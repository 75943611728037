import ReactDOM from "react-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import {CookiesProvider} from "react-cookie";
import {BrowserRouter} from "react-router-dom";
//import "../src/css/index.css";
import IntlProviderWrapper from "./components/intl/IntlProviderWrapper";
import App from "./App.js";
// import reportWebVitals from "./webVitals/reportWebVitals";
// import {reportHandler} from "./webVitals/reportHandler.tsx";

const queryClient = new QueryClient();
ReactDOM.render(
  //<React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <CookiesProvider>
      <IntlProviderWrapper>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </IntlProviderWrapper>
    </CookiesProvider>
  </QueryClientProvider>,
  //</React.StrictMode>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals(reportHandler);
