import {useState, useEffect} from "react";
import {useIntl} from "react-intl";
import _ from "lodash";
import SimpleLabel from "../../common/SimpleLabel.jsx";
import ImageUnit from "./ImageUnit.jsx";
import {isEven} from "../../../utils/utilityFunctions.js";
import config from "../../../../../config.json";
import {range} from "../../../utils/utilityFunctions.js";

function Images({reset, dataIn, onHandleGlobals}) {
  const {formatMessage} = useIntl();
  const [images, setImages] = useState([]);
  useEffect(() => {
    setImages(isEven(reset) ? dataIn.data.default : dataIn.data.saved);
  }, [reset]);
  function handleMain(checked, image) {
    const imgs = _.cloneDeep(images);
    images.map((img, i) => {
      if (checked && i + 1 !== image) {
        document.getElementById(`sliderMainImage${i + 1}`).checked = false;
        imgs[i].main = false;
      }
      if (i + 1 === image) imgs[i].main = checked;
    });
    setImages(imgs);
    onHandleGlobals("value", ["images", imgs]);
  }
  function handleGlobals(cs, val, image) {
    let imgs = [...images];
    let idx = -1,
      main = -1,
      n = imgs.length;
    if (!val) {
      imgs = _.filter(imgs, (img, i) => {
        return i + 1 !== image; //file removal (val=null)
      });
      n += -1;
    } else {
      if (n === 0) idx = 0;
      else {
        imgs.map((img, i) => {
          if (i + 1 === image) idx = i;
          if (img.main) main += 1;
        });
        if (idx === -1) idx = n;
      }
      imgs[idx] = val;
      n += 1;
    }
    if (n > 0 && main < 0) imgs[0].main = true;
    setImages(imgs);
    onHandleGlobals("value", ["images", imgs]);
  }
  return (
    <>
      <SimpleLabel name="images"></SimpleLabel>
      <div className="announce-items-group images-header">
        <div className="member-control-container">
          <p id="images-PH" className="member-control placeholder">
            {`${formatMessage({
              id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.imagesPH",
            })}${config.max_image_size / 1e6} MB`}
          </p>
        </div>
      </div>
      <div className="announce-items-group images-container">
        {range(0, 4).map((idx) => {
          return (
            <ImageUnit
              key={idx}
              idx={idx + 1}
              dataIn={images && images[idx] ? images[idx] : null}
              onHandleMain={handleMain}
              onHandleGlobals={handleGlobals}
            ></ImageUnit>
          );
        })}
      </div>
    </>
  );
}

export default Images;
