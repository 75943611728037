import React from "react";
import {FormattedMessage, useIntl} from "react-intl";

function AnnounceProgram({announce}) {
  const {locale} = useIntl();
  return (
    <div className="ann-section-content">
      {announce.typicalDay && announce.days.length === 0 ? (
        typeof announce.typicalDay[locale] !== "undefined" ? (
          <>
            <h3>
              <FormattedMessage id="src.components.announcePage.announceDetailTab.program.typicalDay" />
            </h3>
            <p>{announce.typicalDay[locale]}</p>
          </>
        ) : null
      ) : null}
      {announce.days.length !== 0 &&
      announce.days[0].title &&
      announce.days[0].title[locale] &&
      announce.days[0].title[locale].length > 0
        ? announce.days.map((day, index) => {
            return (
              <React.Fragment key={index}>
                <div className="ann-programme-day-title">
                  <div className="days">
                    <FormattedMessage id="src.components.announcePage.announceDetailTab.program.day" />{" "}
                    {index + 1}
                  </div>
                  <h3> {day.title[locale] ? day.title[locale] : null}</h3>
                </div>
                <p>
                  {day.description[locale] ? day.description[locale] : null}
                </p>
                <p>
                  {day.accommodation[locale] ? (
                    <span>
                      <FormattedMessage id="src.components.announcePage.announceDetailTab.program.accomodation" />
                      <span>{day.accommodation[locale]}</span>
                    </span>
                  ) : null}
                </p>
                <p>
                  {day.nbHoursEqui ? (
                    <span>
                      <FormattedMessage id="src.components.announcePage.announceDetailTab.program.nbHoursRiding" />
                      <span>{day.nbHoursEqui}</span>
                    </span>
                  ) : null}
                  {day.nbKmsEqui ? (
                    <span className="ml-4 pl-4">
                      <FormattedMessage id="src.components.announcePage.announceDetailTab.program.nbKmsRiding" />
                      <span>{day.nbKmsEqui}</span>
                    </span>
                  ) : null}
                </p>
              </React.Fragment>
            );
          })
        : null}
      {(announce.days.length === 0 ||
        (announce.days[0] && announce.days[0].title[locale].length === 0)) &&
      (!announce.typicalDay ||
        typeof announce.typicalDay[locale] === "undefined" ||
        announce.typicalDay[locale] === "") ? (
        <p>
          <FormattedMessage id="src.components.announcePage.announceDetailTab.program.noInfos" />
        </p>
      ) : null}
    </div>
  );
}

export default AnnounceProgram;
