import {FormattedMessage} from "react-intl";
import {Popper, Paper, Card, Grow, ClickAwayListener} from "@mui/material";

function SinglePopper({
  anchor,
  l0,
  l1_title,
  l1_desc,
  range,
  open,
  onClose,
  mt = 5,
}) {
  return (
    <Popper
      placement="bottom"
      open={open}
      anchorEl={anchor}
      transition
      disablePortal
      style={{zIndex: 100}}
    >
      {({TransitionProps, placement}) => (
        <Grow
          {...TransitionProps}
          /* style={{
            transformOrigin: placement === "right-start", //placement === "bottom" ? "center top" : "center bottom",
          }} */
        >
          <div>
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={onClose}>
                <Card
                  style={{
                    marginTop: mt,
                    maxWidth: 350,
                    border: "1px solid",
                    borderColor: "lightslategrey",
                  }}
                  className="row p-2"
                  elevation={0}
                >
                  {
                    <ul className="mb-2" key={l0}>
                      <li key={l0}>
                        <ul>
                          {range.map((idx) => {
                            return (
                              <li key={idx}>
                                <p className="px-2 py-0 mb-0" key={idx}>
                                  <span className="font-weight-bold">
                                    <FormattedMessage
                                      id={`${l1_title}${idx}`}
                                    />
                                  </span>{" "}
                                  <FormattedMessage id={`${l1_desc}${idx}`} />
                                </p>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </ul>
                  }
                </Card>
              </ClickAwayListener>
            </Paper>
          </div>
        </Grow>
      )}
    </Popper>
  );
}

export default SinglePopper;
