import PageContent from "./PageContent";
import "./style.css";

function HomePage({isLoading, preloaded, announces}) {
  return (
    <PageContent
      preloaded={preloaded}
      announces={!isLoading ? announces : null}
    ></PageContent>
  );
}

export default HomePage;
