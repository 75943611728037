import {useState, useEffect} from "react";
import SimpleText from "../../common/SimpleText.jsx";

let modified = null;
function Position({prefix, reset, dataIn, valid, autofill, onHandleGlobals}) {
  const {name, data} = dataIn;
  const [position, setPosition] = useState(null);
  useEffect(() => {
    setPosition([
      {name: "lat", data: {default: data.default.lat, saved: data.saved.lat}},
      {name: "lng", data: {default: data.default.lng, saved: data.saved.lng}},
    ]);
    modified = {lat: data.saved.lat, lng: data.saved.lng};
  }, [data]);
  function handleGlobals(cs, val) {
    if (cs === "value") modified = {...modified, [val[0]]: val[1]};
    onHandleGlobals(
      cs,
      cs === "valid"
        ? ["position", {...valid, [val[0]]: val[1]}]
        : ["position", modified]
    );
  }
  return (
    position !== null && (
      <>
        {position.map((pos, idx) => {
          return (
            <div key={idx} className="simple-text-block">
              <SimpleText
                prefix={prefix}
                reset={reset}
                dataIn={pos}
                autofill={
                  autofill ? autofill[Object.keys(autofill)[idx]] : null
                }
                required={true}
                valid={valid}
                onHandleGlobals={handleGlobals}
              ></SimpleText>
            </div>
          );
        })}
      </>
    )
  );
}

export default Position;
