import {FormattedMessage, useIntl} from "react-intl";
import {Link} from "react-router-dom";
import FontAwesome from "react-fontawesome";
import Tooltip from "@mui/material/Tooltip";

function NavBarUser({user, masterBadge, spinner}) {
  const {locale} = useIntl();
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Tooltip
        title={
          <FormattedMessage id="src.components.allPages.Menu.userSpace.tooltip"></FormattedMessage>
        }
        arrow
      >
        <div id="user-icon-badge" style={{width: !masterBadge ? 25 : null}}>
          <Link
            className="fa fa-user fa-2x "
            to={`/${locale}/member`}
            style={{
              border: "0",
              cursor: "pointer",
              color: "#7aa095",
            }}
            aria-label="member area"
          ></Link>
          {masterBadge ? (
            <span className="badge rounded-pill badge-notification bg-danger ml-2">
              {"A"}
            </span>
          ) : null}
        </div>
      </Tooltip>
      <span className="user-name">
        {user.firstName
          ? user.firstName.toLowerCase()
          : user.email
          ? user.email
          : null}
      </span>
      <span id="horseAround_navbar_timer"></span>
      <span className="d-inline media-heading">
        {!spinner ? null : (
          <FontAwesome
            className="fa fa-spinner fa-lg"
            style={{
              color: "#7AA095",
              margin: spinner ? "0 10px" : 0,
            }}
            name="spinner"
            pulse
          />
        )}
      </span>
    </div>
  );
}

export default NavBarUser;
