import {FormattedMessage, useIntl} from "react-intl";
import {StarRatingLevel} from "../../../utils/Ratings";
import PopperInfo from "../../../member/common/PopperInfo.jsx";

function AnnounceRatings({label, levels}) {
  const {formatMessage} = useIntl();
  return (
    <>
      <div className="d-flex justify-content-left">
        <h3>
          <FormattedMessage id={label} />
        </h3>
        <div id="announce-global-rating">
          <StarRatingLevel level={levels["0"]} />
          <PopperInfo
            data={[
              [
                `${formatMessage({
                  id: "src.components.rating.DetailledRating.info1",
                })} :`,
              ],
              [
                `- ${formatMessage({
                  id: "src.components.rating.DetailledRating.info2",
                })}`,
              ],
              [
                `- ${formatMessage({
                  id: "src.components.rating.DetailledRating.info3",
                })}`,
              ],
            ]}
            idTT={null}
            icon="fa fa-info-circle fa-2x pl-3"
          ></PopperInfo>
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-left ml-4 mb-4">
        {Object.keys(levels).map((idx) => {
          return (
            idx !== "0" && (
              <div
                key={idx}
                className="d-flex flex-column justify-content-between align-items-left  mx-3 my-3"
              >
                <h4 className="mt-0 pt-0 mb-4">
                  <FormattedMessage id={`${label}${idx}`} />
                </h4>
                <StarRatingLevel level={levels[idx]} />
              </div>
            )
          );
        })}
      </div>
    </>
  );
}

export default AnnounceRatings;
