import {FormattedMessage} from "react-intl";

function SimpleLabel({prefix, name, color = "green", required = false}) {
  return (
    <label
      id={prefix ? `${prefix}-label-${name}` : null}
      className="member-label"
      style={{
        color: !required ? "green" : color,
      }}
    >
      <FormattedMessage
        id={`src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.${name}`}
      />
      {required ? <span>&nbsp;{"*"}</span> : null}
    </label>
  );
}

export default SimpleLabel;
