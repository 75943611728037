import {useState, useEffect} from "react";
import {useIntl} from "react-intl";
import {Card} from "@mui/material";
import _ from "lodash";
import {
  getFormattedDate,
  sumOfPropsValues,
} from "../../../../utils/utilityFunctions.js";
import BookingRecap from "../booking/payment/BookingRecap.jsx";

function Options({form, dataIn, recap, locks, onHandleOptions}) {
  const {locale, formatMessage} = useIntl();
  const {datesType, options, devise} = dataIn.announce;
  const {dateParticipants} = dataIn;
  let dates = null;
  if (recap === 0 || datesType === "Fixed_Fixed") dates = dataIn.dates;
  else dates = dataIn.selection;
  const [dateOptions, setDateOptions] = useState({});

  useEffect(() => {
    const dt_opts = {};
    let obj = {};
    dates.map((date, idx) => {
      if (sumOfPropsValues(dateParticipants[date.id].booking) > 0) {
        obj = {};
        options.map((opt) => {
          obj[opt.option] = false;
        });
        dt_opts[date.id] = obj;
      }
    });
    setDateOptions(dt_opts);
  }, [dates]);
  function handleOptions(dateId, optionId) {
    const dt_opts = _.cloneDeep(dateOptions);
    dt_opts[dateId] = {
      ...dt_opts[dateId],
      [optionId]: !dt_opts[dateId][optionId],
    };
    setDateOptions(dt_opts);
    onHandleOptions(dateId, dt_opts[dateId]);
  }
  return (
    <>
      <BookingRecap recap={recap} announce={dataIn.announce}></BookingRecap>
      <Card
        variant="outlined"
        style={{
          marginTop: recap !== 0 ? 30 : 0,
          marginLeft: recap !== 0 && Object.keys(recap).length === 0 ? 140 : 10,
          width: recap !== 0 ? "70%" : "95%",
        }}
      >
        <div className={`ann-options-container ${recap !== 0 ? "recap" : ""}`}>
          {options.map((option, idx) => {
            return (
              <div
                key={idx}
                className={`ann-option-container ${recap !== 0 ? "recap" : ""}`}
              >
                <div>
                  <label>
                    <h4>
                      {`${formatMessage({
                        id: "src.components.bookingPage.StepOptions.option#",
                      })} ${option.option}`}
                    </h4>
                  </label>
                  <textarea
                    className="form-control"
                    disabled={true}
                    value={`${
                      option.title && option.title[locale]
                        ? option.title[locale]
                        : ""
                    }\n${
                      option.description && option.description[locale]
                        ? option.description[locale]
                        : ""
                    }`}
                    rows={4}
                  ></textarea>
                </div>
                <div className="col-5">
                  <label>
                    <h4>
                      {`${formatMessage({
                        id: "src.components.bookingPage.StepOptions.optionPrice",
                      })}`}
                    </h4>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    style={{minWidth: "150px"}}
                    disabled={true}
                    value={`${option.price} ${devise}   ${formatMessage({
                      id: `src.components.memberPage.tabs.annonces.details.AddOption.option${option.type}`,
                    })}`}
                  ></input>
                </div>
                {dates.map((date, i) => {
                  return (
                    dateOptions[date.id] && (
                      <div
                        key={10 * idx + i}
                        className="row align-items-start mt-2 pt-0"
                      >
                        <div className="d-flex justify-content-left ml-4 pl-4 mt-1">
                          <h5
                            className="ml-4 mt-1 pt-1"
                            style={{paddingLeft: "20px"}}
                          >
                            {`${getFormattedDate(
                              date.startDate,
                              "dd.MM.yyy"
                            )} - ${getFormattedDate(
                              date.endDate,
                              "dd.MM.yyy"
                            )}`}
                          </h5>
                          <input
                            id="option"
                            type="checkbox"
                            className="form-check-input mt-2 ml-4"
                            disabled={locks}
                            onChange={() => {
                              handleOptions(date.id, option.option);
                            }}
                            checked={dateOptions[date.id][option.option]}
                          ></input>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            );
          })}
        </div>
      </Card>
    </>
  );
}

export default Options;
