import {useIntl, FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {Popper, Paper, Card, Grow, ClickAwayListener} from "@mui/material";
import _ from "lodash";

export function getAnnouncesCount(cs, announces, ul, li) {
  let result = null;
  if (cs === "destinations")
    result = _.filter(announces.data, (ann) => {
      return ann.destination[0] === ul && ann.destination[1] === li;
    }).length;
  else {
    //cs = activities;
    result = _.filter(announces.data, (ann) => {
      return ann.category === li;
    }).length;
  }
  return result > 0 ? result : null;
}
function NavBarPopper({
  id,
  announces = null,
  open,
  anchor,
  onClose,
  intlId,
  ul,
  noLink,
}) {
  //noLink=true for announce creation/edit in member page, otherwise noLink=undefined
  const {locale, messages} = useIntl();
  const keys = Object.keys(messages).filter((key) =>
    key.startsWith(`${intlId[0]}${intlId[2]}`)
  );
  let dataL0 = keys.filter((key) => key.includes(intlId[3]));
  dataL0 = dataL0.map((key) => key.replace(intlId[3], ""));
  let l0_key = null,
    l1_key = null;
  return (
    <Popper
      id={`${id}-navbar-popper`}
      open={open}
      anchorEl={anchor}
      transition
      disablePortal
    >
      {({TransitionProps, placement}) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper id="menu-list-grow">
            <ClickAwayListener onClickAway={onClose}>
              <Card className="row p-2" elevation={0}>
                {ul.map(([start, end]) => {
                  return (
                    <ul
                      key={start}
                      className="list-unstyled m-3 "
                      style={{textAlign: "left"}}
                    >
                      {dataL0.slice(start, end).map((l0) => {
                        let dataL1 = Object.keys(messages).filter((key) =>
                          key.startsWith(`${l0}${intlId[4]}`)
                        );
                        l0_key = l0.substring(l0.lastIndexOf(".") + 1);
                        return (
                          <ul className="mb-2" key={l0}>
                            <li key={l0}>
                              <b>
                                <FormattedMessage id={`${l0}${intlId[3]}`} />
                              </b>
                              <ul className="ml-4">
                                {dataL1.map((key) => {
                                  l1_key = key.substring(
                                    key.lastIndexOf(".") + 1
                                  );
                                  return (
                                    <li key={key} className="">
                                      <Link
                                        id={`${l0_key}/${l1_key}`}
                                        style={{color: "black"}}
                                        disabled={true}
                                        to={`/${locale}${
                                          id === "destinations"
                                            ? "/destinations/"
                                            : "/activities/"
                                        }${l0_key}/${l1_key}`}
                                        onClick={(e) => {
                                          if (noLink) e.preventDefault();
                                          onClose(e);
                                        }}
                                      >
                                        <FormattedMessage id={key} />
                                      </Link>
                                      {announces && (
                                        <span
                                          style={{
                                            color: "blue",
                                            paddingLeft: 5,
                                            fontSize: 12,
                                            fontWeight: 600,
                                          }}
                                        >
                                          {getAnnouncesCount(
                                            id,
                                            announces,
                                            l0_key,
                                            l1_key
                                          )}
                                        </span>
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          </ul>
                        );
                      })}
                    </ul>
                  );
                })}
              </Card>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default NavBarPopper;
