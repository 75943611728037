import {useState, useEffect} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import AnnounceCarousel from "./AnnounceCarousel";
import intlData from "../../intl/translations.json";
import Meta from "../common/Meta";
import AnnouncesMap from "../announces/AnnouncesMap";
import AnnounceDetails from "./details/AnnounceDetails";
import {errorHandlingToast} from "../../../services/utilsFunctions.js";
import {getAnnounceBookings} from "../../../services/httpBookings.js";
import RatingTable from "../announces/RatingTable.jsx";
import img from "../home/img/deal/deal-01_light.webp";

let subToType = {};
export function getSubToType() {
  const types =
      intlData["en-EN"].src.components.allPages.Menu.navbar.activities.types,
    subToTyp = {};
  Object.keys(types).map((type) => {
    Object.keys(types[type].subactivities).map((sub) => {
      subToTyp[sub] = type;
    });
  });
  return subToTyp;
}
function PageContent({
  announce,
  pro,
  images,
  onHandleNavSpinner,
  onHandleBookingBadge,
}) {
  const {locale} = useIntl();
  subToType = getSubToType();
  const [bookings, setBookings] = useState(null);
  useEffect(() => {
    async function loadData(signal) {
      onHandleNavSpinner(true);
      const res = await getAnnounceBookings(announce._id, signal);
      if (await errorHandlingToast(res, locale, false)) {
        setBookings([]);
        return;
      } else setBookings(res.data);
      onHandleNavSpinner(false);
    }
    const abortController = new AbortController();
    loadData(abortController.signal);
    return () => {
      abortController.abort(); //clean-up code after component has unmounted
    };
  }, [announce]);
  return (
    pro !== null &&
    bookings !== null && (
      <>
        {announce.category && announce.title && (
          <Meta
            id={`activities.${announce.category}`}
            title1={`${announce.title[locale]} - `}
            desc2={announce.title[locale]}
          ></Meta>
        )}
        <div id="ann-content">
          <section id="ann-page-header">
            <AnnounceCarousel
              announce={announce}
              images={images && images.length > 0 ? images : [img]} //main image or default if not yet loaded
              dflt={images && images.length > 0 ? false : true}
            />
            <div className="header-text">
              <h1 className="text-center" style={{color: "#7AA095"}}>
                {announce.title && announce.title[locale] && (
                  <b>{announce.title[locale]}</b>
                )}
              </h1>
              <h2>
                {announce.category && (
                  <FormattedMessage
                    id={`src.components.allPages.Menu.navbar.activities.types.${
                      subToType[announce.category]
                    }.subactivities.${announce.category}`}
                  ></FormattedMessage>
                )}
              </h2>
              <RatingTable announce={announce}></RatingTable>
            </div>
            <AnnouncesMap infoWindow={false} announces={[announce]} />
          </section>
          <AnnounceDetails
            announce={announce}
            pro={pro}
            bookings={bookings}
            onHandleBookingBadge={onHandleBookingBadge}
          />
        </div>
      </>
    )
  );
}

export default PageContent;
