import {translate} from "./httpGoogleServices.js";
import _ from "lodash";
import config from "../config.json";
import {
  toastSuccess,
  toastError,
} from "../components/pages/common/toastSwal/ToastMessages.js";
import {getImagesByAnnId} from "./httpImages.js";

export async function errorHandlingToast(res, lang, msg = true) {
  if (res.data.statusCode >= 400 && res.data.statusCode <= 500) {
    if (
      res.data.statusCode === 401 &&
      res.data.description.includes("Invalid token")
    )
      try {
        document.getElementById("navbar-home").click();
        document.getElementById("loginButtonHorseAround").innerHTML =
          lang === "en" ? "LOG-IN" : "CONNEXION";
        document.getElementById("horseAround_navbar_timer").innerHTML = "";
      } catch (error) {
        console.log("errorHandlingToast invalid token error", error);
        window.location.reload();
      }
    toastError(
      lang === "en"
        ? res.data.description
        : await translate({text: res.data.description, from: "en", to: lang}) //message sent back from API customErrors.js such as BadRequest, NotFound ...
    );
    return true;
  }
  if (msg) {
    toastSuccess(
      lang === "en"
        ? res.data.message
        : await translate({text: res.data.message, from: "en", to: lang}) //message sent back from API routes such as announces.js, users.js ...
    );
  }
  if (res.status === 200) return false;
  if (typeof res.data.statusCode === "undefined") return true;
  return false;
}
export async function simpleAlert(msg, lang) {
  alert(
    lang === "en" ? msg : await translate({text: msg, from: "en", to: lang})
  );
}
export async function simpleToast(msg, lang) {
  toastError(
    lang === "en" ? msg : await translate({text: msg, from: "en", to: lang})
  );
}
export function getRefreshTime(source, type = "stale_cache") {
  switch (process.env.REACT_APP_NODE_ENV) {
    case "production":
      return type === "stale_cache"
        ? {
            staleTime: config[`${source}_staleTime`].prod * 60 * 1000, //time set in config.json file is in minutes
            cacheTime: config[`${source}_cacheTime`].prod * 60 * 1000,
          }
        : {
            refetchInterval:
              config[`${source}_refetchInterval`].prod * 60 * 1000,
          };
    case "test":
      return type === "stale_cache"
        ? {
            staleTime: config[`${source}_staleTime`].test * 60 * 1000, //time set in config.json file is in minutes
            cacheTime: config[`${source}_cacheTime`].test * 60 * 1000,
          }
        : {
            refetchInterval: process.env.REACT_APP_FETCH_INTERVAL
              ? process.env.REACT_APP_FETCH_INTERVAL * 60000
              : config[`${source}_refetchInterval`].test * 60 * 1000,
          };
    default:
      return type === "stale_cache"
        ? {
            staleTime: config[`${source}_staleTime`].dev * 60 * 1000,
            cacheTime: config[`${source}_cacheTime`].dev * 60 * 1000,
          }
        : {
            refetchInterval:
              config[`${source}_refetchInterval`].dev * 60 * 1000,
          };
  }
}
export function getApiUrl() {
  switch (process.env.REACT_APP_NODE_ENV) {
    case "development":
      return config.api_url_test;
    case "test":
      return config.api_url_test;
    case "production":
      return config.api_url_prod;
  }
}
export function formatEndpoint(endpoint) {
  endpoint = _.split(endpoint, "?");
  if (endpoint.length === 1) return endpoint;
  let result = null;
  endpoint.map((item, idx) => {
    switch (idx) {
      case 0:
        result = `${item}`;
        break;
      case 1:
        result = `${result}?${item}`;
        break;
      default:
        result = `${result}&${item}`;
    }
  });
  return result;
}
export async function getImages(anns, contextImages, main, signal) {
  if (!anns) return {};
  let images = {};
  const tobeLoaded = [];
  anns.map(async (ann) => {
    switch (main) {
      case true: //main images loaded in App.js
        try {
          images[ann._id] =
            contextImages.state.status &&
            contextImages.state.status[ann._id].main === 1
              ? _.filter(contextImages.state[ann._id], (item) => {
                  return item.main;
                })
              : null; //default image returned in utilityFunctions.getMainImage()
        } catch (error) {
          images[ann._id] = null;
        }
        break;
      case false:
        if (
          contextImages.state.status &&
          contextImages.state.status[ann._id].others ===
            contextImages.state.status[ann._id].total - 1
        )
          images[ann._id] = _.filter(contextImages.state[ann._id], (item) => {
            return !item.main;
          });
        else tobeLoaded.push(ann._id);
    }
  });
  if (tobeLoaded.length > 0) {
    const res = await loadOtherImages(tobeLoaded, images, signal);
    if (!_.isEqual(Object.keys(res), ["error"])) {
      images = res; //images={} returned in case of failure
      contextImages.onHandleImages(images); //update ImagesContext in App.js
    }
  }
  return images; //i.a.w main parameter, main images or other images are returned (not both)
}
export async function loadOtherImages(anns, images, signal) {
  let result = null;
  let res = null;
  await Promise.all(
    anns.map(async (_id) => {
      res = await getImagesByAnnId(_id, false, signal);
      images[res.data.id_announce] = res.data.images;
    })
  )
    .then(() => {
      result = images;
    })
    .catch((error) => {
      const txt = "error in async function loadOtherImages at Promise.all";
      result = {
        error: {
          msg: txt,
          details: error,
        },
      };
    });
  return result;
}
