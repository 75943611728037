import {useState, useEffect, useContext, lazy, Suspense} from "react";
import _ from "lodash";
import ImagesContext from "../../common/context/ImagesContext.js";
import {getImages} from "../../../../services/utilsFunctions.js";
import PageLoader from "../../common/loading/PageLoader.jsx";
const HomeTopPromo = lazy(() => import("./HomeTopPromo"));
const HomeTopDeals = lazy(() => import("./HomeTopDeals"));

function HomeTop({top3}) {
  const [images, setImages] = useState({});
  const abortController = new AbortController();
  const contextImages = useContext(ImagesContext);
  useEffect(() => {
    async function fetchImages(signal) {
      const imgs = await getImages(top3, contextImages, true, signal); //main images
      setImages(imgs);
    }
    fetchImages(abortController.signal);
    return () => {
      abortController.abort(); //clean-up code after component has unmounted
    };
  }, [top3, contextImages]);
  return (
    <div>
      <Suspense fallback={<PageLoader></PageLoader>}>
        <HomeTopPromo topDeal={top3[0]} images={images}></HomeTopPromo>
      </Suspense>
      <Suspense fallback={<PageLoader></PageLoader>}>
        <HomeTopDeals top3={top3} images={images}></HomeTopDeals>
      </Suspense>
    </div>
  );
}

export default HomeTop;
