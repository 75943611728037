import {parseISO, format, isDate} from "date-fns";
import * as locales from "date-fns/locale";
import _ from "lodash";
import img from "../home/img/deal/deal-01_light.webp"; //default image

export function isEven(n) {
  return n % 2 === 0;
}
export function getMainImage(images) {
  try {
    if (!images || images.length === 0) return img;
    let idx = -1;
    images.map((image, i) => {
      if (image.main) idx = i;
    });
    return idx >= 0 ? images[idx].data : images[0].data;
  } catch (error) {
    return img;
  }
}
export function getDateStatus(date, participantMax, participantMin) {
  if (date.bookingsByDay.length > 0) {
    const n = sumOfPropsValues(date.bookingsByDay[0].bookings); //applies only to Fixed_Fixed datesType
    return n >= participantMax
      ? "full"
      : n >= participantMin
      ? "guaranteed"
      : "nonGuaranteed";
  }
}
export function testGuaranteedDeparture(
  cs, //cs=0 for SmallAnnounce, cs=1 for priceDatesTable
  dates,
  participantMin,
  participantMax
) {
  let text = -1,
    status = null;
  switch (cs) {
    case 0: //SmallAnnounce
      dates.map((date) => {
        status = getDateStatus(date, participantMax, participantMin);
        if (status === "guaranteed") text += 1;
      });
      if (text >= 0)
        return "src.components.announcePage.announceDetailTab.moreInfoTable.guaranteedDeparture";
      break;
    case 1: //priceDatesTable
      status = getDateStatus(dates[0], participantMax, participantMin);
      if (status === "guaranteed" || status === "full")
        return `src.components.announcePage.announceDetailTab.moreInfoTable.${status}Departure`;
  }
  return null;
}
export function testPromo(dates) {
  let text = null;
  dates.forEach((date) => {
    if (date.promotion && date.promotion !== null) text += 1;
  });
  if (text !== null) return "Promo";
}
export function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}
export function randomInteger(n) {
  return Math.floor(Math.random() * (n + 1)); //random integer between 0 and n
}
export function getRandomColor() {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
}
export function getNRandomColors(n) {
  if (n <= 0) return null;
  const colors = [];
  let i = 0;
  for (i in range(1, n)) {
    colors.push(getRandomColor());
  }
  return colors;
}
export function getFormattedDate(date, frmt, locale = null) {
  if (typeof frmt === "undefined") frmt = "dd.MM.yyyy HH:mm";
  try {
    if (_.isString(date)) {
      if (date.length > 0)
        return format(parseISO(date), frmt, {locale: locales[locale]});
      else return "";
    }
    if (isDate(date)) return format(date, frmt, {locale: locales[locale]});
  } catch (error) {
    console.log("Error in getFormattedDate - utilityFunctions", error);
  }
}
export function getKeys(type, messages) {
  //activities/subactivities, continents/countries keys
  const keys = [];
  let ky = [];
  Object.keys(messages)
    .filter(
      (key) =>
        key.startsWith(`src.components.allPages.Menu.navbar.${type[0]}`) &&
        key.indexOf(type[1]) !== -1
    )
    .map((key) => {
      ky = key.split(".");
      keys.push([ky[7], ky[9]]);
    });
  return keys;
}
export function sumOfPropsValues(obj) {
  return Object.values(obj).reduce((sum, value) => {
    return sum + value; //sum up object's props values
  }, 0);
}
export function padToNDigits(num, n) {
  return num.toString().padStart(n, "0");
}
export function scrollToBottom(id) {
  const elemt = document.getElementById(id);
  if (!elemt) return;
  elemt.scrollTop = elemt.scrollHeight;
}
export function onClassChange(node, callback) {
  let oldClass = node.classList.toString();
  const mutationObserver = new MutationObserver((mutationList) => {
    for (const item of mutationList) {
      if (item.attributeName === "class") {
        const newClass = node.classList.toString();
        if (newClass !== oldClass) {
          callback(mutationObserver);
          oldClass = newClass;
          break;
        }
      }
    }
  });
  mutationObserver.observe(node, {attributes: true});
  return mutationObserver;
}
