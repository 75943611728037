import star from "./img/grading/star.webp";
import starGrey from "./img/grading/greystar.webp";
import horse from "./img/grading/horse.webp";
import horseGrey from "./img/grading/greyhorse.webp";
import run from "./img/grading/run.webp";
import runGrey from "./img/grading/greyrun.webp";
import host from "./img/grading/house.webp";
import hostGrey from "./img/grading/greyhouse.webp";

export function getNStars(n) {
  let stars = [];
  let comp = null;
  for (let i = 0; i < n; i++) {
    comp = (
      <span>
        <img className="fit-picture" src={star} alt="img" />
        <span>
          <abbr> </abbr>
        </span>
      </span>
    );
    stars.push(<span key={i + n * 3}>{comp}</span>);
  }
  return stars;
}
export function getStars(rating, type, img, imgGrey) {
  //type > "span" or "li"
  let stars = [];
  let comp = null;
  for (let i = 0; i < Math.round(rating); i++) {
    comp = (
      <>
        <img className="fit-picture" src={img} alt="img" />
        <span>
          <abbr> </abbr>
        </span>
      </>
    );
    stars.push(
      type === "span" ? <span key={i}>{comp}</span> : <li key={i}>{comp}</li>
    );
  }
  for (let i = Math.round(rating); i < 5; i++) {
    comp = (
      <>
        <img className="fit-picture" src={imgGrey} alt="imgGrey" />
        <span>
          <abbr> </abbr>
        </span>
      </>
    );
    stars.push(
      type === "span" ? <span key={i}>{comp}</span> : <li key={i}>{comp}</li>
    );
  }
  return stars;
}
export function getAnnounceRating(ann, pro) {
  return typeof ann.environmentLandscapeNote !== "undefined"
    ? Math.round(
        (ann.environmentLandscapeNote +
          ann.cavalryNote +
          ann.qualityPriceNote +
          ann.receptionNote +
          pro.horseAroundNote.total) /
          5
      )
    : pro.horseAroundNote.total
    ? pro.horseAroundNote.total
    : 3; //environmentLandscapeNote initialized to null at announce creation >>> no existing comment
}
export function StarRating({announce, justify = "left"}) {
  return (
    <div className={`d-flex justify-content-${justify}`}>
      {getStars(announce.numberRatings, "span", star, starGrey)}
    </div>
  );
}
export function HorseRating({level}) {
  return (
    <div className="d-flex justify-content-left">
      {getStars(level, "span", horse, horseGrey)}
    </div>
  );
}
export function PhysicalRating({level}) {
  return (
    <div className="d-flex justify-content-left">
      {getStars(level, "span", run, runGrey)}
    </div>
  );
}
export function BedRating({level}) {
  return (
    <div className="d-flex justify-content-left">
      {getStars(level, "span", host, hostGrey)}
    </div>
  );
}
export function StarRatingLevel({level}) {
  return (
    <div className="d-flex justify-content-left">
      {getStars(level, "span", star, starGrey)}
    </div>
  );
}
