import {useState, useEffect} from "react";
import {FormattedMessage} from "react-intl";
import InputMask from "react-input-mask";
import {alert} from "../validation.js";
import {isEven} from "../../utils/utilityFunctions.js";
import "./style.css";

function SimpleText({
  reset,
  dataIn,
  autofill = null,
  required,
  disabled = false,
  type = "text",
  options = [],
  label = true,
  aftLabel = null,
  valid = null,
  trash = true,
  icon = "fa-trash",
  prefix = null,
  lt = false,
  ph = null,
  rows = "3",
  mask = null,
  onHandleGlobals,
  onHandleIconClick,
}) {
  const name = dataIn.name;
  const [color, setColor] = useState(
    disabled ? "green" : valid[name] ? "green" : "red"
  );
  const [lineThrough, setLineThrough] = useState(false);
  const [start, setStart] = useState(1);
  const [value, setValue] = useState("");
  const [warning, setWarning] = useState(false);
  useEffect(() => {
    if (typeof reset !== "undefined") return;
    setValue(dataIn.data);
    const result = alert(name, null, dataIn.data, required);
    setWarning(result);
    setColor(valid ? "green" : "red");
  }, [dataIn, reset]);
  useEffect(() => {
    if (typeof reset === "undefined") return;
    let data = isEven(reset) ? dataIn.data.default : dataIn.data.saved;
    if (data instanceof Object) data = dataIn.data.default;
    if (disabled) setValue(data);
    else handleChange(data, 1); //set value state, update validation upon reset change
    setWarning(false);
  }, [dataIn, reset]);
  useEffect(() => {
    function changeEvent() {
      setLineThrough(document.getElementById(lt).value == 1 ? false : true);
    }
    if (lt) {
      changeEvent(); //initial lineThrough setting
      document.getElementById(lt).addEventListener("change", changeEvent);
    }
    return () => {
      try {
        document.getElementById(lt).removeEventListener("change", changeEvent);
      } catch (error) {}
    };
  });
  useEffect(() => {
    if (autofill) handleChange(autofill);
  }, [autofill]);
  function handleChange(val, cs = 0) {
    setStart(0);
    setValue(val);
    const result = alert(name, null, val, required);
    if (cs === 0) setWarning(result);
    if (required) {
      onHandleGlobals("valid", result.props.obj);
      setColor(result.props.obj[1] ? "green" : "red");
    }
    if (cs === 0) onHandleGlobals("value", [name, val]);
  }
  return (
    <>
      {label && (
        <label
          id={prefix ? `${prefix}-label-${name}` : null}
          className="member-label"
          style={{
            color: !required ? "green" : color,
          }}
        >
          <FormattedMessage
            id={`src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.${name}`}
          />
          {required ? <span>&nbsp;{"*"}</span> : null}
        </label>
      )}
      <div
        id={prefix ? `${prefix}-control-${name}` : null}
        className="member-control-container"
      >
        {type === "text" && (
          <input
            id={prefix ? `${prefix}-control-${name}-input` : null}
            type="text"
            className="member-control"
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            value={value}
            disabled={lineThrough || disabled}
            placeholder={ph}
            style={{
              borderColor: !required ? "green" : color,
              textAlign: trash ? "left" : "center",
              textDecorationLine: lineThrough ? "line-through" : null,
              cursor: disabled ? "not-allowed" : "auto",
            }}
          ></input>
        )}
        {type === "textarea" && (
          <textarea
            type="text"
            className="member-control"
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            value={value}
            disabled={lineThrough || disabled}
            placeholder={ph}
            style={{
              borderColor: !required ? "green" : color,
              textAlign: trash ? "left" : "center",
              textDecorationLine: lineThrough ? "line-through" : null,
              cursor: disabled ? "not-allowed" : "auto",
            }}
            rows={rows}
          ></textarea>
        )}
        {type === "select" && (
          <select
            id={prefix ? `${prefix}-control-${name}-select` : null}
            className="member-control"
            style={{
              cursor: disabled ? "not-allowed" : "pointer",
            }}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            value={value}
          >
            {options.map((option, idx) => {
              return (
                <option key={idx} className="option" value={option[0]}>
                  {option[1]}
                </option>
              );
            })}
          </select>
        )}
        {type === "inputMask" && (
          <InputMask
            type="text"
            className="member-control"
            disabled={disabled}
            style={{
              borderColor: !required ? "green" : color,
              textAlign: trash ? "left" : "center",
              textDecorationLine: lineThrough ? "line-through" : null,
              cursor: disabled ? "not-allowed" : "auto",
            }}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            placeholder={ph}
            mask={mask.mask}
            maskChar={mask.maskChar}
            value={value}
          ></InputMask>
        )}
        {!disabled && trash && (
          <i
            className={`fa ${icon} fa-lg`}
            onClick={() => {
              switch (icon) {
                case "fa-trash":
                  setValue("");
                  handleChange("");
                  break;
                default:
                  onHandleIconClick();
              }
            }}
          ></i>
        )}
        {aftLabel && <label className="aft-label">{aftLabel}</label>}
        <div
          className="d-flex justify-content-left"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {start === 0 && required && warning}
        </div>
      </div>
    </>
  );
}

export default SimpleText;
