import {useState, useEffect} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import _ from "lodash";
import DayUnit from "./DayUnit.jsx";
import {isEven} from "../../../utils/utilityFunctions.js";
import {validate} from "../../validation.js";
import SimpleLabel from "../../common/SimpleLabel.jsx";
import {red} from "@mui/material/colors";

function DailyProgram({reset, dataIn, onHandleGlobals}) {
  const {formatMessage} = useIntl();
  const {name, data: dta} = dataIn;
  const [data, setData] = useState([]);
  const [valid, setValid] = useState([]);
  useEffect(() => {
    const days = _.cloneDeep(isEven(reset) ? dta.default : dta.saved);
    setData(days);
    const valide = [];
    days.map((day, idx) => {
      valide.push({
        day: idx + 1,
        title: {
          en: validate("title", day["title"].en)[0],
          fr: validate("title", day["title"].fr)[0],
        },
        description: {en: true, fr: true},
        accommodation: {en: true, fr: true},
        nbHoursEqui: true,
        nbKmsEqui: true,
      });
    });
    onHandleGlobals("valid", [
      name,
      JSON.stringify(valide).indexOf(false) === -1,
    ]);
    setValid(valide);
  }, [reset]);
  function handleCreate() {
    const days = _.cloneDeep(data);
    days.push({
      day: days.length + 1,
      title: {en: "", fr: ""},
      description: {en: "", fr: ""},
      accommodation: {en: "", fr: ""},
      nbHoursEqui: "",
      nbKmsEqui: "",
    });
    setData(days);
    onHandleGlobals("value", [name, days]);
    const valide = _.cloneDeep(valid);
    valide.push({
      day: days.length,
      title: {en: false, fr: false},
      description: {en: true, fr: true},
      accommodation: {en: true, fr: true},
      nbHoursEqui: true,
      nbKmsEqui: true,
    });
    setValid(valide);
    onHandleGlobals("valid", [name, false]);
  }
  function handleDelete(day) {
    const days = _.cloneDeep(data),
      valide = _.cloneDeep(valid);
    let idx = -1;
    days.map((dy, i) => {
      if (dy.day === day) idx = i;
    });
    days.splice(idx, 1);
    days.map((dy, i) => {
      //renumber days after deletion
      dy.day = i + 1;
    });
    setData(days);
    onHandleGlobals("value", [name, days]);
    valide.splice(idx, 1);
    valide.map((dy, i) => {
      //renumber valide after deletion
      dy.day = i + 1;
    });
    setValid(valide);
    onHandleGlobals("valid", [
      name,
      JSON.stringify(valide).indexOf(false) === -1,
    ]);
  }
  function handleGlobals(cs, val, day) {
    const days = _.cloneDeep(data);
    let idx = -1;
    days.map((dy, i) => {
      if (dy.day === day) idx = i;
    });
    if (cs === "value") {
      if (val.length === 3)
        days[idx][val[0]][val[1]] = val[2]; //title,description, accommodation
      else days[idx][val[0]] = val[1]; //nbHoursEqui
      setData(days);
      onHandleGlobals("value", [name, days]);
    } else {
      const valide = _.cloneDeep(valid);
      valid.map((dy, idx) => {
        if (dy.day === day) {
          switch (val.length) {
            case 2:
              valide[idx][val[0]] = val[1];
              break;
            case 3:
              valide[idx][val[0]][val[1]] = val[2];
          }
        }
      });
      setValid(valide);
      onHandleGlobals("valid", [
        name,
        JSON.stringify(valide).indexOf(false) === -1,
      ]);
    }
  }
  return (
    <>
      <SimpleLabel name="days"></SimpleLabel>
      <div className="announce-items-group day-option-header">
        <button
          className="dropdown singleDrop btn btn-success"
          onClick={handleCreate}
        >
          <FormattedMessage
            id={
              "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.add"
            }
          />
        </button>
        <div className="member-control-container">
          <p id="daily-program-PH" className="member-control placeholder">
            {formatMessage({
              id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.daysPH",
            })}
          </p>
        </div>
      </div>
      {data.map((day, idx) => {
        return (
          <div key={idx} className="announce-items-day-option-unit">
            <DayUnit
              key={idx}
              dataIn={day}
              valid={valid[idx]}
              onHandleDelete={handleDelete}
              onHandleGlobals={handleGlobals}
            ></DayUnit>
          </div>
        );
      })}
    </>
  );
}

export default DailyProgram;
