import {useState, useEffect} from "react";
import {FormattedMessage} from "react-intl";
import SimpleLabel from "../../common/SimpleLabel.jsx";
import SimpleText from "../../common/SimpleText.jsx";
import {isEven} from "../../../utils/utilityFunctions.js";

function Price({prefix, reset, dataIn, valid, onHandleGlobals}) {
  const keys = Object.keys(dataIn);
  const x = {
    priceAdulte: "openToAdults",
    priceChild: "openToChildren",
    priceAccompagnateur: "openToNonRiders",
  };
  const [color, setColor] = useState([]);
  function init() {
    const obj = [];
    keys.map((key) => {
      obj.push(valid[key]);
    });
    return obj.indexOf(false) === -1 ? "green" : "red";
  }
  useEffect(() => {
    setColor(init());
  }, [valid]);
  const [value, setValue] = useState({});
  const [cur, setCurrency] = useState("");
  useEffect(() => {
    const data = {};
    keys.map((key, idx) => {
      data[key] = dataIn[key];
    });
    setValue(data);
    setCurrency(data.devise.data[isEven(reset) ? "default" : "saved"]);
  }, [reset]);
  function handleChange(e) {
    setCurrency(e.target.value);
    onHandleGlobals("value", [e.target.id, e.target.value]);
  }
  return (
    Object.keys(value).length > 0 && (
      <>
        <SimpleLabel name="price" color={color} required></SimpleLabel>
        <div className="announce-items-group">
          <div className="simple-text-block">
            <label className="member-label">
              <FormattedMessage
                id={`src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.${keys[0]}`}
              />
            </label>
            <div className="member-control-container mr-4 pr-4">
              <select
                id="devise"
                className="member-control"
                onChange={handleChange}
                value={cur}
              >
                <option>€</option>
                <option>£</option>
                <option>$</option>
              </select>
            </div>
          </div>
          {keys.map((key, idx) => {
            return idx > 0 ? (
              <div key={idx} className="d-flex simple-text-block">
                <SimpleText
                  id={key}
                  prefix={prefix}
                  reset={reset}
                  dataIn={value[key]}
                  required={true}
                  valid={valid[key]}
                  trash={false}
                  lt={x[key]}
                  onHandleGlobals={onHandleGlobals}
                ></SimpleText>
                <span className="aft-label" style={{marginRight: 0}}>
                  {cur}
                </span>
                <span
                  id={`${key}Daily`}
                  className="aft-label"
                  style={{marginLeft: 0}}
                ></span>
              </div>
            ) : null;
          })}
        </div>
      </>
    )
  );
}

export default Price;
