import {useIntl} from "react-intl";
import {HorseRating, PhysicalRating} from "../../utils/Ratings";
import LevelInfo from "./common/LevelInfo";
import {range} from "../../utils/utilityFunctions";
import StandardInfo from "./common/StandardInfo";

function EquestrianPhysicalInfo({announce, full}) {
  const {locale} = useIntl();
  return (
    <div className="ann-section-content">
      <div className="ann-level-info">
        <LevelInfo
          label="src.components.announcePage.announceDetailTab.labels.requiredEquestrianLevel"
          l0="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.header2"
          l1_title="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.span"
          l1_desc="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.description"
          range={[...range(6, 10)]}
          rating={<HorseRating level={announce.equestrianLevel} />}
        ></LevelInfo>
        <LevelInfo
          label="src.components.announcePage.announceDetailTab.labels.physicalLevel"
          l0="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.header3"
          l1_title="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.span"
          l1_desc="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.description"
          range={[...range(11, 15)]}
          rating={<PhysicalRating level={announce.physicalLevel} />}
        ></LevelInfo>
      </div>
      {announce.horseDescription && announce.horseDescription[locale] && (
        <div>
          <StandardInfo
            field={announce.horseDescription}
            locale={locale}
            id="src.components.announcePage.announceDetailTab.labels.horses"
          ></StandardInfo>
        </div>
      )}
      {announce.saddleryDescription && announce.saddleryDescription[locale] && (
        <div>
          <StandardInfo
            field={announce.saddleryDescription}
            locale={locale}
            id="src.components.announcePage.announceDetailTab.labels.saddlery"
          ></StandardInfo>
        </div>
      )}
    </div>
  );
}

export default EquestrianPhysicalInfo;
