import {useState, useEffect} from "react";
import {alpha} from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  ThemeProvider,
  Box,
} from "@mui/material";
import {visuallyHidden} from "@mui/utils";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import {FormattedMessage, useIntl} from "react-intl";
import _ from "lodash";
import {addDays} from "date-fns";
import {parseISO, format, isDate, differenceInCalendarDays} from "date-fns";
import {useCookies} from "react-cookie";
import {useNavigate, useLocation} from "react-router-dom";
import {testGuaranteedDeparture} from "../../../utils/utilityFunctions";
import {decodeJWT} from "../../../../../services/httpUsers.js";

function EnhancedTableHead({
  headCells,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  theme,
  sx,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <ThemeProvider theme={theme}>
      <TableHead>
        <TableRow>
          <TableCell>
            <Checkbox
              style={{color: "white", padding: "0", margin: "0"}}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              style={{padding: "0", margin: "0"}}
              key={headCell.name}
              align={headCell.numeric ? "right" : "left"}
              hidden={headCell.hidden ? true : false}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.name ? order : false}
            >
              <TableSortLabel
                sx={sx}
                active={orderBy === headCell.name}
                direction={orderBy === headCell.name ? order : "asc"}
                onClick={createSortHandler(headCell.name)}
                style={{color: "white"}}
                hidden={headCell.hidden ? true : false}
              >
                {headCell.label}
                {orderBy === headCell.name ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </ThemeProvider>
  );
}
const EnhancedTableToolbar = ({
  announce,
  locale,
  formatMessage,
  rows,
  selected,
  numSelected,
  theme,
  onFilter,
  onHandleFormBooking,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["user"]);
  const currentUser = cookies.user ? decodeJWT(cookies.user) : null;
  //const [open, setOpen] = useState(false);
  function handleFilter(cond) {
    onFilter(cond);
  }
  function getLabel() {
    let num = "",
      txt = "";
    if (numFiltered > 0) {
      num = numFiltered;
      txt = formatMessage({id: "user_msg.standard.filtered"});
    } else if (numSelected > 0) {
      num = numSelected;
      txt = formatMessage({id: "user_msg.standard.selected"});
    }
    return (
      <div className="d-inline-flex flex-wrap align-items-center ml-1 ">
        <span className="mr-2">{`${num} ${txt}`}</span>
        <div
          disabled={
            !cookies.user || Number(num) === 0 || currentUser.type === "pro"
          }
          className="dropdown singleDrop btn btn-success p-2 pl-3 pr-3 mr-2 "
          onClick={() => {
            if (
              !cookies.user ||
              Number(num) === 0 ||
              currentUser.type === "pro"
            )
              return;
            onHandleFormBooking(
              true,
              _.filter(rows, (row) => {
                return selected.indexOf(row.id) !== -1;
              })
            );
          }}
        >
          {formatMessage({
            id: "src.components.announcePage.booking.bookButton",
          })}
        </div>
        <div className="d-inline-flex alert alert-success justify-content-center m-0 p-0 pl-4 pr-4 ">
          <h5 className="p-1 my-2">
            {announce.datesType === "Fixed_Fixed" ? (
              <FormattedMessage id="src.components.announcePage.booking.fixed_fixed"></FormattedMessage>
            ) : null}
            {announce.datesType === "Flex_Fixed" ? (
              <FormattedMessage id="src.components.announcePage.booking.flex_fixed"></FormattedMessage>
            ) : null}
            {announce.datesType === "Flex_Flex" ? (
              <FormattedMessage id="src.components.announcePage.booking.flex_flex"></FormattedMessage>
            ) : null}
          </h5>
        </div>
        {!cookies.user && (
          <div className="d-inline-flex alert alert-danger justify-content-center m-0 ml-3 p-0 pl-4 pr-4 ">
            <h5
              style={{
                fontFamily: "Montserrat, Roboto, Roboto, sans-serif",
              }}
            >
              <FormattedMessage id="src.components.announcePage.booking.userLoggedIn"></FormattedMessage>
            </h5>
          </div>
        )}
        {cookies.user && (
          <div
            className="dropdown singleDrop btn btn-success"
            onClick={() => {
              navigate(
                `${location.pathname}${location.search}&MyBookings_ann_id=${announce._id}`, //add 'MyBookings' parameter in url to be able to come back on tab3 when using browser back button
                {
                  replace: true,
                  state: location.state,
                }
              );
              navigate(
                `/${locale}/member?subtab=0&MyBookings_ann_id=${announce._id}`
              ); //navigate to MemberPage 'Bookings' tab instead of landing on DashBoard tab
            }}
          >
            <FormattedMessage id="src.components.bookingPage.StepFour.redirect" />
          </div>
        )}
      </div>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <Toolbar
        variant="dense"
        sx={{
          pl: {sm: 2},
          pr: {xs: 1, sm: 1},
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        <Typography
          sx={{flex: "1 1 100%"}}
          color="inherit"
          variant="h6"
          component="div"
        >
          {getLabel()}
        </Typography>
        <IconButton
          onClick={() => {
            handleFilter(true);
          }}
        >
          <FilterListIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            handleFilter(false);
          }}
        >
          <FilterListOffIcon />
        </IconButton>
      </Toolbar>
    </ThemeProvider>
  );
};
export function initBookingsByDay(start, end, bookingsByDay, formatDate) {
  let i = 0,
    n = 0,
    day = start;
  try {
    n = bookingsByDay.length;
  } catch (error) {}
  const bBD = [];
  while (day.setHours(0, 0, 0, 0) <= end.setHours(0, 0, 0, 0)) {
    if (n > 0) {
      for (let j = 0; j < n; j++) {
        if (
          day.setHours(0, 0, 0, 0) === //existing bookingByDay for that day
          formatDate(bookingsByDay[j].day).setHours(0, 0, 0, 0)
        ) {
          bBD.push({day, bookings: bookingsByDay[j].bookings});
          break;
        }
        if (j === n - 1)
          bBD.push({
            day,
            bookings: {0: 0, 1: 0, 2: 0}, //adult,child,companion
          });
      }
    } else bBD.push({day, bookings: {0: 0, 1: 0, 2: 0}});
    i++;
    day = addDays(start, i);
  }
  return bBD;
}
let original = [],
  numFiltered = 0;
export default function DataTable({
  headCells,
  announce,
  themes,
  onHandleFormBooking,
}) {
  const {locale, formatMessage} = useIntl();
  const [rows, setRows] = useState([]);
  useEffect(() => {
    original = prepareData(headCells, announce);
    setRows(original);
  }, [locale]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(headCells[0].name);
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(true);
  function formatDate(date) {
    return _.isString(date) ? parseISO(date) : date;
  }
  function getPriceRecap(promo) {
    return (
      <div>
        {announce.priceAdulte
          ? `${formatMessage({
              id: "src.components.announcePage.booking.adult",
            })}
          : ${getPrice(announce.priceAdulte, promo)}`
          : null}
        {announce.priceAdulte ? <br></br> : null}
        {announce.priceChild
          ? `${formatMessage({
              id: "src.components.announcePage.booking.child",
            })}
           : ${getPrice(announce.priceChild, promo)}`
          : null}
        {announce.priceChild ? <br></br> : null}
        {announce.priceAccompagnateur
          ? `${formatMessage({
              id: "src.components.announcePage.booking.companion",
            })}
            : ${getPrice(announce.priceAccompagnateur, promo)}`
          : null}
      </div>
    );
  }
  function getPrice(price, promo) {
    return (
      (promo
        ? Math.round((price - (price * promo) / 100) * 100 + Number.EPSILON) /
          100
        : price) +
      " " +
      announce.devise
    );
  }
  function getOptionRecap(options) {
    if (options.length === 0) return "";
    return (
      <div style={{whiteSpace: "pre"}}>
        {options.map((option, idx) => {
          return (
            <div className="mt-1" key={idx}>
              {`Option#${option.option}: ${option.price} ${
                announce.devise
              } ${formatMessage({
                id: `src.components.memberPage.tabs.annonces.details.AddOption.option${option.type}`,
              })}`}
            </div>
          );
        })}
      </div>
    );
  }
  function getPriceDatesData(announce) {
    if (announce.dates) {
      const data = [];
      let valid = null,
        rowData = [],
        bookingsByDay = [],
        text = null;
      announce.dates.forEach((date, idx) => {
        if (process.env.REACT_APP_NODE_ENV !== "development")
          switch (announce.datesType) {
            case "Fixed_Fixed":
              valid = parseISO(date.period.dateStart) >= new Date();
              break;
            case "Flex_Flex":
              valid = parseISO(date.period.dateEnd) >= new Date();
              break;
            case "Flex_Fixed":
              valid =
                addDays(new Date(), date.duration.nbDays) <=
                parseISO(date.period.dateEnd);
          }
        else valid = true;
        if (valid) {
          rowData = [];
          bookingsByDay = initBookingsByDay(
            formatDate(date.period.dateStart),
            formatDate(date.period.dateEnd),
            _.cloneDeep(date.bookingsByDay),
            formatDate
          );
          rowData.push(
            formatDate(date.period.dateStart),
            formatDate(date.period.dateEnd)
          );
          switch (announce.datesType) {
            case "Fixed_Fixed":
              text = testGuaranteedDeparture(
                1,
                [announce.dates[idx]],
                announce.participantMin,
                announce.participantMax
              );
              rowData.push(
                differenceInCalendarDays(
                  formatDate(date.period.dateEnd),
                  formatDate(date.period.dateStart)
                ) + 1, //stay duration
                `${
                  bookingsByDay
                    ? bookingsByDay[0].bookings["0"] + //sum up object's props values for the 1st day (in Fixed_Fixed case, bookings for each day of the period is the same)
                      bookingsByDay[0].bookings["1"] +
                      Number(
                        announce.companionIncluded
                          ? bookingsByDay[0].bookings["2"]
                          : 0
                      )
                    : 0
                } / ${announce.participantMax}`,
                text
                  ? formatMessage({
                      id: text,
                    })
                  : null
              );
              break;
            case "Flex_Fixed":
              rowData.push(announce.nbDays); //stay duration=announce nbDays
          }
          switch (announce.datesType) {
            case "Flex_Fixed":
            case "Flex_Flex":
              rowData.push(
                `${announce.participantMin} / ${announce.participantMax}`
              );
          }
          rowData.push(
            date.comments ? date.comments[locale] : null,
            getPriceRecap(date.promotion),
            getOptionRecap(announce.options),
            date.promotion ? "-" + date.promotion + "%" : "",
            idx,
            date.promotion,
            bookingsByDay
          );
          data.push(rowData);
        }
      });
      return data;
    }
  }
  function prepareData(headCells, announce) {
    const data = getPriceDatesData(announce);
    const rows = [];
    let obj = {};
    data.map((record) => {
      obj = {};
      record.map((item, idx) => {
        try {
          obj[headCells[idx].name] = item;
        } catch (error) {
          switch (`${idx}-${announce.datesType}`) {
            case "7-Flex_Flex":
            case "8-Flex_Fixed":
            case "10-Fixed_Fixed":
              obj.promotion = item;
              break;
            case "8-Flex_Flex":
            case "9-Flex_Fixed":
            case "11-Fixed_Fixed":
              obj.bookingsByDay = item;
          }
        }
      });
      rows.push(obj);
    });
    return rows;
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((row) => row.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [...selected];
    if (selectedIndex === -1) newSelected.push(id);
    else newSelected.splice(selectedIndex, 1);
    setSelected(newSelected);
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const filterData = (filter) => {
    if (filter) {
      const filtered = _.filter(rows, (row, idx) => {
        return selected.indexOf(row.id) !== -1;
      });
      setRows(filtered);
      numFiltered = filtered.length;
    } else {
      setRows(original);
      numFiltered = 0;
    }
  };
  function sortRows(data, order, orderBy) {
    return data.sort((a, b) => {
      switch (orderBy) {
        case headCells[0].name: //departure/from
          a = a[headCells[0].name];
          b = b[headCells[0].name];
          break;
        case headCells[1].name: //return/to
          a = a[headCells[1].name];
          b = b[headCells[1].name];
          break;
        case headCells[2].name: //duration
          a = a[headCells[2].name];
          b = b[headCells[2].name];
          break;
        case headCells[3].name: //people capacity
          a = a[headCells[3].name];
          b = b[headCells[3].name];
          break;
        case "status":
          a = a[headCells[4].name].toLowerCase();
          b = b[headCells[4].name].toLowerCase();
          break;
        case "comment":
          a = a[headCells[4].name].toLowerCase();
          b = b[headCells[4].name].toLowerCase();
          break;
        case "price":
          if (!a["price"].props["children"][0])
            a = Number(
              a["price"].props["children"].split(":")[1].split("€")[0]
            );
          else
            a =
              Number(
                a["price"].props["children"][0].split(":")[1].split("€")[0]
              ) +
              Number(
                a["price"].props["children"][2]
                  ? a["price"].props["children"][2].split(":")[1].split("€")[0]
                  : 0
              ) +
              Number(
                a["price"].props["children"][4]
                  ? a["price"].props["children"][4].split(":")[1].split("€")[0]
                  : 0
              );
          if (!b["price"].props["children"][0])
            b = Number(
              b["price"].props["children"].split(":")[1].split("€")[0]
            );
          else
            b =
              Number(
                b["price"].props["children"][0].split(":")[1].split("€")[0]
              ) +
              Number(
                b["price"].props["children"][2]
                  ? b["price"].props["children"][2].split(":")[1].split("€")[0]
                  : 0
              ) +
              Number(
                b["price"].props["children"][4]
                  ? b["price"].props["children"][4].split(":")[1].split("€")[0]
                  : 0
              );
          break;
        case "option":
          a = (
            a["option"].props
              ? a["option"].props["children"][0].props["children"]
              : a["option"]
          ).toLowerCase();
          b = (
            b["option"].props
              ? b["option"].props["children"][0].props["children"]
              : b["option"]
          ).toLowerCase();
          break;
        case "promotion":
        case "promo":
          a = a["promotion"];
          b = b["promotion"];
      }
      if (a > b) return Number(order === "asc" ? 1 : -1);
      if (a < b) return Number(order === "asc" ? -1 : 1);
      return 0;
    });
  }
  return (
    <Box sx={{width: "100%"}}>
      <Paper sx={{width: "100%", mb: 2}}>
        <EnhancedTableToolbar
          announce={announce}
          locale={locale}
          formatMessage={formatMessage}
          rows={rows}
          selected={selected}
          numSelected={selected.length}
          theme={themes.toolbar}
          onFilter={filterData}
          onHandleFormBooking={onHandleFormBooking}
        />
        <TableContainer sx={{maxHeight: "43vh"}}>
          <Table
            stickyHeader
            aria-label="sticky table"
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              theme={themes.header}
              sx={themes.header_sx}
            />
            <ThemeProvider theme={themes.body}>
              <TableBody>
                {rows.length > 0 ? (
                  sortRows(rows, order, orderBy).map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        {Object.keys(row).map((item, idx) => {
                          return idx === 0 ? (
                            <TableCell
                              key={idx}
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              align={headCells[idx].align}
                            >
                              {isDate(row[headCells[idx].name])
                                ? format(row[headCells[idx].name], "dd.MM.yyyy")
                                : row[headCells[idx].name]}
                            </TableCell>
                          ) : (
                            idx <= headCells.length - 1 && (
                              <TableCell
                                key={idx}
                                align={headCells[idx].align}
                                hidden={headCells[idx].hidden ? true : false}
                              >
                                {isDate(row[headCells[idx].name])
                                  ? format(
                                      row[headCells[idx].name],
                                      "dd.MM.yyyy"
                                    )
                                  : row[headCells[idx].name]}
                              </TableCell>
                            )
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <div className="member-control-container my-2">
                        <p
                          id="no-dates-PH"
                          className="member-control placeholder"
                        >
                          {`${formatMessage({
                            id: "src.components.announcePage.booking.noDates_PH",
                          })}`}
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </ThemeProvider>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
