import {useState, useEffect} from "react";
import {useIntl} from "react-intl";
import {useCookies} from "react-cookie";
import Meta from "../common/Meta";
import HomeCarousel from "./pageElements/HomeCarousel";
import HomeToursPackage from "./pageElements/HomeToursPackage";
import HomeTop from "./pageElements/HomeTop";

function PageContent({preloaded, announces}) {
  const {locale} = useIntl();
  const [cookies, setCookie, removeCookie] = useCookies(["filter"]);
  const [state, setState] = useState([]);
  useEffect(() => {
    function loadData() {
      const top3 = [];
      let i = 0;
      for (const ann of announces.data) {
        if (ann.title[locale]) {
          top3.push(ann);
          if (i === 2 || i === announces.data.length - 1) break;
          i++;
        }
      }
      setState(top3);
    }
    if (announces) {
      loadData();
      window.addEventListener("onload", removeCookie("filter"));
      return () => {
        window.removeEventListener("onload", removeCookie("filter"));
      };
    }
  }, [announces, locale]);
  return (
    state && (
      <div>
        <h1 className="invisible">HorseAround</h1>
        <Meta
          id="home"
          canonicalUrl={`https://horse-around.com/${locale}/`}
        ></Meta>
        <div>
          <div className="carousel-wrapper">
            <HomeCarousel preloaded={preloaded} />
          </div>
          <>
            <br />
            <br />
            <HomeToursPackage announces={announces} />
            <HomeTop top3={state}></HomeTop>
          </>
        </div>
      </div>
    )
  );
}

export default PageContent;
