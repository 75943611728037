import {useIntl} from "react-intl";
import {Helmet} from "react-helmet";

function Meta({id, title1, desc2, canonicalUrl}) {
  const {locale, formatMessage} = useIntl();
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <html lang={locale} />
      <title>
        {title1
          ? `${title1}${formatMessage({id: `metaData.${id}.title`})}`
          : `${formatMessage({id: `metaData.${id}.title`})}`}
      </title>
      <meta
        name="description"
        content={
          desc2 ? desc2 : `${formatMessage({id: `metaData.${id}.description`})}`
        }
      />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Helmet>
  );
}

export default Meta;
