function StandardInfoMulti({data}) {
  if (data === null) return null;
  return (
    <div className="col my-2 p-0">
      <h3>{data.title}</h3>
      {data.paragraphs.map((para, idx) => {
        return (
          <ul key={idx} className="pl-4 mb-0">
            <h4 key={idx} style={{lineHeight: "2.3rem"}}>
              {para}
            </h4>
          </ul>
        );
      })}
    </div>
  );
}

export default StandardInfoMulti;
