import {useState, useEffect, lazy, Suspense} from "react";
import {useQuery} from "react-query";
import {useIntl} from "react-intl";
import {useCookies} from "react-cookie";
import {ThemeProvider} from "@mui/material";
import _ from "lodash";
import {useLocation, useNavigate, Route, Routes} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {sliderData} from "../src/components/pages/home/img/slider/sliderData.js";
import PageLoader from "./components/pages/common/loading/PageLoader.jsx";
import {getMuiTheme} from "./AppMuiTheme";
import ContainerToast from "./components/pages/common/toastSwal/ContainerToast.jsx";
import Header from "./components/pages/common/Header";
import HomePage from "./components/pages/home/HomePage";
import Footer from "./components/pages/common/Footer";
import {getCompanies} from "./services/httpUsers.js";
import {getAnnounces} from "./services/httpAnnounces.js";
import {getImagesTemplate, getImagesByAnnId} from "./services/httpImages.js";
import {
  getProfileAction,
  getCorporateAction,
  getUnreadAction,
  getAdminPendingAction,
  getAdminUrlsAction,
  getBookingAction,
  getInvoiceAction,
} from "./services/httpMasterBadge.js";
import {errorHandlingToast, getRefreshTime} from "./services/utilsFunctions.js";
import UserContext from "./components/pages/common/context/UserContext.js";
import ProContext from "./components/pages/common/context/ProContext.js";
import ImagesContext from "./components/pages/common/context/ImagesContext";
import {checkDateInFuture} from "./components/pages/member/announces/form/AnnounceForm.jsx";
import {decodeJWT} from "./services/httpUsers.js";
import {
  setLoginTimeOut,
  runSwalTimer,
  runNavBarTimer,
} from "./components/pages/logIn&Out/FormLogin.jsx";
import "../src/css/App.css";
import "./app.css";
import "../src/css/font-awesome/css/font-awesome.min.css";

const MemberPage = lazy(() =>
  import("./components/pages/member/MemberPage.jsx")
);
const ActivityPage = lazy(() =>
  import("./components/pages/common/ActivityPage.jsx")
);
const NotFound = lazy(() => import("./components/pages/NotFound"));
const LegalNotice = lazy(() => import("./components/pages/LegalNotice"));
const AboutUs = lazy(() => import("./components/pages/AboutUs"));
const Support = lazy(() => import("./components/pages/Support"));
const CguCgv = lazy(() => import("./components/pages/CguCgv"));
const AnnouncesPage = lazy(() =>
  import("./components/pages/announces/AnnouncesPage")
);
const AnnouncePage = lazy(() =>
  import("./components/pages/announce/AnnouncePage")
);
const AnnounceForm = lazy(() =>
  import("./components/pages/member/announces/form/AnnounceForm.jsx")
);
const ViewerPage = lazy(() =>
  import("./components/pages/common/viewer/ViewerPage.jsx")
);
const FormRecover = lazy(() =>
  import("./components/pages/logIn&Out/FormRecover")
);

function App() {
  function getResetOthers() {
    let bl = false;
    ["about", "mentions-legales", "support", "CGU-CGV"].map((item) => {
      if (!bl && window.location.pathname.includes(item)) bl = true;
    });
    return bl;
  }
  const reset = {
    resetpassword: window.location.pathname.includes("resetpassword"),
    viewFile: window.location.pathname.includes("viewFile"),
    resetOthers: getResetOthers(),
  };
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const currentUser = cookies.user ? decodeJWT(cookies.user) : null;
  const contextImages = useIntl().formats; //used when switching languages (coming from LanguageSwitch) >>> avoids images reload when switching language
  const {locale, formatMessage} = useIntl();
  const location = useLocation();
  useEffect(() => {
    const url_lang = location.pathname.slice(1, 3);
    if (url_lang === locale) return;
    const elt = document.getElementById(`main-navbar-${url_lang}-flag`);
    if (elt) elt.click();
  }, []);
  const navigate = useNavigate();
  const [preloaded, setPreloaded] = useState(null);
  useEffect(() => {
    setPreloaded(sliderData.slider1);
    removeCookie("filter");
  }, []);
  useEffect(() => {
    if (!currentUser) return;
    const exp = currentUser.exp; //seconds since EPOCH
    const ttg = exp * 1000 - Date.now();
    if (window.localStorage.getItem("spareToken")) {
      //Swal Ok or cancel buttons not hit
      if (ttg > 302000) {
        //5mns +2s margin, count down not yet started
        setLoginTimeOut(
          formatMessage,
          setCookie,
          removeCookie,
          navigate,
          exp,
          null, //userContext=null
          currentUser._id,
          cookies.user,
          locale,
          "App.js"
        );
      } else if (ttg > 2000)
        runSwalTimer(
          locale,
          setCookie,
          removeCookie,
          navigate,
          null, //userContext=null
          formatMessage,
          Math.round(ttg / 1000) - 2
        );
    } else {
      //running on nav bar timer
      runNavBarTimer(
        Math.round(ttg / 1000) - 2, //2s margin
        locale,
        removeCookie,
        navigate,
        null, //userContext=null
        formatMessage
      );
    }
  }, []); //page refresh indicator for login timeout processing
  useEffect(() => {
    if (location.pathname === "/") navigate(`/${locale}`);
  }, []);
  useEffect(() => {
    if (
      !currentUser &&
      location.pathname.includes("member") &&
      document.getElementById("loginButtonHorseAround")
    )
      document.getElementById("loginButtonHorseAround").click();
  }, [document.getElementById("loginButtonHorseAround")]);
  const [user, setUser] = useState({});
  const [pro, setPro] = useState({});
  const abortController = new AbortController();
  useEffect(() => {
    async function loadCompanies(signal) {
      const res = await getCompanies(signal);
      if (!(await errorHandlingToast(res, locale, false))) {
        setPro(res.data);
      }
    }
    loadCompanies(abortController.signal);
    return () => {
      abortController.abort(); //clean-up code after component has unmounted
    };
  }, []);
  const [anns, setAnns] = useState({});
  const [state, setState] = useState({});
  const [dirty, setDirty] = useState(false);
  const [masterBadge, setMasterBadge] = useState({});
  const [spinner, setNavSpinner] = useState(false);
  async function initMasterBadge(usr, signal) {
    if (!usr) {
      setMasterBadge({});
      return;
    }
    await Promise.all([
      await getProfileAction(usr._id, usr.type, usr.role, cookies.user, signal),
      usr.type === "pro" || usr.role === "ADMIN"
        ? await getCorporateAction(usr._id, cookies.user, signal) //non owner admin returns false (no action)
        : Promise.resolve({data: false}),
      usr.role === "ADMIN"
        ? await getAdminPendingAction(cookies.user, signal)
        : Promise.resolve({data: false}),
      usr.role === "ADMIN"
        ? await getAdminUrlsAction(cookies.user, signal)
        : Promise.resolve({data: false}),
      usr.status === "ACTIVE" && usr.role !== "ADMIN"
        ? await getBookingAction(usr._id, usr.type, cookies.user, signal)
        : Promise.resolve({data: false}),
      usr.status === "ACTIVE" && (usr.type === "pro" || usr.role === "ADMIN")
        ? await getInvoiceAction(usr._id, usr.type, cookies.user, signal)
        : Promise.resolve({data: false}),
      usr.status === "ACTIVE"
        ? await getUnreadAction(usr._id, cookies.user, signal)
        : Promise.resolve({data: {othersToMe: 0, meToOthers: 0}}),
    ])
      .then((results) => {
        const keys = [
            "profile",
            "corporate",
            "adminPending",
            "adminUrls",
            "bookings",
            "invoices",
            "unread",
          ],
          obj = {};
        let action = false;
        results.map((item, idx) => {
          obj[keys[idx]] = item.data;
          if (!action && idx < 6 && item.data) action = true;
          if (!action && idx === 6 && item.data.othersToMe > 0) action = true;
        });
        setMasterBadge({action, ...obj});
      })
      .catch((err) => {
        console.log("Promise rejected in App.js initMasterBadge()", err);
      });
  }
  useQuery(
    "masterBadge",
    () => {
      initMasterBadge(
        Object.keys(user).length > 0 ? user : currentUser,
        abortController.signal
      );
    },
    getRefreshTime("App.js", "interval")
  );
  useEffect(() => {
    const n = Object.keys(user).length;
    if (n === 0 && !currentUser) {
      setMasterBadge({});
      return;
    }
    initMasterBadge(n > 0 ? user : currentUser);
  }, [user]);
  async function loadData(signal) {
    if (JSON.stringify(reset).indexOf(true) !== -1) return null; //no data loading when resetting password or viewing file in a new window
    try {
      console.log("start announces loading", new Date());
      const res = await getAnnounces(
        null, //status=any
        null, //archived=any
        null, //all fields to be retrieved
        null, //limit parameter set to null > retrieves all announces from the database
        null, //lang parameter set to null
        "-numberRatings",
        signal
      );
      let filtered = _.filter(res.data.anns, {
          status: "publique",
          archived: false,
        }),
        cond = null;
      // if (process.env.REACT_APP_NODE_ENV !== "development")
      //   filtered = _.filter(filtered, (ann) => {
      //     cond = false;
      //     ann.dates.map((date) => {
      //       if (!cond && checkDateInFuture(date)) cond = true;
      //     });
      //     return cond;
      //   });
      setAnns({
        announces: {data: filtered, len: filtered.length},
        allAnnounces: {data: res.data.anns, len: res.data.nb},
      });
      console.log("announces loading complete", new Date());
    } catch (error) {
      alert(
        "An error has occured in App.js announces data fetching : " +
          error.message
      );
      abortController.abort(); //clean-up code
      return "errorApp.js";
    }
  }
  async function loadMainImages(contextImages, signal) {
    if (JSON.stringify(reset).indexOf(true) !== -1) return null; //no data loading when resetting password or viewing file in a new window
    if (
      Object.keys(contextImages).length > 0 &&
      Object.keys(contextImages.state).length > 0
    ) {
      setState(contextImages.state);
      return;
    }
    const msg = "An error has occured in App.js images data fetching : ";
    try {
      console.log("start main images loading", new Date());
      let res = null;
      const imagesTemplate = {status: {}};
      res = await getImagesTemplate(signal);
      const emptyStatus = {
        // _id: null,
        main: 0,
        others: 0,
        total: 0,
      };
      res.data.map((item) => {
        imagesTemplate[item.id_announce] = Array(item.imagesCnt).fill({
          name: "",
          main: false,
          type: "",
          size: 0,
          lastModified: 0,
          data: "",
        });
        imagesTemplate.status[item.id_announce] = {
          ...emptyStatus,
          // _id: item._id,
          total: item.imagesCnt,
        };
      });
      const imgs = {status: _.cloneDeep(imagesTemplate.status)};
      await Promise.all(
        Object.keys(imagesTemplate.status).map(async (id, idx) => {
          res = await getImagesByAnnId(id, true, signal); //main images loading only, other images loaded as needed in AnnouncePage
          if (!(await errorHandlingToast(res, locale, false)))
            if (!imgs[res.data.id_announce]) {
              imgs[res.data.id_announce] = res.data.images;
              imgs.status[res.data.id_announce].main = 1;
            }
        })
      )
        .then(() => {
          console.log("main images loading complete", new Date());
          anns.allAnnounces.data.map((ann) => {
            if (!imgs[ann._id]) {
              imgs[ann._id] = [];
              imgs.status[ann._id] = {
                ...emptyStatus,
                // _id: ann._id,
              };
            }
          });
          setState(imgs);
        })
        .catch((error) => {
          console.log(msg + error.message);
          abortController.abort(); //clean-up code
        });
    } catch (error) {
      alert(msg + error.message);
      abortController.abort(); //clean-up code
      return "errorApp.js";
    }
  }
  const {isLoading, error, data} = useQuery(
    "announces",
    () => loadData(abortController.signal),
    getRefreshTime("App.js")
  );
  useQuery(
    "images",
    () => {
      loadMainImages(contextImages, abortController.signal);
    },
    {
      enabled:
        anns && anns.allAnnounces && anns.allAnnounces.data.length > 0
          ? true
          : false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
  if (data === "errorApp.js") return null;
  // if (isLoading) return <LoadingPage></LoadingPage>;
  if (error) {
    alert("An error has occured in App.js useQuery hook : " + error.message);
    return null;
  }
  function handleSaveDelete(cs, id, body) {
    //save, delete operation in AnnounceForm
    const data = _.cloneDeep(anns);
    const n = data.allAnnounces.len;
    switch (cs) {
      case "save":
        const keys = Object.keys(body);
        for (let i = 0; i < n; i++) {
          if (data.allAnnounces.data[i]._id === id) {
            //edit announce case
            keys.map((key) => {
              data.allAnnounces.data[i][key] = body[key];
            });
            break;
          }
          if (i === n - 1) {
            //new announce case
            const dta = {_id: id};
            keys.map((key) => {
              dta[key] = body[key];
            });
            data.allAnnounces.data.push(dta);
            data.allAnnounces.len = n + 1;
          }
        }
        break;
      case "delete":
        for (let i = 0; i < n; i++) {
          if (data.allAnnounces.data[i]._id === id) {
            data.allAnnounces.data.splice(i, 1);
            data.allAnnounces.len = n - 1;
            break;
          }
        }
        break;
      case "images":
        let imgs = _.cloneDeep(state);
        switch (id) {
          case "save": //images can be added or removed
            const n = body.images.length;
            if (n === 0) {
              delete imgs[body.id_announce];
              delete imgs.status[body.id_announce];
            } else {
              imgs[body.id_announce] = body.images;
              imgs.status[body.id_announce] = {
                main: 1,
                others: n - 1,
                total: n,
              };
            }
            break;
          case "delete": //full announce deletion
            // imgs = _.filter(imgs, (img) => {
            //   return img.id_announce !== body; //body=id_announce
            // });
            delete imgs[body]; //body=id_announce
            delete imgs.status[body];
        }
        setState(imgs);
        return;
    }
    setAnns(data);
  }
  function wrapElement(element, id = null, footer = true) {
    return (
      <div id={id} className="main-wrapper">
        <Header
          announces={
            process.env.REACT_APP_NODE_ENV !== "development"
              ? anns.announces
              : anns.allAnnounces
          }
          dirty={dirty}
          masterBadge={masterBadge.action}
          spinner={spinner}
          onHandleDirty={(bl) => {
            setDirty(bl);
          }}
        />
        {element}
        {footer && !reset.viewFile && <Footer noLink={reset.resetpassword} />}
        {isLoading && <PageLoader></PageLoader>}
      </div>
    );
  }
  function handleUser(data) {
    setUser(data);
  }
  function handlePro(key, data) {
    setPro(
      data !== null
        ? {...pro, [key]: data}
        : _.filter(pro, (ky) => {
            return ky !== key; //remove pro[key]
          })
    );
  }
  function handleProInit(data) {
    setPro(data);
  }
  function handleImages(data) {
    //data >>> new other images to be inserted in ImagesContext
    const imgs = _.cloneDeep(state);
    if (Object.keys(imgs).length === 0) return;
    Object.keys(data).map((ann_id) => {
      imgs[ann_id] = [...imgs[ann_id], ...data[ann_id]];
      imgs.status[ann_id] = {
        ...imgs.status[ann_id],
        others: imgs.status[ann_id].total - 1,
      };
    });
    setState(imgs);
  }
  function getRoutes(lang) {
    return (
      <>
        <Route
          path={`/${lang}/`}
          element={wrapElement(
            <HomePage
              isLoading={isLoading}
              preloaded={preloaded}
              announces={anns.announces}
            />
          )}
        ></Route>
        <Route
          path={`/${lang}/activities/:id1/:id2`}
          element={wrapElement(
            <Suspense fallback={<PageLoader></PageLoader>}>
              <ActivityPage />
            </Suspense>
          )}
        ></Route>
        <Route
          path={`/${lang}/destinations/:id1/:id2`}
          element={wrapElement(
            <Suspense fallback={<PageLoader></PageLoader>}>
              <AnnouncesPage announces={anns.announces} />
            </Suspense>
          )}
        ></Route>
        <Route
          path={`/${lang}/member`}
          element={wrapElement(
            <Suspense fallback={<PageLoader></PageLoader>}>
              <MemberPage
                announces={anns.allAnnounces}
                pro={pro}
                masterBadge={masterBadge}
                onHandleSaveDelete={handleSaveDelete} //update, deletion made in the member page announces or bookings table
                onHandleDirty={(bl) => {
                  setDirty(bl);
                }}
                onHandleBadges={(bdgs) => {
                  setMasterBadge(bdgs);
                }}
                onHandleNavSpinner={(bl) => {
                  setNavSpinner(bl);
                }}
              ></MemberPage>
            </Suspense>
          )}
        ></Route>
        <Route
          path={`/${lang}/member/announces/edit/:id`}
          element={wrapElement(
            <Suspense fallback={<PageLoader></PageLoader>}>
              <AnnounceForm
                data={anns.allAnnounces}
                onHandleSaveDelete={handleSaveDelete} //deletion made in the announce form (edit case)
                onHandleDirty={(bl) => {
                  setDirty(bl);
                }}
              />
            </Suspense>
          )}
        ></Route>
        <Route
          path={`/${lang}/member/announces/new`}
          element={wrapElement(
            <Suspense fallback={<PageLoader></PageLoader>}>
              <AnnounceForm
                onHandleSaveDelete={handleSaveDelete} //deletion made in the announce form (creation case)
                onHandleDirty={(bl) => {
                  setDirty(bl);
                }}
              />
            </Suspense>
          )}
        ></Route>
        <Route
          path={`/${lang}/announces`}
          element={wrapElement(
            <Suspense fallback={<PageLoader></PageLoader>}>
              <AnnouncesPage announces={anns.announces} pro={pro} />
            </Suspense>
          )}
        ></Route>
        <Route
          path={`/${lang}/announce/details`}
          element={wrapElement(
            <Suspense fallback={<PageLoader></PageLoader>}>
              <AnnouncePage
                announces={anns.allAnnounces}
                pro={pro}
                onHandleNavSpinner={(bl) => {
                  setNavSpinner(bl);
                }}
                onHandleBookingBadge={(bl) => {
                  //booking creation by 'particulier' user
                  const bdgs = _.cloneDeep(masterBadge);
                  bdgs.action = true;
                  bdgs.bookings = true;
                  setMasterBadge(bdgs);
                }}
              />
            </Suspense>,
            "main-wrapper-ann-page",
            false
          )}
        ></Route>
        <Route
          path={`/${lang}/about`}
          element={wrapElement(
            <Suspense fallback={<PageLoader></PageLoader>}>
              <AboutUs />
            </Suspense>
          )}
        ></Route>
        <Route
          path={`/${lang}/mentions-legales`}
          element={wrapElement(
            <Suspense fallback={<PageLoader></PageLoader>}>
              <LegalNotice />
            </Suspense>
          )}
        ></Route>
        <Route
          path={`/${lang}/support`}
          element={wrapElement(
            <Suspense fallback={<PageLoader></PageLoader>}>
              <Support />
            </Suspense>
          )}
        ></Route>
        <Route
          path={`/${lang}/CGU-CGV`}
          element={wrapElement(
            <Suspense fallback={<PageLoader></PageLoader>}>
              <CguCgv />
            </Suspense>
          )}
        ></Route>
        <Route
          path={`/${lang}/resetpassword/:id/:token`}
          element={wrapElement(
            <Suspense fallback={<PageLoader></PageLoader>}>
              <FormRecover />
            </Suspense>
          )}
        ></Route>
        <Route
          path={`/${lang}/viewFile`}
          element={wrapElement(
            <Suspense fallback={<PageLoader></PageLoader>}>
              <ViewerPage params={null} />
            </Suspense>
          )}
        ></Route>
        <Route
          path={`*`}
          element={wrapElement(
            <Suspense fallback={<PageLoader></PageLoader>}>
              <NotFound />
            </Suspense>
          )}
        ></Route>
      </>
    );
  }
  return (
    <ThemeProvider theme={getMuiTheme()}>
      <UserContext.Provider value={{user, onHandleUser: handleUser}}>
        <ProContext.Provider
          value={{
            pro,
            onHandleProInit: handleProInit,
            onHandlePro: handlePro,
          }}
        >
          <ImagesContext.Provider value={{state, onHandleImages: handleImages}}>
            <Routes>
              {getRoutes("en")}
              {getRoutes("fr")}
            </Routes>
            <ContainerToast></ContainerToast>
          </ImagesContext.Provider>
        </ProContext.Provider>
      </UserContext.Provider>
    </ThemeProvider>
  );
}
export default App;
