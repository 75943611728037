import {useState, useEffect} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {range} from "../../../utils/utilityFunctions.js";
import LevelInfo from "../../../announce/details/common/LevelInfo.jsx";
import {isEven} from "../../../utils/utilityFunctions.js";

function LevelRating({
  label = true,
  reset,
  dataIn,
  l0,
  l1,
  onHandleGlobals,
  rt = null,
}) {
  //validation not needed because there always exists a valid value from the dropdown select
  const {formatMessage} = useIntl();
  const {name, data: dta} = dataIn;
  const [value, setValue] = useState("");
  useEffect(() => {
    const data = isEven(reset) ? dta.default : dta.saved;
    setValue(data);
  }, [reset]);
  function handleChange(e) {
    setValue(e.target.value);
    onHandleGlobals("value", [e.target.id, e.target.value]);
  }
  return (
    <div className={`simple-text-block ${!label ? "ml-0" : ""}`}>
      {label && (
        <label className="member-label">
          <FormattedMessage
            id={`src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.${name}`}
          />
        </label>
      )}
      <div className="member-control-container info">
        <select
          id={name}
          className="member-control"
          onChange={handleChange}
          value={value}
        >
          {range(
            name === "comfortLeveL" ? 0 : 1, //comfortLevel=1 >>> not applicable, 2=bivouac ...,6=luxurious
            l1.length + Number(name === "comfortLeveL" ? -1 : 0)
          ).map((lvl) => {
            return (
              <option key={lvl} value={lvl}>
                {formatMessage({
                  id: `src.components.memberPage.tabs.annonces.details.AddAnnounceForm.span${
                    l1[lvl - 1]
                  }`,
                }).replace(":", "")}
              </option>
            );
          })}
        </select>
        <LevelInfo
          label={null}
          l0={`src.components.memberPage.tabs.annonces.details.AddAnnounceForm.header${l0}`}
          l1_title="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.span"
          l1_desc="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.description"
          range={l1}
          rating={null}
          rt={rt}
        ></LevelInfo>
      </div>
    </div>
  );
}

export default LevelRating;
