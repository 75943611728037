import {Avatar, Card} from "@mui/material";
import {useCookies} from "react-cookie";
import {decodeJWT} from "../../../../services/httpUsers.js";
import {getFormattedDate} from "../../utils/utilityFunctions.js";

function ChatList({announceId, messages, locale, onHandleReply, onHandleRead}) {
  const [cookies] = useCookies(["user"]);
  const currentUser = cookies.user ? decodeJWT(cookies.user) : null;
  function checkSource(id_sender) {
    //check if a message has been created by the logged in user or not
    return currentUser._id === id_sender;
  }
  let avatar = [];
  function getMsgIconColor(msg) {
    if (msg.msg.isRead) return "green";
    if (!checkSource(msg.msg.id_sender._id)) return "red";
    return "orange";
  }
  return (
    <>
      {messages.map((message, idx) => {
        avatar.push(
          <Avatar
            style={{margin: "5px"}}
            alt={message.msg.id_sender.firstName}
            src={`https://ui-avatars.com/api/?uppercase=false&name=${message.msg.id_sender.firstName}&background=random`}
          />
        );
        return (
          <Card
            key={message.msg._id}
            variant="outlined"
            style={{
              borderWidth: "1px",
              borderRadius: "5px",
              marginBottom: "10px",
              marginRight: "10px",
            }}
          >
            <div key={message._id} className="message-container">
              {avatar[idx]}
              <div className="message-toolbar">
                <i
                  className={`fa ${
                    !message.msg.isRead ? "fa-envelope" : "fa-envelope-open-o"
                  } fa-lg mr-3`}
                  style={{
                    color: getMsgIconColor(message),
                    cursor:
                      message.msg.id_sender._id === currentUser._id
                        ? null
                        : "pointer",
                  }}
                  onClick={() => {
                    if (message.msg.id_sender._id === currentUser._id) return;
                    onHandleRead(message.msg._id, !message.msg.isRead);
                  }}
                ></i>
                <span>{getFormattedDate(message.msg.sendDate)}</span>
                {!checkSource(message.msg.id_sender._id) ? (
                  <button
                    className="fa fa-reply fa-lg ml-3 mr-1"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "blue",
                    }}
                    onClick={() => {
                      onHandleReply(
                        avatar[idx],
                        message.msg.id_announce,
                        typeof message.msg.title !== "undefined"
                          ? message.msg.title
                          : "",
                        message.msg.id_sender,
                        message.msg._id,
                        message.conv
                      );
                    }}
                  ></button>
                ) : (
                  <span style={{color: "transparent"}}> {`rrrrr`}</span>
                )}
              </div>
              <div
                className="form-control title"
                style={{backgroundColor: "transparent"}}
              >
                {message.msg.title}
              </div>
              {!announceId && (
                <div
                  className="form-control announce-title mb-3"
                  style={{
                    color: "blue",
                    backgroundColor: "transparent",
                  }}
                >
                  {message.msg.id_announce.title[locale]}
                </div>
              )}
              <textarea
                className="form-control message"
                style={{backgroundColor: "transparent"}}
                readOnly={true}
                value={message.msg.message}
              ></textarea>
            </div>
          </Card>
        );
      })}
    </>
  );
}

export default ChatList;
