import {useRef, useState, useEffect} from "react";
import {useIntl} from "react-intl";
import {useCookies} from "react-cookie";
import LogInPopper from "./LogInPopper";

function LogIn() {
  const {formatMessage} = useIntl();
  const [cookies, setCookie] = useCookies(["user"]);
  const ref = useRef();
  const [state, setState] = useState(false);
  function handleClose(cs) {
    setTimeout(
      () => {
        setState(false);
      },
      cs !== 0 ? 2000 : 0
    );
  }
  return (
    <>
      <div
        ref={ref}
        id="loginButtonHorseAround"
        className="login btn btn-success "
        onClick={() => setState(true)}
      >
        {cookies.user
          ? formatMessage({id: "src.components.allPages.Menu.userSpace.logout"})
          : formatMessage({id: "src.components.allPages.Menu.userSpace.login"})}
      </div>
      <LogInPopper
        open={state}
        anchor={ref.current}
        onClose={handleClose}
      ></LogInPopper>
    </>
  );
}

export default LogIn;
