import React, {useState, useEffect} from "react";
import {FormattedMessage} from "react-intl";
import {StarRatingLevel} from "../../../../utils/Ratings";
import {Divider, Avatar, Grid, Paper} from "@mui/material";
import _ from "lodash";
import {getFormattedDate} from "../../../../utils/utilityFunctions";

function CommentsBlock({data}) {
  const [comments, setComments] = useState(null);
  useEffect(() => {
    setComments(data);
  }, [data]);
  return (
    comments !== null && (
      <Paper
        elevation={3}
        style={{
          padding: comments && comments.length >= 1 ? 20 : 10,
          width: "100%",
          maxWidth: comments && comments.length >= 1 ? 1500 : 750,
        }}
      >
        {comments && comments.length >= 1 ? (
          _.orderBy(
            comments,
            (comment) =>
              getFormattedDate(comment.creationDate, "yyyy:MM:dd HH:mm:ss"),
            ["desc"]
          ).map((comment, idx) => {
            return (
              <React.Fragment key={idx}>
                <Grid container>
                  <Grid item xs={5} xl="auto" sm="auto" md="auto" lg="auto">
                    <div className="d-flex flex-wrap">
                      <Avatar
                        alt={comment.firstName}
                        src={
                          "https://ui-avatars.com/api/?uppercase=false&name=" +
                          comment.firstName +
                          "&background=random"
                        }
                      />
                      <div className="d-flex flex-column align-items-left mx-2 my-3">
                        <h5
                          style={{
                            color: "blue",
                            fontSize: "1.5rem",
                            margin: 0,
                            width: 110,
                            overflowWrap: "break-word",
                          }}
                        >{`${comment.firstName} (${comment.city})`}</h5>
                        <h5>{`${getFormattedDate(
                          comment.creationDate,
                          "dd:MM:yyyy HH:mm"
                        )}`}</h5>
                        <StarRatingLevel
                          level={
                            (comment.cavalryNote +
                              comment.environmentLandscapeNote +
                              comment.qualityPriceNote +
                              comment.receptionNote) /
                            4
                          }
                        ></StarRatingLevel>
                      </div>
                    </div>
                  </Grid>
                  <Grid item justifyContent="left" xs={7} sm={9} md={8} lg={9}>
                    <div className="d-flex flex-column ml-4">
                      <h5
                        className="m-0 mr-5 font-weight-bold"
                        style={{
                          fontSize: "1.5rem",
                          overflowWrap: "break-word",
                        }}
                      >
                        {comment.text}
                      </h5>
                      <p
                        style={{
                          fontSize: "1.5rem",
                          paddingTop: "10px",
                          paddingRight: "15px",
                          textAlign: "justify",
                          overflowWrap: "break-word",
                          // width: "100%",
                        }}
                      >
                        {comment.desc}
                      </p>
                    </div>
                  </Grid>
                </Grid>
                {idx + 1 < comments.length && (
                  <Divider
                    flexItem
                    variant="fullWidth"
                    style={{margin: "5px 20px 10px 10px"}}
                  />
                )}
              </React.Fragment>
            );
          })
        ) : (
          <div id="no-comments">
            <FormattedMessage id="src.components.announcePage.comments.noComments"></FormattedMessage>
          </div>
        )}
      </Paper>
    )
  );
}

export default CommentsBlock;
