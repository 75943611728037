import NavBar from "./NavBar";

function Header({
  announces,
  reset,
  dirty,
  masterBadge,
  spinner,
  onHandleDirty,
}) {
  return !reset ? (
    <NavBar
      announces={announces}
      dirty={dirty}
      masterBadge={masterBadge}
      spinner={spinner}
      onHandleDirty={onHandleDirty}
    ></NavBar>
  ) : null;
}

export default Header;
