import {useState, useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import ReactModal from "react-modal-resizable-draggable";
import {useCookies} from "react-cookie";
import AnnounceProgram from "./AnnounceProgram";
import EquestrianPhysicalInfo from "./EquestrianPhysicalInfo";
import FormBooking from "./priceDatesTable/booking/FormBooking.jsx";
import PriceDatesTable from "./priceDatesTable/PriceDatesTable";
import AccomodationInfo from "./AccomodationInfo";
import PracticalInfo from "./practicalInfo/PracticalInfo";
import ProInfo from "./proInfo/ProInfo";
import Messaging from "../../member/messaging/Messaging.jsx";
import Options from "./Options.jsx";
import "../../../../css/draggable_resizable.css";
import Footer from "../../common/Footer.jsx";

let sec_id = null;
function AnnounceDetails({announce, pro, bookings, onHandleBookingBadge}) {
  const {locale, formatMessage} = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = [];
  const uRLSearch = new URLSearchParams(location.search);
  for (let item of uRLSearch) {
    queryParams.push(item);
  }
  let tab = 0;
  try {
    if (queryParams[1][0].includes("MyBookings")) {
      tab = 4; //return on PriceDatesTable (i.e 4th tab) when coming back from MemberPage Bookings tab (ref. to DataTable.jsx >>> EnhancedTableToolbar)
    }
  } catch (error) {}
  const sections = [
    "ann-description",
    "ann-programme",
    "ann-equestrianInfo",
    "ann-options",
    "ann-priceDate",
    "ann-houseFood",
    "ann-usefulInfo",
    "ann-hostingBody",
    "ann-contact",
  ];
  function getDistance(element, nav_rect) {
    const d = element.getBoundingClientRect().y - nav_rect.y - nav_rect.height;
    return Math.abs(d);
  }
  function getFirstSectionInView(rect) {
    let section = null,
      d = null,
      dmin = 10000,
      sec_min = null;
    sections.map((sec) => {
      section = document.getElementById(sec);
      if (section) {
        d = getDistance(section, rect);
        if (d >= 0 && d < dmin) {
          dmin = d;
          sec_min = sec;
        }
      }
    });
    return sec_min;
  }
  function handleFirstSectionInView(sec_min) {
    sections.map((sec) => {
      try {
        if (sec !== sec_min)
          document.getElementById(`li-${sec}`).classList.remove("active");
        else document.getElementById(`li-${sec}`).classList.add("active");
      } catch (error) {}
    });
  }
  function setSticky(navbar, bl) {
    if (bl) {
      navbar.classList.add("sticky");
      document.getElementById("ann-content").classList.add("sticky");
    } else {
      document.getElementById("li-ann-description").classList.remove("flag");
      navbar.classList.remove("sticky");
      document.getElementById("ann-content").classList.remove("sticky");
    }
  }
  useEffect(() => {
    if (tab === 4) {
      navigate(
        `${location.pathname}?${queryParams[0][0]}=${queryParams[0][1]}`, //remove 'MyBookings_ids, MyBookings_ann_id' parameter from url
        {replace: true, state: location.state}
      );
      handleNavbarClick("ann-priceDate");
    }
    const wrapper = document.getElementById("main-wrapper-ann-page");
    const content = document.getElementById("ann-content");
    const navbar = document.getElementsByClassName("ann-navbar")[0];
    const li_ann_description = document.getElementById("li-ann-description");
    function handleContentScroll(evt) {
      if (
        evt.target.classList.contains("sticky") &&
        !li_ann_description.classList.contains("flag")
      ) {
        li_ann_description.classList.add("flag");
        li_ann_description.click();
      }

      let rect = navbar.getBoundingClientRect();
      const sticky = rect.y;
      if (rect.width === 0) {
        //navbar is not displayed for low resolution devices
        setSticky(navbar, false);
        return;
      }
      if (content.scrollTop >= sticky && !navbar.classList.contains("sticky"))
        setSticky(navbar, true);
      if (content.scrollTop < 4) setSticky(navbar, false);
      wrapper.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        handleFirstSectionInView(getFirstSectionInView(rect));
      }, 500);
    }
    content.addEventListener("scroll", handleContentScroll);
    return () => {
      content.removeEventListener("scroll", handleContentScroll);
    };
  }, []);
  const [cookies] = useCookies(["user"]);
  function handleNavbarClick(id) {
    const navbar = document.getElementsByClassName("ann-navbar")[0];
    const section = document.getElementById(id);
    if (
      sec_id === id &&
      navbar.classList.contains("sticky") &&
      getFirstSectionInView(navbar.getBoundingClientRect()) === id
    )
      return;
    sec_id = id;
    document.getElementById("li-ann-description").classList.add("flag");
    if (sec_id === "ann-description" && !navbar.classList.contains("sticky"))
      setSticky(navbar, true);
    section.scrollIntoView();
    const cond = navbar.classList.contains("sticky");
    setTimeout(() => {
      if (!cond) section.scrollIntoView();
    }, 100);
  }
  const [modal, setOpenModal] = useState({open: false, dates: []});
  function handleFormModal(open, dates) {
    setOpenModal({open, dates});
  }
  function handleCloseModal(cs = null) {
    if (cs === "clickAway") return;
    setOpenModal({open: false, dates: []});
  }
  return (
    <>
      <nav className="ann-navbar">
        <ul className="ann-navbar-list">
          <li
            id="li-ann-description"
            className="list-group-item active"
            onClick={() => {
              handleNavbarClick("ann-description");
            }}
          >
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.description" />
          </li>
          <li
            id="li-ann-programme"
            className="list-group-item"
            onClick={() => {
              handleNavbarClick("ann-programme");
            }}
          >
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.programme" />
          </li>
          <li
            id="li-ann-equestrianInfo"
            className="list-group-item"
            onClick={() => {
              handleNavbarClick("ann-equestrianInfo");
            }}
          >
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.equestrianInfo" />
          </li>
          <li
            id="li-ann-options"
            className="list-group-item"
            onClick={() => {
              handleNavbarClick("ann-options");
            }}
          >
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.options" />
          </li>
          <li
            id="li-ann-priceDate"
            className="list-group-item"
            onClick={() => {
              handleNavbarClick("ann-priceDate");
            }}
          >
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.priceDate" />
          </li>
          <li
            id="li-ann-houseFood"
            className="list-group-item"
            onClick={() => {
              handleNavbarClick("ann-houseFood");
            }}
          >
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.houseFood" />
          </li>
          <li
            id="li-ann-usefulInfo"
            className="list-group-item"
            onClick={() => {
              handleNavbarClick("ann-usefulInfo");
            }}
          >
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.usefulInfo" />
          </li>
          <li
            id="li-ann-hostingBody"
            className="list-group-item"
            onClick={() => {
              handleNavbarClick("ann-hostingBody");
            }}
          >
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.hostingBody" />
          </li>

          <li
            id="li-ann-contact"
            className="list-group-item"
            onClick={() => {
              handleNavbarClick("ann-contact");
            }}
          >
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.contact" />
          </li>
        </ul>
      </nav>
      <section id="ann-dummy"></section>
      <section id="ann-description">
        <article>
          <h2 className="separator">
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.description" />
          </h2>
          <div className="ann-section-content">
            <p>{announce.description && announce.description[locale]}</p>
            {announce.highlights.length !== 0 && (
              <div>
                <br />
                <br />
                <div className="card">
                  <div
                    className="card-header"
                    style={{
                      backgroundColor: " #7AA095",
                      color: "white",
                    }}
                  >
                    <FormattedMessage id="src.components.announcePage.youWillLike" />
                  </div>
                  <div className="card-body">
                    {announce.highlights.map((highlight) => {
                      return (
                        <b>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            <i className="fa fa-circle-o" aria-hidden="true" />{" "}
                            {highlight[locale]}
                          </p>
                        </b>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </article>
      </section>
      <section id="ann-programme">
        <article>
          <h2 className="separator">
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.programme" />
          </h2>
          <AnnounceProgram announce={announce} />
        </article>
      </section>
      <section id="ann-equestrianInfo">
        <article>
          <h2 className="separator">
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.equestrianInfo" />
          </h2>
          <EquestrianPhysicalInfo announce={announce} full={true} />
        </article>
      </section>
      <section id="ann-options">
        <article>
          <h2 className="separator">
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.options" />
          </h2>
          <Options announce={announce}></Options>
        </article>
      </section>
      <section id="ann-priceDate" className="react-modal">
        <article>
          <ReactModal
            animation={false}
            initWidth={Math.min(0.8 * window.innerWidth, 1300)}
            //className="my-modal-custom-class"
            onRequestClose={() => {
              return; //cancel modal window closing wneh clicking outside modal >>> force closing by cancel button
            }}
            isOpen={modal.open}
            style={{zIndex: "100"}}
            disableKeystroke
          >
            <FormBooking
              announce={announce}
              data={modal.dates}
              onClose={handleCloseModal}
              byPro={false}
              onHandleBookingBadge={onHandleBookingBadge}
            ></FormBooking>
          </ReactModal>
          <h2 className="separator">
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.priceDate" />
          </h2>
          <PriceDatesTable
            announce={announce}
            onHandleFormBooking={handleFormModal}
          />
        </article>
      </section>
      <section id="ann-houseFood">
        <article>
          <h2 className="separator">
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.houseFood" />
          </h2>
          <AccomodationInfo announce={announce}></AccomodationInfo>
        </article>
      </section>
      <section id="ann-usefulInfo">
        <article>
          <h2 className="separator">
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.usefulInfo" />
          </h2>
          <PracticalInfo announce={announce}></PracticalInfo>
        </article>
      </section>
      <section id="ann-hostingBody">
        <article>
          <h2 className="separator">
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.hostingBody" />
          </h2>
          <ProInfo pro={pro} bookings={bookings} announce={announce}></ProInfo>
        </article>
      </section>
      <section id="ann-contact">
        <article>
          <h2 className="separator">
            <FormattedMessage id="src.components.announcePage.announceDetailTab.labels.contact" />
          </h2>
          <div className="ann-section-content">
            <h3>
              <FormattedMessage id="src.components.bookingPage.StepTwoContent.label" />
            </h3>
            {!cookies.user && (
              <div className="d-inline-flex alert alert-danger justify-content-center m-0 p-0 pl-4 pr-4 ">
                <h5>
                  <FormattedMessage id="src.components.announcePage.messaging.userLoggedIn"></FormattedMessage>
                </h5>
              </div>
            )}
            {cookies.user && (
              <Messaging
                pro={pro.id_user}
                announceId={announce._id}
                onHandleBadges={() => {}}
              ></Messaging>
            )}
          </div>
        </article>
      </section>
      <Footer></Footer>
    </>
  );
}

export default AnnounceDetails;
