import {useState, useEffect} from "react";
import {useIntl} from "react-intl";
import {toDate} from "date-fns";
import SimpleLabel from "../../common/SimpleLabel.jsx";
import SimpleText from "../../common/SimpleText.jsx";
import {getEmptyImage} from "./AnnounceForm.jsx";
import {getFormattedDate} from "../../../utils/utilityFunctions.js";
import config from "../../../../../config.json";
import {toastError} from "../../../common/toastSwal/ToastMessages.js";
import {arrayBufferToWebP} from "webp-converter-browser";
import "./upload.css";

let keys = Object.keys(getEmptyImage(0));
function ImageUnit({idx, dataIn, onHandleMain, onHandleGlobals}) {
  const {formatMessage} = useIntl();
  const [value, setValue] = useState(null);
  useEffect(() => {
    const data = dataIn ? {...dataIn, image: idx} : getEmptyImage(idx);
    setValue(data);
  }, [dataIn]);
  function handleClear(image) {
    document.getElementById(`selectFile${image}`).value = ""; //reset file selector
    onHandleGlobals("value", null, image);
  }
  function handleGlobals(cs, val, image) {
    if (cs === "valid") return; //no validation at all since it is optional
    onHandleGlobals("value", val, image);
  }
  function fileSize(size) {
    if (size < 1024) {
      return `${size} bytes`;
    } else if (size >= 1024 && size < 1048576) {
      return `${(size / 1024).toFixed(1)} KB`;
    } else if (size >= 1048576) {
      return `${(size / 1048576).toFixed(1)} MB`;
    }
  }
  function handleSelectedFile(e, image) {
    const file = e.target.files[0];
    function processData(val, image) {
      handleGlobals("value", val, image);
    }
    let val = getEmptyImage(0);
    delete val.image;
    if (typeof file !== "undefined") {
      const reader1 = new FileReader();
      reader1.onload = async function () {
        let name = file.name;
        const blob = await arrayBufferToWebP(reader1.result);
        if (blob.size > config.max_image_size) {
          toastError(
            formatMessage({
              id: "src.components.announcePage.announceDetailTab.program.maxSize",
            })
          );
          return;
        }
        const reader2 = new FileReader();
        reader2.onload = function () {
          if (!file.type.includes("webp")) {
            name = file.name.split(".");
            name.splice(-1, 1);
            name = name.join(".") + ".webp";
          }
          val = {
            ...val,
            name,
            type: blob.type,
            size: blob.size,
            lastModified: file.lastModified,
            data: reader2.result,
          };
          processData(val, image);
        };
        reader2.readAsDataURL(blob);
      };
      reader1.readAsArrayBuffer(file);
    } else processData(val, image);
  }
  function displayData(key, val) {
    switch (key) {
      case "size":
        return fileSize(val);
      case "lastModified":
        if (val === 0) return "";
        else return getFormattedDate(toDate(val));
      default:
        return val;
    }
  }
  return (
    value && (
      <div className="image-container">
        <div className="d-flex justify-content-between">
          <button type="button" className="dropdown singleDrop btn btn-success">
            {formatMessage({
              id: "src.components.announcePage.announceDetailTab.program.image",
            })}
            <span className="badge badge-light mx-2">{idx}</span>
            <i
              className="fa fa-trash fa-lg ml-4"
              onClick={() => {
                handleClear(idx);
              }}
            ></i>
          </button>
          <button
            className="dropdown singleDrop btn btn-success"
            onClick={() => {
              document.getElementById(`selectFile${idx}`).click();
            }}
          >
            {formatMessage({
              id: "src.components.announcePage.announceDetailTab.program.chooseFile",
            })}
            <i className="fa fa-upload fa-lg ml-4"> </i>
            <input
              id={`selectFile${idx}`}
              className="upload"
              type="file"
              accept="image/*"
              onChange={(e) => {
                handleSelectedFile(e, idx);
              }}
            />
          </button>
        </div>
        {keys.map((key, i) => {
          if (key !== "image" && key !== "data")
            return (
              <div
                key={i}
                className="d-flex justify-content-between align-items-center my-2 mx-2 image-container-content"
              >
                {key !== "main" && (
                  <SimpleText
                    prefix="image-file"
                    key={i}
                    dataIn={{
                      name: key,
                      data: displayData(key, value[key]),
                    }}
                    required={false}
                    disabled={true}
                    trash={false}
                    valid={{[key]: true}}
                    onHandleGlobals={(cs, val) => {
                      handleGlobals(cs, val, idx);
                    }}
                  ></SimpleText>
                )}
                {key === "main" && (
                  <>
                    <SimpleLabel name="main"></SimpleLabel>
                    <label className="switch m-0 mr-2">
                      <input
                        id={`sliderMainImage${idx}`}
                        type="checkbox"
                        checked={value.main}
                        disabled={value.data.length === 0}
                        onChange={(e) => {
                          onHandleMain(e.target.checked, idx);
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </>
                )}
              </div>
            );
        })}
        <div
          className="d-flex justify-content-center"
          style={{height: "200px"}}
        >
          {value.data ? (
            <img src={value.data} alt="" style={{height: "100%"}} />
          ) : null}
        </div>
      </div>
    )
  );
}

export default ImageUnit;
