import {useIntl} from "react-intl";
import Opts from "./priceDatesTable/option/Options.jsx";
import StandardInfo from "./common/StandardInfo.jsx";

function Options({announce}) {
  const {locale} = useIntl();
  return (
    <div className="ann-section-content">
      {((announce.included && announce.included[locale]) ||
        (announce.notIncluded && announce.notIncluded[locale])) && (
        <>
          {announce.included && announce.included[locale] && (
            <div>
              <StandardInfo
                field={announce.included}
                locale={locale}
                id="src.components.announcePage.announceDetailTab.labels.included"
              ></StandardInfo>
            </div>
          )}
          {announce.notIncluded && announce.notIncluded[locale] && (
            <div>
              <StandardInfo
                field={announce.notIncluded}
                locale={locale}
                id="src.components.announcePage.announceDetailTab.labels.notIncluded"
              ></StandardInfo>
            </div>
          )}
        </>
      )}
      {announce.options.length > 0 && (
        <>
          <h3>Options</h3>
          <Opts
            form={false}
            dataIn={{announce, dates: [], dateParticipants: {}}}
            recap={0}
            locks={false}
          ></Opts>
        </>
      )}
    </div>
  );
}

export default Options;
