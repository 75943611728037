import {useState, useRef} from "react";
import {FormattedMessage} from "react-intl";
import SinglePopper from "./SinglePopper";

function LevelInfo({
  label,
  l0,
  l1_title,
  l1_desc,
  range,
  rating,
  rt = -250,
  mt,
}) {
  const ref = useRef();
  const [state, setState] = useState(false);
  function handleToggle(e, cs) {
    if (e.target.id === "btn_info" && cs === "clickAway") return;
    setState(!state);
  }
  return (
    <div
      className="d-flex flex-wrap mb-3"
      style={{
        marginRight: label === null ? 0 : 50,
      }}
    >
      {label !== null ? (
        <h3 style={{marginRight: 10}}>
          <FormattedMessage id={label} />
        </h3>
      ) : null}
      <div
        className="d-flex flex-nowrap"
        style={{
          position: "relative",
        }}
      >
        {rating}
        <button
          ref={ref}
          id="btn_info"
          className="fa fa-info-circle fa-2x"
          style={{
            position: "absolute",
            top: label === null ? -30 : -2,
            right: rating === null ? rt : -30,
            color: "#7AA095",
            background: "transparent",
            border: "0",
            maxWidth: 30,
          }}
          onClick={handleToggle}
        ></button>
      </div>
      <SinglePopper
        anchor={ref.current}
        l0={l0}
        l1_title={l1_title}
        l1_desc={l1_desc}
        range={range}
        open={state}
        onClose={(e) => {
          handleToggle(e, "clickAway");
        }}
        mt={mt}
      ></SinglePopper>
    </div>
  );
}

export default LevelInfo;
