import {useState, useEffect} from "react";
import {FormattedMessage} from "react-intl";
import {alert} from "../../validation.js";

function DaysNights({reset, data, onHandleWarning, onHandleGlobals}) {
  const [values, setValues] = useState(data); //type, nbDays, nbNights, dates
  const [color, setColor] = useState({});
  useEffect(() => {
    setValues(data);
    setColor({
      nbDays:
        values.type !== "Flex_Flex"
          ? alert("nbDays", null, data.nbDays, true).props.obj[1]
          : true,
      nbNights:
        values.type !== "Flex_Flex"
          ? alert("nbNights", null, data.nbNights, true).props.obj[1]
          : true,
    });
  }, [reset]);
  function handleChange(e) {
    setValues({...values, [e.target.id]: e.target.value.toString()});
    let result = null;
    if (values.type !== "Flex_Flex") {
      result = alert(e.target.id, null, e.target.value, true);
      setColor({...color, [e.target.id]: result.props.obj[1]});
      onHandleWarning([e.target.id, result]);
    }
    onHandleGlobals(
      "valid",
      values.type !== "Flex_Flex" ? result.props.obj : [e.target.id, true]
    );
    onHandleGlobals("value", [e.target.id, e.target.value.toString()]);
  }
  return (
    <div className="days-nights">
      <label
        className="member-label mr-2"
        style={{
          color:
            (color.nbDays && color.nbNights) || values.type === "Flex_Flex"
              ? "green"
              : "red",
        }}
      >
        <FormattedMessage id="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.duration" />
      </label>
      <div className="member-control-container">
        <input
          id="nbDays"
          type="text"
          style={{
            borderColor:
              color.nbDays || values.type === "Flex_Flex" ? "green" : "red",
            cursor: values.type === "Flex_Flex" ? "not-allowed" : "default",
          }}
          className="member-control"
          onChange={handleChange}
          value={values.nbDays}
          disabled={values.type.includes("Fixed") ? false : true}
        ></input>
      </div>
      <label className="aft-label days-nights ml-2 mt-1">
        <FormattedMessage id="global.days" />
      </label>
      <div className="member-control-container">
        {values.type.includes("Fixed") ? (
          <input
            id="nbNights"
            type="text"
            style={{
              borderColor: color.nbNights ? "green" : "red",
            }}
            className="member-control"
            onChange={handleChange}
            value={values.nbNights}
          ></input>
        ) : (
          <select
            id="nbNights"
            className="member-control"
            style={{
              borderColor: "green",
            }}
            onChange={handleChange}
            value={values.nbNights}
          >
            <option value="0">0</option>
            <option value="n">n</option>
            <option value="n-1">n-1</option>
            <option value="n+1">n+1</option>
          </select>
        )}
      </div>
      <label className="aft-label days-nights ml-2 mt-1">
        <FormattedMessage id="global.nights" />
      </label>
    </div>
  );
}

export default DaysNights;
