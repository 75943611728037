import {useState, useEffect, useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import {useCookies} from "react-cookie";
import ImagesContext from "./context/ImagesContext.js";
import NavBarDropDown from "./NavBarDropDown";
import LanguageSwitch from "../../intl/LanguageSwitch";
import LogIn from "../logIn&Out/LogIn";
import {decodeJWT} from "../../../services/httpUsers.js";
import {SwalOkCancel} from "./toastSwal/SwalOkCancel.jsx";
import "./navbar.css";
import NavBarUser from "./NavBarUser.jsx";

function NavBar({announces, dirty, masterBadge, spinner: spin, onHandleDirty}) {
  const navigate = useNavigate();
  const {locale, formatMessage} = useIntl();
  const [cookies, setCookie] = useCookies(["user"]);
  const currentUser = cookies.user ? decodeJWT(cookies.user) : null;
  const contextImages = useContext(ImagesContext);
  const [toggle, setToggle] = useState(false);
  const [barIconVisible, setIconVisible] = useState(
    window.innerWidth >= 1000 ? false : true
  );
  const [spinner, setSpinner] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1000) setIconVisible(false);
      else setIconVisible(true);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setSpinner(spin);
  }, [spin]);
  async function handleDirty(e, path) {
    let result = "ok";
    if (dirty) {
      e.preventDefault();
      result = await SwalOkCancel(formatMessage, "global.dirty");
    }
    if (result === "ok") {
      navigate(path);
      onHandleDirty(false);
    }
  }
  return (
    <header>
      <nav className="navbar navbar-default navbar-fixed-top lightHeader">
        <ul className="navbar-nav nav-menu">
          <Link
            className="nav-brand"
            to={dirty ? "" : `/${locale}`}
            onClick={async (e) => {
              await handleDirty(e, `/${locale}`);
            }}
            aria-label="brand"
          />
          {(toggle && barIconVisible) || !barIconVisible ? (
            <div className="menu-collapsable">
              <span
                onClick={() => {
                  setToggle(!toggle);
                }}
                className="close-button"
              >
                x
              </span>
              <li className="menu-item dropdown singleDrop">
                <Link
                  id="navbar-home"
                  to={dirty ? "" : `/${locale}`}
                  onClick={async (e) => {
                    await handleDirty(e, `/${locale}`);
                  }}
                >
                  <FormattedMessage id="src.components.allPages.Menu.navbar.home" />
                </Link>
              </li>
              <li className="menu-item dropdown singleDrop ">
                <Link
                  to={dirty ? "" : `/${locale}/about`}
                  onClick={async (e) => {
                    await handleDirty(e, `/${locale}/about`);
                  }}
                >
                  <FormattedMessage id="src.components.allPages.Menu.navbar.aboutUs" />
                </Link>
              </li>
              <li className="menu-item dropdown megaDropMenu">
                <NavBarDropDown
                  id="destinations"
                  announces={announces}
                  intlId={[
                    "src.components.allPages.Menu.navbar.destinations",
                    ".title",
                    ".continents",
                    ".continentName",
                    ".countries",
                  ]} //ul slicing layout
                  ul={[
                    [0, 1],
                    [1, 3],
                    [3, 6],
                  ]}
                  dirty={dirty}
                ></NavBarDropDown>
              </li>
              <li className="menu-item dropdown megaDropMenu">
                <NavBarDropDown
                  id="activities"
                  announces={announces}
                  intlId={[
                    "src.components.allPages.Menu.navbar.activities",
                    ".title",
                    ".types",
                    ".title",
                    ".subactivities",
                  ]} //ul slicing layout
                  ul={[
                    [0, 2],
                    [2, 4],
                    [4, 6],
                  ]}
                  dirty={dirty}
                ></NavBarDropDown>
              </li>
            </div>
          ) : null}
          <div className="menu-always-visible">
            <li id="loginHorseAround" className="menu-item login">
              <LogIn></LogIn>
            </li>
            <li className="menu-item language">
              <LanguageSwitch contextImages={contextImages} />
            </li>
            <li className="menu-item user">
              {cookies.user && (
                <NavBarUser
                  user={currentUser}
                  masterBadge={masterBadge}
                  spinner={spinner}
                ></NavBarUser>
              )}
            </li>
            {barIconVisible && (
              <li
                id="header-nav-toggle"
                className="nav-toggle menu-item"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <a href="#">
                  <i className="fa fa-bars fa-2x" aria-label="main menu"></i>
                </a>
              </li>
            )}
          </div>
        </ul>
      </nav>
    </header>
  );
}

export default NavBar;
