import {useState, useEffect, useContext} from "react";
import {useLocation, Link, useNavigate} from "react-router-dom";
import {Tooltip} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import _ from "lodash";
import {parseISO} from "date-fns";
import {useCookies} from "react-cookie";
import FontAwesome from "react-fontawesome";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import annKeys from "./announceKeys.json";
import empty from "./default.json";
import defaultValid from "./defaultValid.json";
import {validate, alert} from "../../validation.js";
import LanguageBlock from "./LanguageBlock.jsx";
import DropDownBlock from "./DropDownBlock";
import SimpleLabel from "../../common/SimpleLabel.jsx";
import SimpleText from "../../common/SimpleText.jsx";
import PopperInfo from "./PopperInfo.jsx";
import ImagesContext from "./../../../common/context/ImagesContext";
import DatesBlock from "./DatesBlock.jsx";
import GuideAdultChildRider from "./GuideAdultChildRider.jsx";
import Participants from "./Participants.jsx";
import Price from "./Price.jsx";
import LevelRating from "./LevelRating";
import {range} from "../../../utils/utilityFunctions.js";
import LanguageRating from "./LanguageRating";
import france from "../../../../intl/flags/france.webp";
import uk from "../../../../intl/flags/uk.webp";
import germany from "../../../../intl/flags/germany.webp";
import spain from "../../../../intl/flags/spain.webp";
import DailyProgram from "./DailyProgram.jsx";
import Options from "./Options.jsx";
import Images from "./Images.jsx";
import {
  postAnnounce,
  patchAnnounce,
  deleteAnnounce,
  deleteAnnounceImages,
} from "../../../../../services/httpAnnounces.js";
import {
  getAnnounceImages,
  patchAnnounceImages,
  postAnnounceImages,
} from "../../../../../services/httpImages.js";
import {errorHandlingToast} from "../../../../../services/utilsFunctions.js";
import {
  toastSuccess,
  toastError,
  toastWarning,
  toastInfo,
} from "../../../common/toastSwal/ToastMessages";
import {decodeJWT} from "../../../../../services/httpUsers.js";
import {isEven} from "../../../utils/utilityFunctions.js";
import {SwalOkCancel} from "../../../common/toastSwal/SwalOkCancel.jsx";
import Position from "./Position.jsx";
import {getMarkers} from "../../../../../services/httpGoogleServices.js";
import {checkAnnounceDelete} from "../../../../../services/httpAnnounces.js";
import {getFormattedDate} from "../../../utils/utilityFunctions.js";
import {getRefreshTime} from "../../../../../services/utilsFunctions.js";

export function getEmptyImage(image) {
  return {
    image,
    name: "",
    main: false,
    type: "",
    size: 0,
    lastModified: 0,
    data: "",
  };
}
export function checkDateInFuture(date) {
  let start = date.period.dateStart,
    end = date.period.dateEnd;
  if (_.isString(start)) start = parseISO(start);
  if (_.isString(end)) end = parseISO(end);
  if (
    start.setHours(0, 0, 0, 0) >= Date.now() ||
    end.setHours(0, 0, 0, 0) >= Date.now()
  )
    return true;
  return false;
}
async function deleteConditionsSatisfiedRR(id, token, formatMessage) {
  //RR >>> Related Records
  let bl = null;
  const abortController = new AbortController(),
    signal = abortController.signal,
    models = ["bookings"],
    n = models.length;
  for (let i = 0; i < n; i++) {
    bl = await checkAnnounceDelete(id, models[i], token, signal);
    if (!bl.data) {
      const msg = `${formatMessage({
        id: `src.components.memberPage.tabs.annonces.details.AddAnnounceForm.warning3`,
      })}'${models[i]}' !`;
      return [bl.data, msg];
    } else if (i === n - 1) return [true];
  }
}
export async function deleteConditionsSatisfied(
  id,
  local,
  data,
  token,
  formatMessage
) {
  let result = ["satisfied", true];
  const status = local ? data.status.data.saved : data.status,
    dates = local ? data.dates.data.saved : data.dates;
  if (status === "publique" && dates.length > 0) {
    let date = null;
    for (date of dates) {
      if (date?.bookingsByDay?.length > 0) {
        result = ["bookingsByDay", false]; //related records in bookinsByDay
        break;
      }
      if (checkDateInFuture(date)) {
        result = ["datesInFuture", false];
        break;
      }
    }
  }
  if (!result[1]) {
    const msg = formatMessage({
      id: `src.components.memberPage.tabs.annonces.details.AddAnnounceForm.${result[0]}Failure`,
    });
    return [result[1], msg];
  }
  return await deleteConditionsSatisfiedRR(id, token, formatMessage);
}
export async function handleDelete(
  id,
  lang,
  token,
  onHandleSaveDelete,
  formatMessage,
  local,
  navigate
) {
  const result = await SwalOkCancel(
    formatMessage,
    "src.components.memberPage.tabs.annonces.MyAnnonces.delete"
  );
  if (result === "cancel") return;

  let bl = [null, null];
  const abortController = new AbortController();
  let res = await deleteAnnounceImages(id, token, abortController.signal);
  bl[0] = !(await errorHandlingToast(res, lang, false));
  if (bl[0])
    onHandleSaveDelete(
      "images", //update images data state in App.js with saved data
      "delete",
      id
    );

  res = await deleteAnnounce(id, token, abortController.signal);
  bl[1] = !(await errorHandlingToast(res, lang, false));
  if (bl[1]) {
    onHandleSaveDelete(
      "delete", //delete announces data properties in App.js (from useQuery)
      id
    );
    if (local)
      setTimeout(() => {
        navigate(-1);
      }, 3500);
  }
  successFailure("DELETE", bl, formatMessage);
}
export function successFailure(
  cs,
  bl,
  formatMessage,
  source = "AddAnnounceForm"
) {
  let i = 1, //PATCH case
    j = 1; //PATCH, POST
  switch (cs) {
    case "POST":
      i = 2;
      break;
    case "DELETE":
      i = 3;
      j = 2;
  }
  if (bl.indexOf(false) === -1)
    toastSuccess(
      formatMessage({
        id: `src.components.memberPage.tabs.annonces.details.${source}.success${i}`,
      })
    );
  else
    toastError(
      formatMessage({
        id: `src.components.memberPage.tabs.annonces.details.AddAnnounceForm.failure${j}`,
      })
    );
}
let globals = {},
  dataIn = {},
  pro = {},
  id_ann = 0,
  imagesInDb = -1,
  dirty = false,
  selected = null,
  userId = null;
function AnnounceForm({onHandleSaveDelete, onHandleDirty}) {
  const navigate = useNavigate();
  useEffect(() => {
    function getURL() {
      let url = null;
      if (!localStorage.getItem("eye")) {
        url = `/${locale}/member?MyAnnounces&${userId}`;
        Object.keys(selected).map((id) => {
          if (selected[id] === 1) url = `${url}&${id}`;
        });
      } else {
        url = `/${locale}/member/announces/edit/${localStorage.getItem("eye")}`;
        localStorage.removeItem("eye");
      }
      return url;
    }
    async function resetData(e) {
      //if (dirty === false && location.state === null) navigate(getURL()); //new announce created, saved and action on back button
      if (dirty === false) navigate(getURL()); //new announce created, saved or unsaved and action on back button
      if (dirty) {
        const result = await SwalOkCancel(formatMessage, "global.dirty");
        if (result === "cancel") {
          navigate(null);
          return;
        } else {
          dirty = false;
          navigate(getURL());
          onHandleDirty(false);
        }
      }
      if (e.target.location.pathname.split("/").indexOf(id_ann) !== -1) return; //coming back to AnnounceForm after viewing the announce in AnnouncePage display format
      id_ann = 0;
      dataIn = {};
      imagesInDb = -1;
    }
    window.addEventListener("popstate", resetData); //resets id_ann and dataIn on browser back button action
    return () => {
      window.removeEventListener("popstate", resetData);
    };
  }, []);
  const contextImages = useContext(ImagesContext);
  const {locale, formatMessage} = useIntl();
  const location = useLocation();
  const [cookies, setCookie] = useCookies(["user"]);
  const currentUser = cookies.user ? decodeJWT(cookies.user) : null;
  // let cond = false;
  // if (id_ann === 0) cond = true;
  // if (!cond && location.state) cond = location.state.announce._id !== -1;
  // if (cond) {
  if (location.state) {
    try {
      dataIn = location.state.announce; //announce edit (announce is provided, may contain missing fields) and announce creation case ({_id:-1} is provided)
      pro = location.state.pro;
      selected = location.state.selected;
      userId = location.state.userId;
      id_ann = dataIn._id;
    } catch (error) {
      setTimeout(() => {
        navigate(`/${locale}/member`); //location.state=null
      }, 500);
    }
  }
  dataIn.dates?.map((date, idx) => {
    dataIn.dates[idx].period.dateStart = _.isString(date.period.dateStart)
      ? parseISO(date.period.dateStart)
      : date.period.dateStart;
    dataIn.dates[idx].period.dateEnd = _.isString(date.period.dateEnd)
      ? parseISO(date.period.dateEnd)
      : date.period.dateEnd;
  });
  const [reset, setReset] = useState(id_ann === -1 ? 0 : 1); //0 >>> default data (new announce), 1 >>> saved data (edit case)
  function initGlobals(cs = "all") {
    const keys = Object.keys(empty);
    switch (cs) {
      case "data":
        keys.map((key) => {
          if (key !== "images") globals[key] = {init: null};
        });
        break;
      case "images":
        globals.images = ["init"];
        break;
      default: //full initialisation
        keys.map((key) => {
          globals[key] = {init: null};
        });
        globals.images = ["init"];
    }
  }
  const [spin1, setSpinner1] = useState(false);
  const [spin2, setSpinner2] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [values, setValues] = useState({});
  const [valid, setValid] = useState({
    current: {},
    default: {},
    saved: {},
  });
  const [trashConditions, setTrashConditions] = useState({
    cond: [false, ""],
    color: "#ccc",
  });
  async function setDeleteConditions(data) {
    const rslt = await deleteConditionsSatisfied(
      id_ann,
      true, //local=true
      data,
      cookies.user,
      formatMessage
    );
    setTrashConditions({cond: rslt, color: rslt[0] ? "#7AA095" : "#ccc"});
  }
  useEffect(() => {
    if (Object.keys(dataIn).length === 0) return;
    setSpinner1(true);
    initGlobals("data");
    const valide = _.cloneDeep(defaultValid);
    let result = null;
    function prepareData() {
      result = _.cloneDeep(empty);
      delete result.images;
      result.log = {
        name: "log",
        data: {
          default: {
            status: [["brouillon", null]],
            archived: [[false, null]],
          },
          saved: {status: [], archived: []},
        },
      };
      Object.keys(annKeys).map((key) => {
        switch (key) {
          case "_id":
            result[key].data.saved = id_ann; //id_ann=-1 for new announce creation
            break;
          case "id_user":
            result[key].data.saved =
              id_ann === -1 ? currentUser._id : dataIn[key]._id;
            break;
          default:
            result[key].data.saved =
              id_ann === -1
                ? empty[key].data.default
                : typeof dataIn[key] !== "undefined"
                ? dataIn[key]
                : {};
            switch (annKeys[key]) {
              case true: //en, fr
                result[key].data.saved.fr =
                  id_ann === -1
                    ? empty[key].data.default.fr
                    : dataIn[key] && dataIn[key].fr
                    ? dataIn[key].fr
                    : "";
                valide[key].fr = !valide[key].fr
                  ? validate(key, result[key].data.saved.fr)[0]
                  : true;
                result[key].data.saved.en =
                  id_ann === -1
                    ? empty[key].data.default.en
                    : dataIn[key] && dataIn[key].en
                    ? dataIn[key].en
                    : "";
                valide[key].en = !valide[key].en
                  ? validate(key, result[key].data.saved.en)[0]
                  : true;
                break;
              case false:
                valide[key] = !valide[key]
                  ? validate(key, result[key].data.saved)[0]
                  : true;
                if (key === "position")
                  valide[key] = {
                    lat: validate("lat", result[key].data.saved.lat)[0],
                    lng: validate("lng", result[key].data.saved.lng)[0],
                  };
                if (
                  (key === "nbDays" || key === "nbNights") &&
                  dataIn.datesType === "Flex_Flex"
                )
                  valide[key] = true; //nbdays='n'
            }
        }
      });
      setValid({
        current: _.cloneDeep(valide),
        default: _.cloneDeep(defaultValid),
        saved: _.cloneDeep(valide),
      });
      setValues(result);
      setSpinner1(false);
    }
    prepareData();
    if (id_ann !== -1) setDeleteConditions(result); //clean-up code in deleteConditionsSatisfiedRR()
  }, [dataIn]);
  const [images, setImages] = useState({});
  useEffect(() => {
    if (id_ann === 0) return;
    setSpinner1(true);
    initGlobals("images");
    async function loadImages(signal) {
      const result = _.cloneDeep(empty.images);
      if (id_ann !== -1) {
        //edit case
        try {
          let data = [];
          if (
            contextImages.state.status[id_ann] &&
            contextImages.state.status[id_ann].main === 1 &&
            contextImages.state.status[id_ann].others ===
              contextImages.state.status[id_ann].total - 1
          ) {
            data = _.cloneDeep(contextImages.state[id_ann]);
            imagesInDb = data.length;
          } else {
            const res = await getAnnounceImages(id_ann, signal);
            if (!(await errorHandlingToast(res, locale, false))) {
              imagesInDb = res.data.len;
              data = res.data.images;
            }
          }
          result.data.saved = data;
        } catch (error) {}
      }
      setImages(result);
      setSpinner1(false);
    }
    const abortController = new AbortController();
    loadImages(abortController.signal);
    return () => {
      abortController.abort(); //clean-up code after component has unmounted
    };
  }, []);
  const [autofill, setAutofill] = useState({});
  function handleClearAllUndo(cs) {
    initGlobals(); //resets globals (contains all user's modified data) to empty properties
    let rst = _.clone(reset);
    if (cs === 0) {
      rst += isEven(rst) ? 2 : 1; //even number, clear all >>> default values
      // update globals >>> delta between default and saved values
      Object.keys(values).map((key) => {
        if (!_.isEqual(values[key].data.saved, values[key].data.default))
          globals[key] = values[key].data.default;
      });
    } else rst += isEven(rst) ? 1 : 2; //odd number, undo >>> saved values

    const valide = {...valid}; //resets valid after clear or restore all
    valide.current = _.cloneDeep(isEven(rst) ? valide.default : valide.saved);
    setValid(valide);
    setReset(rst);
    setDirty();
  }
  function setDirty() {
    dirty = announceUnsavedChanges(false);
    if (!dirty) {
      dirty = imagesUnsavedChanges(false);
    }
    if (dirty) navigate(null);
    onHandleDirty(dirty);
  }
  function handleGlobals(cs, val) {
    const len = val.length;
    if (cs === "value") {
      try {
        delete globals[val[0]].init;
      } catch (error) {}
      switch (len) {
        case 2:
          globals[val[0]] = val[1];
          break;
        case 3:
          globals[val[0]][val[1]] = val[2]; // en-fr
      }
      setDirty();
      // console.log("globals", globals);
    }
    if (cs === "valid") {
      const valide = {...valid};
      switch (len) {
        case 2:
          valide.current[val[0]] = val[1];
          break;
        case 3:
          valide.current[val[0]][val[1]] = val[2]; // en-fr
      }
      setValid(valide);
      setFormValid(JSON.stringify(valide.current).indexOf(false) === -1);
    }
  }
  function prepareAnnounceBody(cs, modified) {
    const keys = Object.keys(annKeys);
    keys.splice(0, cs === "POST" ? 1 : 2); //removes _id, id_user (PATCH only)
    let body = {},
      flg = null,
      cond = null;
    switch (cs) {
      case "POST": //body = empty + globals (user modified inputs)
        const data = _.cloneDeep(empty);
        delete data._id;
        delete data.images;
        keys.map((key) => {
          switch (key) {
            case "id_user":
              body[key] = currentUser._id;
              break;
            default:
              flg = Object.keys(modified[key]);
              if (flg.length === 1 && flg.indexOf("init") === 0) {
                body[key] = data[key].data.default;
                break;
              }
              flg = [];
              switch (annKeys[key]) {
                case true: //en, fr
                  body[key] = {};
                  if (modified[key] && modified[key].en)
                    body[key].en = modified[key].en;
                  else flg.push("en"); //nochange en
                  if (modified[key] && modified[key].fr)
                    body[key].fr = modified[key].fr;
                  else flg.push("fr"); //nochange fr
                  if (flg.length === 1)
                    body[key][flg[0]] = data[key].data.default[flg[0]];
                  break;
                case false:
                  if (modified[key]) body[key] = modified[key];
              }
          }
        });
        break;
      case "PATCH": //compares saved data (dataIn) vs modified (changes made by user)
        body = {};
        keys.map((key) => {
          flg = Object.keys(modified[key]);
          cond = flg.length === 1 && flg.indexOf("init") === 0;
          switch (key) {
            case "options":
            case "days":
              if (cond) break;
              if (!_.isEqual(modified[key], dataIn[key]))
                body[key] = modified[key];
              else if (body[key]) delete body[key]; //no change but modified[key] may have been created before (changes and come back to saved values)
              break;
            default:
              if (cond) break;
              flg = [];
              switch (annKeys[key]) {
                case true: //en, fr
                  body[key] = {};
                  if (
                    modified[key] &&
                    modified[key].en &&
                    modified[key].en !== dataIn[key].en
                  )
                    body[key].en = modified[key].en;
                  else flg.push("en"); //nochange en
                  if (
                    modified[key] &&
                    modified[key].fr &&
                    modified[key].fr !== dataIn[key].fr
                  )
                    body[key].fr = modified[key].fr;
                  else flg.push("fr"); //nochange fr
                  if (flg.length === 1) body[key][flg[0]] = dataIn[key][flg[0]];
                  if (flg.length === 2 && body[key]) delete body[key]; //no change in both en fr, but modified[key] may have been created before (changes and come back to saved values)
                  break;
                case false:
                  if (modified[key] != dataIn[key]) body[key] = modified[key];
                  else if (body[key]) delete body[key]; //no change but modified[key] may have been created before (changes and come back to saved values)
              }
          }
        });
    }
    console.log("body announce", body);
    return body;
  }
  function checkForImagesChange(imagesSet) {
    if (imagesSet && imagesSet[0] === "init") return false;
    const original = _.cloneDeep(images.data.saved), //original pictures before save
      keys = Object.keys(getEmptyImage(0)),
      n_globals = imagesSet.length,
      n_saved = original.length;
    keys.splice(0, 1);
    if (n_globals === 0) return n_saved === 0 ? false : true; //no change in announce pictures or removal
    if (n_globals > 0 && n_saved === 0) return true; //actual change in announce pictures, no original pictures
    //actual change in announce pictures, existing original pictures
    if (n_saved !== n_globals) return true; //n_globals > 0
    else {
      let bl = [];
      for (let i = 0; i < n_globals; i++) {
        if (imagesSet[i].main === original[i].main) {
          bl[i] = false;
          for (let j = 0; j < n_saved; j++) {
            if (imagesSet[i].data === original[j].data) {
              bl[i] = true;
              break;
            }
          }
        } else return true;
      }
      if (bl.indexOf(false) !== -1) return true;
    }
    return false;
  }
  function prepareImagesBodyData(val) {
    const body = {
      id_announce: id_ann,
      images: val,
    };
    console.log("body images", body);
    return body;
  }
  async function handleSave() {
    setSpinner2(true);
    const bl = [null, null],
      abortController = new AbortController();
    //######## announce processing
    let modified = _.cloneDeep(globals), //current user's modified values
      res = null,
      actualChange = -1;
    delete modified._id;
    delete modified.id_user;
    delete modified.images;
    let cs = id_ann === -1 ? "POST" : "PATCH";
    const body = prepareAnnounceBody(cs, modified);
    if (Object.keys(body).length > 0) {
      actualChange += 1;
      switch (cs) {
        case "POST":
          res = await postAnnounce(
            {...body},
            cookies.user,
            abortController.signal
          );
          id_ann = res.data.data._id; //newly created announce _id
          body.log = res.data.data.log; //update body with log data from the database
          dataIn = {..._.cloneDeep(body), _id: id_ann}; //multiple save operations on a newly created announce
          break;
        case "PATCH":
          res = await patchAnnounce(
            id_ann,
            body,
            cookies.user,
            abortController.signal
          );
          if (res.data.log) body.log = res.data.log; //update body with log data from the database in case of status or archived change
          if (res.data.flag === 2) body.status = "brouillon"; //update body with unrequested status change (announce restoration with 'publique' status >>> 'brouillon')
      }
      bl[0] = !(await errorHandlingToast(res, locale, false));
      if (bl[0]) {
        const data = _.cloneDeep(values); //update values state properties with saved data
        if (cs === "POST") {
          data._id.data.saved = id_ann;
          selected[id_ann] = 1;
        }
        Object.keys(body).map((key) => {
          data[key].data.saved = _.clone(body[key]);
          if (cs === "PATCH") dataIn[key] = _.clone(body[key]);
        });
        setValues(data);
        onHandleSaveDelete(
          "save", //update/create announces data properties in App.js (from useQuery) with saved data
          id_ann,
          cs === "POST"
            ? {
                ...body,
                id_user: {
                  _id: body.id_user,
                  email: currentUser.email,
                  registration_date: currentUser.registration_date,
                },
              }
            : body
        );
        setDeleteConditions(data); //update delete conditions following save operation
        initGlobals("data");
      }
    }
    //###### images processing
    const imagesSet = _.cloneDeep(globals.images);
    if (checkForImagesChange(imagesSet)) {
      actualChange += 1;
      if (imagesSet.length === 0) {
        res = await deleteAnnounceImages(
          id_ann,
          cookies.user,
          abortController.signal
        );
        bl[1] = !(await errorHandlingToast(res, locale, false));
        if (bl[1]) {
          setImages({name: "images", data: {default: [], saved: []}});
          onHandleSaveDelete(
            "images", //update images data state in App.js with saved data
            "delete",
            id_ann
          );
        }
      } else {
        const body = prepareImagesBodyData(imagesSet);
        res =
          imagesInDb > 0
            ? await patchAnnounceImages(
                id_ann,
                body,
                cookies.user,
                abortController.signal
              )
            : await postAnnounceImages(
                id_ann,
                body,
                cookies.user,
                abortController.signal
              );
        bl[1] = !(await errorHandlingToast(res, locale, false));
        if (bl[1]) {
          const imgs = _.cloneDeep(images); //update images state properties with saved data
          if (Object.keys(globals.images).length > 0) {
            const data = _.cloneDeep(globals.images);
            imgs.data.saved = [...data];
            imagesInDb = data.length;
          }
          setImages(imgs);
          onHandleSaveDelete(
            "images", //update/create images data properties in App.js with saved data
            "save",
            body
          );
          initGlobals("images");
        }
      }
    }
    if (actualChange >= 0) {
      successFailure(cs, bl, formatMessage);
      handleClearAllUndo(1); //set reset to odd number to display saved data
    }
    dirty = false;
    onHandleDirty(false);
    setSpinner2(false);
    return bl.indexOf(false) === -1 ? true : false;
  }
  function imagesUnsavedChanges(msg = true) {
    const result = checkForImagesChange(globals.images);
    if (result && msg)
      toastWarning(
        formatMessage({
          id: "src.components.memberPage.tabs.annonces.MyAnnonces.toast7",
        })
      );
    return result;
  }
  function announceUnsavedChanges(msg = true) {
    let cs = -1;
    if (id_ann === -1) cs += 1; //newly created announce that has never been saved
    if (cs === -1) {
      let modified = _.cloneDeep(globals); //current user's modified values
      let body = prepareAnnounceBody("PATCH", modified);
      if (Object.keys(body).length > 0) cs += 1; //unsaved pending changes
    }
    if (cs >= 0 && msg) {
      toastWarning(
        formatMessage({
          id: "src.components.memberPage.tabs.annonces.MyAnnonces.toast7",
        })
      ); //save announce before any further action such as publishing or reverting to draft, archiving or restoring ...
    }
    return cs >= 0;
  }
  async function handleStatusArchived(type) {
    //status change : type=-1 >>> 'publique' to 'brouillon', type=1 >>> 'brouillon' to 'publique'
    //archived change : type=-2 >>> 'archived=true' to 'archived=false', type=2 >>> 'archived=false' to 'archived=true'
    if (type === 1 && !formValid) {
      toastWarning(
        formatMessage({
          id: "src.components.memberPage.tabs.annonces.MyAnnonces.toast8",
        })
      ); //mandatory fields missing
      return;
    }
    if (type === 1 && currentUser.status !== "ACTIVE") {
      toastWarning(
        formatMessage({
          id: "src.components.memberPage.tabs.annonces.MyAnnonces.toast6",
        })
      ); //user profile must be approved before publishing
      return;
    }
    if (announceUnsavedChanges()) return; //save announce before publishing or reverting to draft, archiving or restoring
    if (imagesUnsavedChanges()) return;
    if (
      type === 1 &&
      values.priceAdulte.data.saved +
        values.priceChild.data.saved +
        values.priceAccompagnateur.data.saved <=
        0
    ) {
      toastWarning(
        formatMessage({
          id: "src.components.memberPage.tabs.annonces.MyAnnonces.toast9",
        })
      ); //user profile must be approved before publishing
      return;
    }

    if (type == -1 || type === 2) {
      //status change 'publique' to 'brouillon', archived change false to true only possible if no dates in future
      for (let date of values.dates.data.saved) {
        if (checkDateInFuture(date)) {
          toastWarning(
            formatMessage({
              id: `src.components.memberPage.tabs.annonces.MyAnnonces.${
                type === -1 ? "status" : "archived"
              }Change${type > 0 ? "+" : ""}${type}Failure`,
            })
          );
          return;
        }
      }
    }
    //at this stage, all rejection conditions passed, ask user's confirmation
    if (
      (await SwalOkCancel(
        formatMessage,
        `src.components.memberPage.tabs.annonces.MyAnnonces.${
          Math.abs(type) === 1 ? "status" : "archived"
        }Change${type > 0 ? "+" : ""}${type}`
      )) === "cancel"
    )
      return;

    function getStatusArchived() {
      switch (type) {
        case -1:
        case 1:
          return type === 1 ? "publique" : "brouillon";
        case -2:
        case 2:
          return type === 2 ? true : false;
      }
    }
    globals[Math.abs(type) === 1 ? "status" : "archived"] = getStatusArchived(); //update globals since it is not changed by user's input
    const result = await handleSave(); //update status or archived change in database
    if (result && type === 1)
      toastInfo(
        `${formatMessage({
          id: "src.components.memberPage.tabs.annonces.MyAnnonces.toast10",
        })} ${getRefreshTime("App.js").staleTime / 60000} mns`
      );
    /* const vals = _.cloneDeep(values);
    vals[Math.abs(type) === 1 ? "status" : "archived"].data.saved =
      getStatusArchived(); //update saved values state properties
    setValues(vals); */
  }
  async function handlePositionUpdate() {
    setAutofill({});
    const dest = document.getElementById("AnnounceFormdestination").value,
      city = document.getElementById(
        "member-announce-control-city-input"
      ).value,
      postalCode = document.getElementById(
        "member-announce-control-postalCode-input"
      ).value;
    if (dest.length === 0 && city.length === 0 && postalCode.length === 0)
      return;
    const str_markers = {dummy: []};
    str_markers.locations = [
      encodeURIComponent(`${dest} ${city} ${postalCode}`),
    ];
    const markers = (await getMarkers(str_markers)).data;
    const lat = markers[0].position.lat,
      lng = markers[0].position.lng;
    setAutofill({position: {lat, lng}});
  }
  function prepareData(data) {
    const obj = [];
    Object.keys(data).map((key) => {
      data[key].map((item) => {
        obj.push([
          `${key} > ${item[0]} - ${getFormattedDate(item[1])}`,
          new Date(item[1]).getTime(),
        ]);
      });
    });
    obj.sort(function (a, b) {
      return b[1] - a[1]; //sort by date desc
    });
    return obj;
  }
  const prefix = "member-announce"; //prefix in SimpleLabel, SimpleText, LanguageBlock
  return (
    Object.keys(values).length > 0 &&
    Object.keys(valid.current).length > 0 && (
      <div className="member-form-container announce">
        <div className="member-form-toolbar-wrapper">
          <div className="member-form-toolbar-container announce">
            <div className="member-form-toolbar announce lh1">
              <label>
                <FormattedMessage id="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.status" />
              </label>
              <div className="member-control-container">
                <input
                  key={reset}
                  type="text"
                  className="member-control"
                  readOnly={true}
                  value={
                    values.status.data[isEven(reset) ? "default" : "saved"]
                  }
                  style={{width: "85px", cursor: "not-allowed"}}
                ></input>
              </div>
              <label>
                <FormattedMessage id="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.archived" />
              </label>
              <div className="member-control-container">
                <input
                  key={reset + 1}
                  type="checkbox"
                  className="member-control mt-2"
                  readOnly={true}
                  style={{cursor: "not-allowed"}}
                  checked={
                    values.archived.data[isEven(reset) ? "default" : "saved"]
                  }
                ></input>
                <PopperInfo
                  data={prepareData(
                    values.log.data[isEven(reset) ? "default" : "saved"]
                  )}
                  idTT="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.history"
                  icon="fa fa-history fa-2x mx-4 pl-4"
                ></PopperInfo>
              </div>
              {!formValid && (
                <span className="badge rounded-pill badge-notification bg-danger">
                  A
                </span>
              )}
              <Tooltip
                title={formatMessage({
                  id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.scrollTop",
                })}
                arrow
              >
                <VerticalAlignTopIcon
                  sx={{
                    fontSize: 35,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                ></VerticalAlignTopIcon>
              </Tooltip>
            </div>
            <div className="member-form-toolbar announce rh">
              <div className="member-form-toolbar announce rh1">
                <span>
                  <div className="d-inline media-heading">
                    {!spin1 && !spin2 ? null : (
                      <FontAwesome
                        className="fa fa-spinner fa-lg mr-4"
                        style={{
                          color: "#7AA095",
                          fontSize: "30px",
                        }}
                        name="spinner"
                        pulse
                      />
                    )}
                  </div>
                  <Tooltip
                    title={formatMessage({
                      id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.save",
                    })}
                    arrow
                  >
                    <i
                      className="fa fa-save fa-2x mr-4"
                      style={{
                        color: !values.archived.data.saved ? "#7AA095" : "#ccc",
                      }}
                      onClick={() => {
                        if (values.archived.data.saved) return; //no fields change in archived announce, therefore no need to save
                        if (
                          values.status.data.saved === "publique" &&
                          !formValid
                        ) {
                          toastError(
                            formatMessage({
                              id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.warning4",
                            })
                          );
                          return;
                        }
                        handleSave();
                      }}
                    ></i>
                  </Tooltip>
                  <Tooltip
                    title={formatMessage({
                      id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.view",
                    })}
                    arrow
                  >
                    <button
                      className="fa fa-eye fa-2x mr-3"
                      style={{
                        color: "#7AA095",
                        border: "0",
                      }}
                      onClick={(e) => {
                        if (
                          announceUnsavedChanges() ||
                          imagesUnsavedChanges()
                        ) {
                          e.preventDefault();
                          return;
                        }
                        navigate(`/${locale}/announce/details?id=${id_ann}`, {
                          state: {images: images.data.saved},
                        });
                        localStorage.setItem("eye", id_ann);
                      }}
                    ></button>
                  </Tooltip>
                  <Tooltip
                    title={formatMessage({
                      id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.clearAll",
                    })}
                    arrow
                  >
                    <i
                      className="fa fa-eraser fa-2x mr-3"
                      style={{
                        color: !values.archived.data.saved ? "#7AA095" : "#ccc",
                      }}
                      onClick={() => {
                        if (values.archived.data.saved) return; //no fields change in archived announce
                        handleClearAllUndo(0);
                      }}
                    ></i>
                  </Tooltip>
                  <Tooltip
                    title={formatMessage({
                      id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.restoreAll",
                    })}
                    arrow
                  >
                    <i
                      className="fa fa-undo fa-2x ml-2"
                      style={{
                        color:
                          !values.archived.data.saved && id_ann !== -1
                            ? "#7AA095"
                            : "#ccc",
                      }}
                      onClick={() => {
                        if (values.archived.data.saved || id_ann === -1) return; //no restore fields capbility in archived or newly created announce
                        handleClearAllUndo(1);
                      }}
                    ></i>
                  </Tooltip>
                </span>
              </div>
              <div className="member-form-toolbar announce rh2">
                <span>
                  <Tooltip
                    title={formatMessage({
                      id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.archive",
                    })}
                    arrow
                  >
                    <i
                      className="fa fa-archive fa-2x mr-3"
                      style={{
                        color: !values.archived.data[
                          isEven(reset) ? "default" : "saved"
                        ]
                          ? "#7AA095"
                          : "#ccc",
                      }}
                      onClick={() => {
                        if (
                          values.archived.data[
                            isEven(reset) ? "default" : "saved"
                          ]
                        )
                          return;
                        handleStatusArchived(2);
                      }}
                    ></i>
                  </Tooltip>
                  <Tooltip
                    title={formatMessage({
                      id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.restore",
                    })}
                    arrow
                  >
                    <i
                      className="fa fa-folder-open fa-2x mr-3"
                      style={{
                        color: values.archived.data[
                          isEven(reset) ? "default" : "saved"
                        ]
                          ? "#7AA095"
                          : "#ccc",
                      }}
                      onClick={() => {
                        if (
                          !values.archived.data[
                            isEven(reset) ? "default" : "saved"
                          ]
                        )
                          return;
                        handleStatusArchived(-2);
                      }}
                    ></i>
                  </Tooltip>
                  <Tooltip
                    title={formatMessage({
                      id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.delete",
                    })}
                    arrow
                  >
                    <i
                      className="fa fa-trash fa-2x"
                      style={{
                        color: trashConditions.color,
                      }}
                      onClick={async () => {
                        if (id_ann === -1) return; //no delete on unsaved announce
                        if (!trashConditions.cond[0]) {
                          toastError(trashConditions.cond[1]);
                          return;
                        }
                        setSpinner2(true);
                        await handleDelete(
                          id_ann,
                          locale,
                          cookies.user,
                          onHandleSaveDelete,
                          formatMessage,
                          true,
                          navigate
                        );
                        setSpinner2(false);
                      }}
                    ></i>
                  </Tooltip>
                </span>
              </div>
              <div className="member-form-toolbar announce rh3">
                <span>
                  <Tooltip
                    title={formatMessage({
                      id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.draft",
                    })}
                    arrow
                  >
                    <i
                      className="fa fa-download fa-2x mr-4"
                      style={{
                        color:
                          values.status.data[
                            isEven(reset) ? "default" : "saved"
                          ] !== "brouillon"
                            ? "#7AA095"
                            : "#ccc",
                      }}
                      onClick={() => {
                        if (
                          values.status.data[
                            isEven(reset) ? "default" : "saved"
                          ] === "brouillon"
                        )
                          return;
                        handleStatusArchived(-1);
                      }}
                    ></i>
                  </Tooltip>
                  <Tooltip
                    title={formatMessage({
                      id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.publish",
                    })}
                    arrow
                  >
                    <i
                      className="fa fa-upload fa-2x"
                      style={{
                        color:
                          values.status.data[
                            isEven(reset) ? "default" : "saved"
                          ] !== "publique" && currentUser.status !== "PENDING"
                            ? "#7AA095"
                            : "#ccc",
                      }}
                      onClick={() => {
                        if (currentUser.status === "PENDING") return;
                        if (
                          values.status.data[
                            isEven(reset) ? "default" : "saved"
                          ] === "publique"
                        )
                          return;
                        handleStatusArchived(1);
                      }}
                    ></i>
                  </Tooltip>
                </span>
              </div>
            </div>
            <div>
              <span
                className="close-button"
                onClick={() => {
                  navigate(-1);
                }}
              >
                &times;
              </span>
            </div>
          </div>
        </div>
        <div className="announce-form">
          <LanguageBlock
            type="text"
            lenMax={100}
            reset={reset}
            dataIn={values.title}
            required={true}
            valid={valid.current.title}
            onHandleGlobals={handleGlobals}
          ></LanguageBlock>
          <LanguageBlock
            type="textarea"
            reset={reset}
            dataIn={values.description}
            required={true}
            valid={valid.current.description}
            onHandleGlobals={handleGlobals}
          ></LanguageBlock>
          <SimpleLabel
            name="destination"
            color={valid.current.destination ? "green" : "red"}
            required
          ></SimpleLabel>
          <div className="announce-items-group">
            <DropDownBlock
              cs="destination"
              reset={reset}
              dataIn={values.destination}
              valid={valid.current.destination}
              onHandleGlobals={handleGlobals}
            ></DropDownBlock>
            <div className="simple-text-block">
              <SimpleText
                prefix={prefix}
                reset={reset}
                dataIn={values.city}
                required={true}
                valid={valid.current.city}
                onHandleGlobals={handleGlobals}
              ></SimpleText>
            </div>
            <div className="simple-text-block">
              <SimpleText
                prefix={prefix}
                reset={reset}
                dataIn={values.postalCode}
                required={true}
                valid={valid.current.postalCode}
                onHandleGlobals={handleGlobals}
              ></SimpleText>
            </div>
            <Tooltip
              title={formatMessage({
                id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.positionUpdateTT",
              })}
              arrow
            >
              <button
                id="btn-update-GPS"
                className="dropdown singleDrop btn btn-success"
                onClick={handlePositionUpdate}
              >
                <FormattedMessage
                  id={
                    "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.positionUpdate"
                  }
                />
              </button>
            </Tooltip>
            <Position
              prefix={prefix}
              reset={reset}
              dataIn={values.position}
              valid={valid.current.position}
              autofill={autofill.position}
              onHandleGlobals={handleGlobals}
            ></Position>
          </div>
          <LanguageBlock
            type="textarea"
            reset={reset}
            dataIn={values.howToGoTo}
            required={false}
            valid={valid.current.howToGoTo}
            onHandleGlobals={handleGlobals}
          ></LanguageBlock>
          <SimpleLabel
            name="category"
            color={valid.current.category ? "green" : "red"}
            required
          ></SimpleLabel>
          <div className="announce-items-group">
            <DropDownBlock
              cs="category"
              reset={reset}
              dataIn={values.category}
              valid={valid.current.category}
              onHandleGlobals={handleGlobals}
            ></DropDownBlock>
            <GuideAdultChildRider
              reset={reset}
              dataIn={{
                haveGuide: values.haveGuide,
                openToAdults: values.openToAdults,
                openToChildren: values.openToChildren,
                openToNonRiders: values.openToNonRiders,
              }}
              onHandleGlobals={handleGlobals}
            ></GuideAdultChildRider>
          </div>
          <SimpleLabel
            name="expectedLevel"
            color="green"
            required
          ></SimpleLabel>
          <div className="announce-items-group">
            <LevelRating
              reset={reset}
              dataIn={values.equestrianLevel}
              required={false}
              l0={2}
              l1={range(6, 10)}
              onHandleGlobals={handleGlobals}
              rt={-200}
            ></LevelRating>
            <LevelRating
              reset={reset}
              dataIn={values.physicalLevel}
              required={false}
              l0={3}
              l1={range(11, 15)}
              onHandleGlobals={handleGlobals}
              rt={-215}
            ></LevelRating>
          </div>
          <Participants
            prefix={prefix}
            reset={reset}
            dataIn={{
              participantMin: values.participantMin,
              participantMax: values.participantMax,
              companionIncluded: values.companionIncluded,
              ageMinParticipant: values.ageMinParticipant,
              ageMaxParticipant: values.ageMaxParticipant,
              childUnderOf: values.childUnderOf,
            }}
            valid={{
              participantMin: valid.current.participantMin,
              participantMax: valid.current.participantMax,
              companionIncluded: true,
              ageMinParticipant: valid.current.ageMinParticipant,
              ageMaxParticipant: valid.current.ageMaxParticipant,
              childUnderOf: valid.current.childUnderOf,
            }}
            onHandleGlobals={handleGlobals}
          ></Participants>
          <Price
            prefix={prefix}
            type={dataIn.datesType}
            reset={reset}
            dataIn={{
              devise: values.devise,
              priceAdulte: values.priceAdulte,
              priceChild: values.priceChild,
              priceAccompagnateur: values.priceAccompagnateur,
            }}
            valid={{
              devise: valid.current.devise,
              priceAdulte: valid.current.priceAdulte,
              priceChild: valid.current.priceChild,
              priceAccompagnateur: valid.current.priceAccompagnateur,
            }}
            onHandleGlobals={handleGlobals}
          ></Price>
          <DatesBlock
            key={reset} //component refresh each time reset is changed in handleClearAllUndo function
            reset={reset}
            dataIn={{
              datesType: values.datesType,
              daysNights: {
                nbDays: values.nbDays.data,
                nbNights: values.nbNights.data,
              },
              dates: values.dates,
            }}
            onHandleGlobals={handleGlobals}
          ></DatesBlock>
          <LanguageBlock
            type="textarea"
            reset={reset}
            dataIn={values.typicalDay}
            required={false}
            valid={valid.current.typicalDay}
            onHandleGlobals={handleGlobals}
          ></LanguageBlock>
          <DailyProgram
            reset={reset}
            dataIn={values.days}
            onHandleGlobals={handleGlobals}
          ></DailyProgram>
          <LanguageBlock
            type="textarea"
            reset={reset}
            dataIn={values.horseDescription}
            required={false}
            valid={valid.current.horseDescription}
            onHandleGlobals={handleGlobals}
          ></LanguageBlock>
          <LanguageBlock
            type="textarea"
            reset={reset}
            dataIn={values.saddleryDescription}
            required={false}
            valid={valid.current.saddleryDescription}
            onHandleGlobals={handleGlobals}
          ></LanguageBlock>
          <LanguageBlock
            type="textarea"
            reset={reset}
            dataIn={values.supervisingDescription}
            required={false}
            valid={valid.current.supervisingDescription}
            onHandleGlobals={handleGlobals}
          ></LanguageBlock>
          <SimpleLabel name="comfortLevel"></SimpleLabel>
          <div className="announce-items-group">
            <LevelRating
              label={false}
              reset={reset}
              dataIn={values.comfortLevel}
              required={false}
              l0={1}
              l1={range(0, 5)}
              onHandleGlobals={handleGlobals}
              rt={-230}
            ></LevelRating>
          </div>
          <LanguageBlock
            type="textarea"
            reset={reset}
            dataIn={values.accommodationDescription}
            required={false}
            valid={valid.current.accommodationDescription}
            onHandleGlobals={handleGlobals}
          ></LanguageBlock>
          <LanguageBlock
            type="textarea"
            reset={reset}
            dataIn={values.mealDescription}
            required={false}
            valid={valid.current.mealDescription}
            onHandleGlobals={handleGlobals}
          ></LanguageBlock>
          <LanguageBlock
            type="textarea"
            reset={reset}
            dataIn={values.included}
            required={false}
            valid={valid.current.included}
            onHandleGlobals={handleGlobals}
          ></LanguageBlock>
          <LanguageBlock
            type="textarea"
            reset={reset}
            dataIn={values.notIncluded}
            required={false}
            valid={valid.current.notIncluded}
            onHandleGlobals={handleGlobals}
          ></LanguageBlock>
          <Options
            reset={reset}
            dataIn={values.options}
            onHandleGlobals={handleGlobals}
          ></Options>
          <LanguageBlock
            type="textarea"
            reset={reset}
            dataIn={values.establishment}
            required={false}
            valid={valid.current.establishment}
            onHandleGlobals={handleGlobals}
          ></LanguageBlock>
          <SimpleLabel name="spokenLanguages"></SimpleLabel>
          <div className="announce-items-group">
            <LanguageRating
              img={france}
              reset={reset}
              dataIn={values.frenchLevel}
              required={false}
              l0={5}
              l1={range(19, 24)}
              onHandleGlobals={handleGlobals}
            ></LanguageRating>
            <LanguageRating
              img={uk}
              reset={reset}
              dataIn={values.englishLevel}
              required={false}
              l0={5}
              l1={range(19, 24)}
              onHandleGlobals={handleGlobals}
            ></LanguageRating>
            <LanguageRating
              img={germany}
              reset={reset}
              dataIn={values.germanLevel}
              required={false}
              l0={5}
              l1={range(19, 24)}
              onHandleGlobals={handleGlobals}
            ></LanguageRating>
            <LanguageRating
              img={spain}
              reset={reset}
              dataIn={values.spanishLevel}
              l0={5}
              l1={range(19, 24)}
              onHandleGlobals={handleGlobals}
            ></LanguageRating>
          </div>
          <LanguageBlock
            type="textarea"
            reset={reset}
            dataIn={values.infosAdditional}
            required={false}
            valid={valid.current.infosAdditional}
            onHandleGlobals={handleGlobals}
          ></LanguageBlock>
          {typeof images !== "undefined" &&
            Object.keys(images).length === 2 && (
              <Images
                reset={reset}
                dataIn={images}
                onHandleGlobals={handleGlobals}
              ></Images>
            )}
        </div>
      </div>
    )
  );
}
export default AnnounceForm;
