import {useContext} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import IntlContext from "./IntlContext";
import france from "./flags/france.webp";
import uk from "./flags/uk.webp";

function LanguageSwitch({contextImages}) {
  const location = useLocation();
  const navigate = useNavigate();
  const functions = useContext(IntlContext);
  return (
    <div className="flags-container">
      <button
        id="main-navbar-fr-flag"
        className="btn btn-link"
        onClick={() => {
          functions.switchToFrench(contextImages);
          if (location.pathname.slice(0, 3).includes("/en"))
            navigate(
              `${location.pathname.replace("/en", "/fr")}${
                location.search ? location.search : ""
              }`,
              {
                replace: true,
                state: location.state,
              }
            );
        }}
      >
        <img src={france} alt="frenchFlag" style={{width: 28, height: 15}} />
      </button>
      <button
        id="main-navbar-en-flag"
        className="btn btn-link"
        onClick={() => {
          functions.switchToEnglish(contextImages);
          if (location.pathname.slice(0, 3).includes("/fr"))
            navigate(
              `${location.pathname.replace("/fr", "/en")}${
                location.search ? location.search : ""
              }`,
              {
                replace: true,
                state: location.state,
              }
            );
        }}
      >
        <img src={uk} alt="UKFlag" style={{width: 28, height: 15}} />
      </button>
    </div>
  );
}

export default LanguageSwitch;
