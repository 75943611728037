import {useState, useEffect} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import _ from "lodash";
import {toastWarning} from "../../../common/toastSwal/ToastMessages.js";
import DaysNights from "./DaysNights.jsx";
import CalendarBlock from "./CalendarBlock.jsx";

let calendar_block = null;
function DatesType({
  idx,
  reset,
  data: dta,
  onHandleChange,
  onHandleColor,
  onHandleGlobals,
}) {
  const {locale, formatMessage} = useIntl();
  const [data, setData] = useState({});
  const [duration, setDuration] = useState({});
  const [value, setValue] = useState(false); //local check box
  const [warning, setWarning] = useState({});
  useEffect(() => {
    setData(dta);
    setValue(dta.daysNights.active);
    if (dta.daysNights.active)
      calendar_block = (
        <CalendarBlock
          type={dta.datesType}
          duration={duration}
          reset={reset}
          data={dta.dates}
          disabled={
            dta.datesType === "Flex_Flex" ? false : duration.nbDays <= 0
          }
          onHandleColor={onHandleColor} //'Proposed dates' label color setting
          onHandleGlobals={onHandleGlobals}
        ></CalendarBlock>
      );
  }, [dta, reset]); //data and reset needed !!!!
  useEffect(() => {
    setDuration({
      nbDays: dta.daysNights.nbDays,
      nbNights: dta.daysNights.nbNights,
    });
    setWarning({
      nbDays: {props: {obj: [0, true]}},
      nbNights: {props: {obj: [0, true]}},
    });
  }, [reset]);
  function handleChange(e) {
    //local check box
    if (data.locked) {
      toastWarning(
        formatMessage({
          id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.warning1",
        })
      );
      return;
    }
    setValue(!value);
    onHandleChange(e);
  }
  function getWarning() {
    try {
      if (!warning.nbDays.props.obj[1]) return warning.nbDays;
      if (!warning.nbNights.props.obj[1]) return warning.nbNights;
    } catch (error) {}
    return null;
  }
  function handleWarning(val) {
    const warn = _.cloneDeep(warning);
    warn[val[0]] = val[1];
    onHandleColor({
      nbDays: warn.nbDays.props.obj[1],
      nbNights: warn.nbNights.props.obj[1],
    });
    setWarning(warn);
  }
  function handleGlobals(cs, val) {
    if (cs === "value") {
      const dur = _.cloneDeep(duration);
      dur[val[0]] = val[1];
      setDuration(dur);
    }
    onHandleGlobals(cs, val);
  }
  return (
    Object.keys(data).length === 4 && (
      <>
        <div className="announce-items-subgroup dates-type">
          <div className="simple-text-block dates-type">
            <label className="member-label">
              <FormattedMessage
                id={`src.components.announcePage.booking.${data.datesType.toLowerCase()}`}
              />
            </label>
            <div className="member-control-container">
              <input
                id={data.datesType}
                type="checkbox"
                className="member-control dates-type"
                onChange={handleChange}
                checked={value}
              ></input>
            </div>
          </div>
          {value && (
            <DaysNights
              reset={reset}
              data={{
                type: data.datesType,
                nbDays: data.daysNights.nbDays,
                nbNights: data.daysNights.nbNights,
                dates: data.dates,
              }}
              onHandleWarning={handleWarning}
              onHandleGlobals={handleGlobals}
            ></DaysNights>
          )}
          <div className="d-flex justify-content-center">{getWarning()}</div>
        </div>
        {idx === 2 ? calendar_block : null}
      </>
    )
  );
}

export default DatesType;
