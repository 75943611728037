import {useState, useEffect} from "react";
import {useQuery} from "react-query";
import {FormattedMessage, useIntl} from "react-intl";
import _ from "lodash";
import {parseISO} from "date-fns";
import {useCookies} from "react-cookie";
import {
  getConversation,
  getConversations,
  patchConversation,
  postConversation,
  postMessage,
  patchMessage,
} from "../../../../services/httpAnnounces.js";
import {decodeJWT} from "../../../../services/httpUsers.js";
import {errorHandlingToast} from "../../../../services/utilsFunctions.js";
import ChatList from "./ChatList.jsx";
import {getRefreshTime} from "../../../../services/utilsFunctions.js";

let post_disabled = {msg_lgth: true, conv: true};
function Messaging({pro, announceId, onHandleBadges}) {
  //pro not null, announceId not null, onHandleBadges = empty function > messaging in announce page
  //pro=null, announceId=null, onHandleBadges = not empty function > messaging in MemberPage
  const {locale} = useIntl();
  const [cookies] = useCookies(["user"]);
  const currentUser = cookies.user ? decodeJWT(cookies.user) : null;
  if (pro && currentUser.type !== "pro") post_disabled.conv = false;
  const emptyState = {
    to: null,
    id_receiver: pro ? pro._id : null,
    id_announce: null,
    title: "",
    message: "",
    conversation: {},
  };
  const [state, setState] = useState(emptyState);
  const [messages, setMessages] = useState([]);
  const [conversations, setConversations] = useState({});
  function sortMessages(msgs) {
    const ordered = _.orderBy(
      msgs,
      (message) => parseISO(message.msg.sendDate).getTime(),
      ["desc"]
    );
    return ordered;
  }
  function prepareData(data) {
    const msgs = [],
      convs = {};
    let conv_msgs = [],
      cond = true;
    data.map((conv) => {
      conv_msgs = [];
      conv.messages.map((msg) => {
        if (pro) cond = msg.id_announce._id === announceId;
        if (cond) {
          msgs.push({msg, conv: conv._id});
          conv_msgs.push(msg._id);
        }
      });
      convs[conv._id] = conv_msgs;
    });
    return {msgs: sortMessages(msgs), convs};
  }
  async function loadData(signal) {
    const res = pro
      ? await getConversation(currentUser._id, pro._id, cookies.user, signal)
      : await getConversations(currentUser._id, cookies.user, signal);
    if (await errorHandlingToast(res, locale, false)) return;
    if (res.data.length !== 0) {
      const data = prepareData(res.data);
      setMessages(data.msgs);
      setConversations(data.convs);
    }
  }
  const abortController = new AbortController();
  useQuery(
    "messages",
    () => {
      loadData(abortController.signal);
    },
    getRefreshTime("Messaging.jsx", "interval")
  );
  useEffect(() => {
    return () => {
      abortController.abort(); //clean-up code after component has unmounted
    };
  }, []);
  async function createConversation(msg) {
    const abortController = new AbortController();
    const res = await postConversation(
      {users: [currentUser._id, pro._id], messages: [msg._id]},
      cookies.user,
      abortController.signal
    );
    if (await errorHandlingToast(res, locale)) {
      abortController.abort();
      return;
    }
    res.data.data.messages = [msg];
    return res.data.data;
  }
  async function sendMessage() {
    const abortController = new AbortController();
    let res = await postMessage(
      {
        id_sender: currentUser._id,
        id_receiver: pro ? pro._id : state.id_receiver._id,
        id_announce: announceId !== null ? announceId : state.id_announce._id,
        title: state.title,
        message: state.message,
      },
      cookies.user,
      abortController.signal,
      {
        recipient: pro ? pro.email : state.id_receiver.email,
        lang: pro ? pro.preferredLanguage : state.id_receiver.preferredLanguage,
        dear: pro
          ? pro.firstName
            ? pro.firstName
            : pro.lastName
          : state.id_receiver.firstName
          ? state.id_receiver.firstName
          : state.id_receiver.lastName,
        from: `${currentUser.firstName} ${currentUser.lastName}`,
        params: "newMessage",
      }
    );
    if (await errorHandlingToast(res, locale)) {
      abortController.abort();
      return;
    }
    const msgs = _.cloneDeep(messages);
    msgs.push({
      msg: {
        ...res.data.data,
        id_announce: announceId !== null ? announceId : state.id_announce, //state.id_announce={_id:xxxx, title:{en:....,fr:...}}
      },
      conv: state.conversation,
    });
    let convs = {...conversations};
    if (Object.keys(state.conversation).length > 0) {
      convs[state.conversation].push(res.data.data._id); //add newly created message _id to conversation
      res = await patchConversation(
        state.conversation,
        {
          messages: convs[state.conversation],
        },
        cookies.user,
        abortController.signal
      );
    } else {
      //create conversation
      const conv = await createConversation(res.data.data);
      if (conv === null) return;
      else convs = {...convs, [conv._id]: conv};
    }
    if (await errorHandlingToast(res, locale)) {
      abortController.abort();
      return;
    }
    setConversations(convs);
    setMessages(sortMessages(msgs));
    setState(emptyState);
  }
  async function setReadStatus(id, value) {
    const msgs = _.cloneDeep(messages);
    let flg = false;
    msgs.map((msg, idx) => {
      if (msg.msg._id === id && msg.msg.isRead === value) flg = true;
    });
    if (flg) return;
    const abortController = new AbortController();
    const res = await patchMessage(
      id,
      {
        isRead: value,
      },
      cookies.user,
      abortController.signal
    );
    if (await errorHandlingToast(res, locale)) {
      abortController.abort();
      return;
    }
    msgs.map((msg, idx) => {
      if (msg.msg._id === id) msgs[idx].msg.isRead = value;
    });
    setMessages(msgs);
    onHandleBadges([["othersToMe", value ? -1 : 1]]);
  }
  function handleReply(
    avatar,
    id_announce,
    title,
    id_receiver,
    msg_id,
    conv_id
  ) {
    //msg_id is the incoming message id that is being replied >>> read=true, it is assumed that incoming message is read before repying to it
    setReadStatus(msg_id, true);
    setState({
      to: avatar,
      id_announce,
      id_receiver,
      title: "Re: " + title,
      message: "",
      conversation: conv_id,
    });
    post_disabled.conv = false;
  }
  return (
    <div className="messaging-container">
      <div className="messaging-authoring">
        {!pro && (
          <div>
            <label style={{marginTop: 0}}>
              <FormattedMessage id="src.components.bookingPage.StepTwoContent.label0" />
            </label>
            {state.to}
          </div>
        )}
        <label htmlFor="title">
          <FormattedMessage id="src.components.bookingPage.StepTwoContent.label1" />
        </label>
        <div className="messaging-authoring-title">
          <input
            id="title"
            className="form-control "
            value={state.title}
            onChange={(e) => setState({...state, title: e.target.value})}
          />
          <a
            className="btn btn-success post-button ml-4"
            style={{
              cursor:
                JSON.stringify(post_disabled).indexOf(true) !== -1
                  ? "not-allowed"
                  : "pointer",
            }}
            onClick={() => {
              if (
                JSON.stringify(post_disabled).indexOf(true) !== -1 ||
                (pro === null && state.conversation === null)
              )
                return;
              sendMessage();
            }}
          >
            <FormattedMessage id="src.components.bookingPage.StepTwoContent.send" />
          </a>
          <i
            className="fa fa-trash fa-lg"
            onClick={() => {
              setState(emptyState);
              post_disabled = {
                msg_lgth: true,
                conv: pro ? false : true,
              };
            }}
          ></i>
        </div>
        <label htmlFor="message">
          <FormattedMessage id="src.components.bookingPage.StepTwoContent.label2" />
          {" *"}
        </label>
        <textarea
          id="message"
          className="form-control"
          rows="4"
          value={state.message}
          onChange={(e) => {
            if (e.target.value.length > 0) post_disabled.msg_lgth = false;
            else post_disabled.msg_lgth = true;
            setState({...state, message: e.target.value});
          }}
        />
      </div>
      <div className="messaging-chat-list">
        <ChatList
          announceId={announceId}
          messages={messages}
          locale={locale}
          onHandleReply={handleReply}
          onHandleRead={setReadStatus}
        />
      </div>
    </div>
  );
}

export default Messaging;
