import {LazyLoadImage} from "react-lazy-load-image-component";
import Carousel from "react-bootstrap/Carousel";
import {FormattedMessage, useIntl} from "react-intl";
import {Link} from "react-router-dom";
import slider2 from "../img/slider/slider-02-bis_red.webp";
import slider3 from "../img/slider/slider-03-bis_red.webp";

function HomeCarousel({preloaded}) {
  const {locale} = useIntl();
  const sliders = [
    [preloaded, 1, 2], //slider1 image data are pre-loaded in App.js
    [slider2, 3, 4],
    [slider3, 5, 6],
  ];
  return (
    <Carousel
      interval={4000}
      nextLabel=""
      prevLabel=""
      indicators={false}
      touch={true}
    >
      {sliders.map((item, idx) => {
        return (
          <Carousel.Item key={idx} className="containerCarousel">
            <LazyLoadImage
              src={item[0]}
              alt="randonnée équestre le long des plages normandes"
            />
            <Carousel.Caption>
              <div className="text ">
                <h2>
                  <FormattedMessage
                    id={
                      "src.components.homePage.HomePageCarousel.title" +
                      (idx + 1)
                    }
                  />
                </h2>
                <h3>
                  <FormattedMessage
                    id={
                      "src.components.homePage.HomePageCarousel.subTitle" +
                      (idx + 1)
                    }
                  />
                </h3>
                <p>
                  <FormattedMessage
                    id={
                      "src.components.homePage.HomePageCarousel.phrase" +
                      item[1]
                    }
                  />
                  <br />
                  <FormattedMessage
                    id={
                      "src.components.homePage.HomePageCarousel.phrase" +
                      item[2]
                    }
                  />
                </p>
                <Link to={`/${locale}/announces`} className="btn btn-success">
                  <FormattedMessage id="src.components.homePage.HomePageCarousel.link" />
                </Link>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default HomeCarousel;
