import {FormattedMessage} from "react-intl";
import {
  StarRating,
  PhysicalRating,
  HorseRating,
  BedRating,
} from "../utils/Ratings";

function RatingTable({announce, thumbnails = false}) {
  const numberComments = announce.numberComments ? announce.numberComments : 0;
  return (
    <div id="ratings-container">
      <div className={`rating-content ${thumbnails ? "thumbnails" : ""}`}>
        {!thumbnails && (
          <span>
            <FormattedMessage id="src.components.rating.Rating.tR1C1" />
            {"("}
            {numberComments}
            {")"}
          </span>
        )}
        <StarRating announce={announce} />
      </div>
      <div className={`rating-content ${thumbnails ? "thumbnails" : ""}`}>
        {!thumbnails && (
          <FormattedMessage id="src.components.rating.Rating.tR2C1" />
        )}
        <HorseRating level={announce.equestrianLevel} />
      </div>
      <div className={`rating-content ${thumbnails ? "thumbnails" : ""}`}>
        {!thumbnails && (
          <FormattedMessage id="src.components.rating.Rating.tR3C1" />
        )}
        <PhysicalRating level={announce.physicalLevel} />
      </div>
      {announce.comfortLevel > 1 && (
        <div className={`rating-content ${thumbnails ? "thumbnails" : ""}`}>
          {!thumbnails && (
            <FormattedMessage id="src.components.rating.Rating.tR4C1" />
          )}
          <BedRating level={announce.comfortLevel - 1} />
        </div>
      )}
    </div>
  );
}

export default RatingTable;
