import React from "react";
import {useState, useEffect} from "react";
import {useIntl} from "react-intl";
import LanguageBlock from "./LanguageBlock.jsx";
import SimpleLabel from "../../common/SimpleLabel.jsx";
import SimpleText from "../../common/SimpleText.jsx";

function OptionUnit({dataIn, valid, onHandleDelete, onHandleGlobals}) {
  const {formatMessage} = useIntl();
  const keys = Object.keys(dataIn);
  const [value, setValue] = useState("All");
  useEffect(() => {
    setValue(dataIn.type);
  }, [dataIn]);
  const [currency, setCurrency] = useState(null);
  useEffect(() => {
    function changeEvent() {
      try {
        setCurrency(document.getElementById("devise").value);
      } catch (error) {}
    }
    changeEvent(); //initial currency setting
    try {
      document.getElementById("devise").addEventListener("change", changeEvent);
    } catch (error) {}
    return () => {
      try {
        document
          .getElementById("devise")
          .removeEventListener("change", changeEvent);
      } catch (error) {}
    };
  });
  function handleChange(e) {
    setValue(e.target.value);
    onHandleGlobals("value", [e.target.id, e.target.value], dataIn.option);
  }
  return keys.map((key, idx) => {
    return (
      <React.Fragment key={idx}>
        {key === "option" && (
          <button
            key={`${key}-${idx}`}
            type="button"
            className="dropdown singleDrop btn btn-success"
          >
            {formatMessage({
              id: "src.components.announcePage.announceDetailTab.program.option",
            })}
            <span className="badge badge-light mx-2">{dataIn[key]}</span>

            <i
              className="fa fa-trash fa-lg ml-4"
              onClick={() => {
                onHandleDelete(idx + 1);
              }}
            ></i>
          </button>
        )}
        {(key === "title" || key === "description") && (
          <LanguageBlock
            key={`${key}-${idx}`}
            type={`${idx === 1 ? "text" : "textarea"}`}
            nested={true}
            dataIn={{name: key, data: dataIn[key]}}
            required={key === "title" ? true : false}
            valid={valid[key]}
            onHandleGlobals={(cs, val) => {
              onHandleGlobals(cs, val, dataIn.option);
            }}
          ></LanguageBlock>
        )}
        {key === "price" && (
          <>
            <SimpleLabel name="price"></SimpleLabel>
            <div key={`${key}-${idx}`} className="option-price">
              <SimpleText
                dataIn={{name: key, data: dataIn[key]}}
                label={false}
                aftLabel={currency}
                required={true}
                valid={valid[key]}
                onHandleGlobals={(cs, val) => {
                  onHandleGlobals(cs, val, dataIn.option);
                }}
              ></SimpleText>
              <div className="member-control-container">
                <select
                  id="type"
                  className="member-control"
                  onChange={handleChange}
                  value={value}
                >
                  <option value="All">
                    {formatMessage({
                      id: "src.components.memberPage.tabs.annonces.details.AddOption.optionAll",
                    })}
                  </option>
                  <option value="Day">
                    {formatMessage({
                      id: "src.components.memberPage.tabs.annonces.details.AddOption.optionDay",
                    })}
                  </option>
                  <option value="Night">
                    {formatMessage({
                      id: "src.components.memberPage.tabs.annonces.details.AddOption.optionNight",
                    })}
                  </option>
                  <option value="Person">
                    {formatMessage({
                      id: "src.components.memberPage.tabs.annonces.details.AddOption.optionPerson",
                    })}
                  </option>
                </select>
              </div>
            </div>
          </>
        )}
      </React.Fragment>
    );
  });
}

export default OptionUnit;
