import {useState, useEffect} from "react";
import {useIntl} from "react-intl";
import {range} from "../../../utils/utilityFunctions.js";
import LevelInfo from "../../../announce/details/common/LevelInfo.jsx";
import {isEven} from "../../../utils/utilityFunctions.js";

function LanguageRating({img, reset, dataIn, l0, l1, onHandleGlobals}) {
  //validation not needed because there always exists a valid value from the dropdown select
  const {formatMessage} = useIntl();
  const {name, data: dta} = dataIn;
  const data = isEven(reset) ? dta.default : dta.saved;
  const [value, setValue] = useState(data);
  useEffect(() => {
    setValue(data);
  }, [reset]);
  function handleChange(e) {
    setValue(e.target.value);
    onHandleGlobals("value", [e.target.id, e.target.value]);
  }
  return (
    <div className="announce-items-subgroup">
      <div className="d-flex justify-content-left align-items-center mb-3">
        <img
          src={img}
          style={{
            width: 25,
            height: 17,
            marginLeft: 17,
            marginRight: 7,
            marginBottom: 12,
          }}
          alt={name}
        />
        <div className="member-control-container info">
          <select
            id={name}
            className="member-control"
            onChange={handleChange}
            value={value}
          >
            {range(0, l1.length - 1).map((lvl) => {
              return (
                <option key={lvl} value={lvl}>
                  {formatMessage({
                    id: `src.components.memberPage.tabs.annonces.details.AddAnnounceForm.span${l1[lvl]}`,
                  }).replace(":", "")}
                </option>
              );
            })}
          </select>
          <LevelInfo
            label={null}
            l0={`src.components.memberPage.tabs.annonces.details.AddAnnounceForm.header${l0}`}
            l1_title="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.span"
            l1_desc="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.description"
            range={l1}
            rating={null}
          ></LevelInfo>
        </div>
      </div>
    </div>
  );
}

export default LanguageRating;
