import {useState, useEffect, useContext} from "react";
import Carousel from "react-bootstrap/Carousel";
import ImagesContext from "../common/context/ImagesContext.js";
import {getImages} from "../../../services/utilsFunctions.js";

function AnnounceCarousel({announce, images: imgs, dflt}) {
  const contextImages = useContext(ImagesContext);
  const [images, setImages] = useState([]);
  useEffect(() => {
    if (dflt) {
      setImages(imgs);
      return;
    }
    const abortController = new AbortController();
    if (
      contextImages.state.status &&
      contextImages.state.status[announce._id].others ===
        contextImages.state.status[announce._id].total - 1
    )
      //other images already in contextImages
      setImages(contextImages.state[announce._id]);
    //main + other images combined
    else {
      setImages(imgs); //main image
      setTimeout(async () => {
        const others = await getImages(
          [announce],
          contextImages,
          false,
          abortController.signal
        );
        if (others[announce._id]) setImages([...imgs, ...others[announce._id]]);
      }, 1000); //1s delay to load the other images
    }
    return () => {
      abortController.abort();
    };
  }, [imgs]);
  return (
    <Carousel
      className="carousel-wrapper"
      interval={4000}
      nextLabel=""
      prevLabel=""
      indicators={false}
      touch={true}
      pause={false}
      controls={images.length > 1}
    >
      {images.map((image, index) => {
        return (
          <Carousel.Item key={index}>
            <div className="image-container">
              <img src={image.data || image} alt="" />
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default AnnounceCarousel;
