import {useState} from "react";
import {Link} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import {toastError, toastSuccess} from "./toastSwal/ToastMessages.js";
import {translate} from "../../../services/httpGoogleServices.js";
import {postNewsLetter} from "../../../services/httpUsers";
import logoColor from "../../../images/logo-color-sm.webp";

export async function handleNewsLetter(email, locale) {
  const res = await postNewsLetter(email);
  if (res.data.statusCode >= 400 && res.data.statusCode < 500)
    toastError(
      locale === "en"
        ? res.data.description
        : await translate({text: res.data.description, from: "en", to: locale}) //message sent back from API
    );
  else
    toastSuccess(
      <FormattedMessage id="src.components.allPages.Footer.toastNewsletter" />
    );
}
function Footer({noLink}) {
  const {locale} = useIntl();
  const [state, setState] = useState({email: null});
  return (
    <div className="footer">
      <div className="main-footer">
        {noLink ? null : (
          <Link to={`/${locale}`} className="title t1 footer-logo hide-elt">
            <img src={logoColor} alt="footer-logo" />
          </Link>
        )}
        <p className="p1 hide-elt">
          <FormattedMessage id="src.components.allPages.Footer.desHorseAround" />
        </p>
        <ul className="list-inline social ul1 hide-elt">
          <li>
            <a
              href="https://www.facebook.com/Horse-Around-102659337783267/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="facebook"
            >
              <i className="fa fa-facebook" />
            </a>
          </li>
          <li>
            <a
              href="https://www.pinterest.fr/H0rseAr0und/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="pinterest"
            >
              <i className="fa fa-pinterest-p" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/horsearound_off/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="instagram"
            >
              <i className="fa fa-instagram" />
            </a>
          </li>
        </ul>
        <h1 className="title t2 hide-elt">
          <FormattedMessage id="src.components.allPages.Footer.supportHorseAround" />
        </h1>
        <p className="p2 hide-elt">
          <FormattedMessage id="src.components.allPages.Footer.contactText" />
        </p>
        <ul className="list-unstyled contact ul2 hide-elt">
          <li>
            <span style={{whiteSpace: "nowrap"}}>
              <i className="fa fa-home" />
              <FormattedMessage id="global.address1" />
              {","}
            </span>
            <span style={{whiteSpace: "nowrap", paddingLeft: 5}}>
              <FormattedMessage id="global.address2" />
            </span>
          </li>
          <li>
            <i className="fa fa-phone" />
            <FormattedMessage id="global.phone" />
          </li>
          <li>
            <i className="fa fa-envelope-o" />
            <a href="mailTo:info@horse-around.com">
              <FormattedMessage id="global.email" />
            </a>
          </li>
        </ul>
        <h1 className="title t3 ">newsletter</h1>
        <p className="p3 ">
          <FormattedMessage id="src.components.allPages.Footer.desNewsletter" />
        </p>
        <div className="input-group newsletter ">
          <FormattedMessage id="src.components.allPages.Menu.userSpace.email">
            {(text) => (
              <input
                type="email"
                className="form-control"
                placeholder={text}
                id="emailhome"
                onChange={(e) => {
                  setState({email: e.target.value});
                }}
              />
            )}
          </FormattedMessage>
          <span className="input-group-addon" id="basic-addon21">
            <i
              className="fa fa-long-arrow-right"
              style={{cursor: "pointer", marginLeft: "-5px"}}
              onClick={() => {
                handleNewsLetter(state.email, locale);
              }}
            />
          </span>
        </div>
        <div className="copyRight-text">
          <p>
            <FormattedMessage id="src.components.allPages.Footer.copyright" />{" "}
            <FormattedMessage id="src.components.allPages.Footer.poweredBy" />{" "}
            horse-around.com
          </p>
        </div>
        <div className="bottom-links">
          <Link to={`/${locale}/CGU-CGV`}>
            <FormattedMessage id="src.components.allPages.Footer.linkSupport.CGU" />
          </Link>
          <Link to={`/${locale}/mentions-legales`}>
            <FormattedMessage id="src.components.allPages.Footer.linkSupport.legalMention" />
          </Link>
          <Link to={`/${locale}/about`}>
            <FormattedMessage id="src.components.allPages.Footer.linkSupport.aboutUs" />
          </Link>
          <Link to={`/${locale}/support`}>
            <FormattedMessage id="src.components.allPages.Footer.linkSupport.support" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
