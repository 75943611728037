import {useState, useEffect} from "react";
import {useIntl, FormattedMessage} from "react-intl";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import {translate} from "../../../../../../services/httpGoogleServices.js";
import CommentsBlock from "./CommentsBlock";

let translated = false;
function AnnounceComments({bookings}) {
  const {formatMessage} = useIntl();
  const [comments, setComments] = useState([]);
  function initComments() {
    const data = [];
    bookings.map((bkg) => {
      if (bkg.comment) data.push(bkg.comment);
    });
    setComments(data);
  }
  useEffect(() => {
    initComments();
  }, [bookings]);
  async function handleTranslate() {
    if (translated) {
      const comnts = _.cloneDeep(comments);
      const to = window.navigator.language;
      await Promise.all(
        comnts.map(async (comnt) => {
          comnt.text = await translate({text: comnt.text, to});
          comnt.desc = await translate({text: comnt.desc, to});
        })
      ).then((resolve, reject) => {
        if (resolve) setComments(comnts);
      });
    } else initComments();
  }
  return (
    <>
      <div className="d-flex flex-nowrap align-items-center">
        <h3>
          <FormattedMessage id="src.components.announcePage.comments.title" />
        </h3>
        <Tooltip
          title={formatMessage({
            id: `src.components.announcePage.comments.translateIconTooltip${
              !translated ? "1" : "2"
            }`,
          })}
          arrow
        >
          <button
            className="fa fa-language fa-2x ml-3"
            style={{
              color: "#7AA095",
              border: "0",
              width: 40,
              backgroundColor: "transparent",
              margin: "10px 0",
            }}
            onClick={() => {
              translated = !translated;
              handleTranslate();
            }}
          ></button>
        </Tooltip>
      </div>

      <div className="comments-container">
        <CommentsBlock data={comments}></CommentsBlock>
      </div>
    </>
  );
}

export default AnnounceComments;
