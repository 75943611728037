import React, {useState, useRef} from "react";
import {FormattedMessage} from "react-intl";
import {StarRatingLevel} from "../../../utils/Ratings";
import SinglePopper from "../common/SinglePopper";

function LangLevelInfo({label, l0, l1_title, l1_desc, range, levels}) {
  const ref = useRef();
  const [state, setState] = useState(false);
  function handleToggle(e, cs) {
    if (e.target.id === "btn_info" && cs === "clickAway") return;
    setState(!state);
  }
  return (
    <div className="row m-0 p-0">
      <h3>
        <FormattedMessage id={label} />
      </h3>
      {Object.keys(levels).map((lang, idx) => {
        return (
          levels[lang].level > 0 && (
            <div
              key={idx}
              className="d-flex flex-nowrap align-items-center mx-3 mb-3"
            >
              <img
                key={idx}
                src={levels[lang].img}
                style={{
                  width: "25px",
                  height: "17px",
                  marginLeft: "17px",
                  marginRight: "7px",
                }}
                alt={`${lang} flag`}
              />
              <div className="flex-shrink-0">
                <StarRatingLevel key={`${idx}x`} level={levels[lang].level} />
              </div>
              {idx === Object.keys(levels).length - 1 && (
                <>
                  <button
                    id="btn_info"
                    className="fa fa-info-circle fa-2x ml-3 mt-1"
                    style={{
                      color: "#7AA095",
                      border: 0,
                    }}
                    onClick={handleToggle}
                  ></button>
                  <div
                    ref={ref}
                    className="d-inline"
                    style={{width: "20px"}}
                  ></div>
                  <SinglePopper
                    anchor={ref.current}
                    l0={l0}
                    l1_title={l1_title}
                    l1_desc={l1_desc}
                    range={range}
                    open={state}
                    onClose={(e) => {
                      handleToggle(e, "clickAway");
                    }}
                    mt={20}
                  ></SinglePopper>
                </>
              )}
            </div>
          )
        );
      })}
    </div>
  );
}

export default LangLevelInfo;
