import {useState, useEffect, useContext} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Link, useNavigate} from "react-router-dom";
import Joi from "joi";
import {useValidator} from "react-joi";
import {useCookies} from "react-cookie";
import {deleteCookies} from "./deleteCookies.js";
import {translate} from "../../../services/httpGoogleServices.js";
import {
  emailSchema,
  passwordSchema,
  sponsorCodeSchema,
} from "../common/validation/JoiSchemas";
import {
  login,
  register,
  postCompany,
  forgotPassword,
  decodeJWT,
} from "../../../services/httpUsers.js";
import {
  SwalOkCancelWithTimer,
  formatTimer,
} from "../common/toastSwal/SwalOkCancel.jsx";
import UserContext from "../common/context/UserContext.js";
import ProContext from "../common/context/ProContext.js";
import {getUser} from "../../../services/httpUsers.js";
import {errorHandlingToast} from "../../../services/utilsFunctions.js";
import langs from "../../intl/languages.json";
import {toastWarning} from "../common/toastSwal/ToastMessages.js";

export async function processPwdReset(url, userId, locale) {
  let msg = null;
  const res = await forgotPassword(url, userId, locale);
  if (res.data.statusCode >= 400 && res.data.statusCode <= 500) {
    msg = (
      <div className="alert alert-danger">
        <div className="text-center w-100">
          {locale === "en"
            ? res.data.description
            : await translate({
                text: res.data.description,
                from: "en",
                to: locale,
              })}
          {/*message sent back from API*/}
        </div>
      </div>
    );
    if (res.data.statusCode >= 500) return;
  } else if (res.status === 200)
    msg = (
      <div className="alert alert-success text-center w-100">
        {locale === "en"
          ? res.data
          : await translate({text: res.data, from: "en", to: locale})}
        {/*message sent back from API*/}
      </div>
    );
  return msg;
}
let timer = null;
export function handleLogOut(
  locale,
  removeCookie,
  navigate,
  userContext,
  formatMessage,
  cs = false
) {
  try {
    if (window.location.href.indexOf("member") !== -1)
      //case where log out is performed on MemberPage
      navigate(`/${locale}`, {
        replace: true,
      });
    if (window.location.href.indexOf("announce/details") !== -1)
      //case where log out is performed on announce page
      navigate(`/${locale}/announces`); //quit announce page >>> FormBooking modal and messaging tab need a logged in user
  } catch (error) {
    console.log("handleLogOut err1", error);
  }
  clearInterval(timer);
  try {
    deleteCookies(removeCookie, ["user"]);
    window.localStorage.removeItem("spareToken");
    // document.getElementById("horseAround_navbar_timer").innerHTML = "";
  } catch (error) {
    console.log("handleLogOut err2", error);
  }
  try {
    if (userContext) userContext.onHandleUser({}); //clear data in UserContext
    document.getElementById("loginButtonHorseAround").innerHTML = formatMessage(
      {
        id: "src.components.allPages.Menu.userSpace.login",
      }
    );
    if (!cs)
      //no warning in case of controlled logout (i.e standard sign-out through form login pop up)
      toastWarning(
        formatMessage({id: "src.components.reglog.LogForm.tokenExpired3"})
      );
  } catch (error) {
    //formatMessage is not a function
  }
}
export async function loadUserdata(userContext, id, token, locale) {
  const abortController = new AbortController();
  const res = await getUser(id, token, abortController.signal);
  if (!(await errorHandlingToast(res, locale, false))) {
    userContext.onHandleUser(res.data); //update UserContext at login time
    return res.data;
  } else abortController.abort();
}
export function runNavBarTimer(
  closeInSeconds,
  locale,
  removeCookie,
  navigate,
  userContext,
  formatMessage
) {
  let elt = null;
  const timer = setInterval(() => {
    closeInSeconds--;
    if (closeInSeconds <= 0) {
      clearInterval(timer);
      handleLogOut(locale, removeCookie, navigate, userContext, formatMessage);
    }
    try {
      if (!elt) elt = document.getElementById("horseAround_navbar_timer");
      elt.innerHTML = `${formatTimer(
        closeInSeconds >= 0 ? closeInSeconds : 0
      )}`;
    } catch (error) {}
  }, 1000);
}
export function runSwalTimer(
  locale,
  setCookie,
  removeCookie,
  navigate,
  userContext,
  formatMessage,
  initial = null
) {
  SwalOkCancelWithTimer(
    formatMessage,
    "src.components.reglog.LogForm.tokenExpired1",
    "src.components.reglog.LogForm.tokenExpired2",
    initial ? initial : 300 //300 >>> 5 mns
  ).then((resolve, reject) => {
    switch (resolve[0]) {
      case "cancel":
        runNavBarTimer(
          resolve[1],
          locale,
          removeCookie,
          navigate,
          userContext,
          formatMessage
        ); //time remaining before token actually expires
        window.localStorage.removeItem("spareToken");
        break;
      case "ok": //use the spare token stored in local storage that has a 30mn longer expiry time
        const spare = window.localStorage.getItem("spareToken");
        const spare_exp = decodeJWT(spare).exp; //seconds since EPOCH
        setCookie("user", spare, {
          path: "/",
          expires: new Date(spare_exp * 1000),
        });
        const timeout = spare_exp * 1000 - Date.now() - 2000; //2s margin (to avoid invalid token message)
        window.localStorage.removeItem("spareToken");
        runNavBarTimer(
          Math.trunc(timeout / 1000),
          locale,
          removeCookie,
          navigate,
          userContext,
          formatMessage
        );
        break;
      case "aborted": //no user action on SwalOkCancel
        handleLogOut(
          locale,
          removeCookie,
          navigate,
          userContext,
          formatMessage
        );
    }
  });
}
export function setLoginTimeOut(
  formatMessage,
  setCookie,
  removeCookie,
  navigate,
  exp,
  userContext,
  id,
  token,
  locale,
  cs = null //indicates setLoginTimeOut() has been launched from App.js following a page refresh
) {
  if (!cs) loadUserdata(userContext, id, token, locale); //update UserContext at login time
  setTimeout(() => {
    runSwalTimer(
      locale,
      setCookie,
      removeCookie,
      navigate,
      userContext,
      formatMessage
    );
  }, exp * 1000 - Date.now() - 302 * 1000); //300 >>> 5 mns + 2s margin (to avoid invalid token message)
}
export function checkSponsorCodeValid(
  proContext,
  code,
  formatMessage,
  code_parrainage = null
) {
  if (code.length === 0) return {valid: true, data: null};
  if (code.length === 10) {
    if (code === code_parrainage)
      return {
        valid: false,
        data: [
          [
            formatMessage({
              id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.code_parrainage_selfWarning",
            }),
          ],
        ],
      };
    let id = null;
    for (id of Object.keys(proContext.pro)) {
      if (
        proContext.pro[id].code_parrainage &&
        proContext.pro[id].code_parrainage.code === code &&
        proContext.pro[id].code_parrainage.active
      ) {
        return {
          valid: true,
          data: [
            [proContext.pro[id].corpName],
            [proContext.pro[id].address.address],
            [
              `${proContext.pro[id].address.postcode} ${proContext.pro[id].address.city} - ${proContext.pro[id].address.country}`,
            ],
          ],
        };
      }
    }
  }
  return {
    valid: false,
    data: [
      [
        formatMessage({
          id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.code_parrainage_usedWarning",
        }),
      ],
    ],
  };
}
function FormLogin({onClose}) {
  const intl = useIntl();
  const {locale, formatMessage} = useIntl();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const proContext = useContext(ProContext);
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const currentUser = cookies.user ? decodeJWT(cookies.user) : null;
  const schema = Joi.object({
    creation: Joi.boolean().required().valid(true, false), //false for standard login, true for account creation
    forgotPwd: Joi.boolean().required().valid(true, false), //false for standard login, true for password reset
    userID: emailSchema(intl),
    type: Joi.alternatives().conditional(".creation", {
      is: true,
      then: Joi.string(),
      otherwise: Joi.any().optional(),
    }),
    sponsorCode: Joi.alternatives().conditional(".creation", {
      is: true,
      then: sponsorCodeSchema(intl, 10),
      otherwise: Joi.any().optional(),
    }),
    password: Joi.alternatives().conditional(".forgotPwd", {
      is: true,
      then: passwordSchema(intl, 3, 10),
      otherwise: Joi.any().optional(),
    }),
    checkPassword: Joi.alternatives().conditional(".creation", {
      is: true,
      then: passwordSchema(intl, 3, 10),
      otherwise: Joi.any().optional(),
    }),
    checkTerms: Joi.alternatives().conditional(".creation", {
      is: true,
      then: Joi.boolean(),
      otherwise: Joi.any().optional(),
    }),
  });
  const {state, setData, validate} = useValidator({
    initialData: {
      creation: false,
      forgotPwd: false,
      userID: cookies.user ? currentUser.email : null,
      type: "particulier",
      sponsorCode: "",
      password: "",
      checkPassword: "",
      checkTerms: false,
    },
    schema: schema,
    explicitCheck: {
      creation: false,
      forgotPwd: false,
      userID: true,
      type: true,
      sponsorCode: true,
      password: true,
      checkPassword: true,
      checkTerms: true,
    },
    validationOptions: {
      abortEarly: true,
    },
  });
  const [close, setClose] = useState(false);
  const [alert, setAlert] = useState(null);
  const [disabled, setDisabled] = useState(null);
  useEffect(() => {
    if (cookies.user) {
      //sign-out
      setDisabled(false);
      return;
    }
    if (disabled === null) {
      //initial render
      setDisabled(true);
      return;
    }
    setDisabled(!validateForm());
  }, [state.$data]);
  useEffect(() => {
    function click(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        document.getElementById("login_submit").click();
      }
    }
    window.addEventListener("keydown", click);
    return () => {
      window.removeEventListener("keydown", click);
    };
  }, []);
  function handleChange(e) {
    setData((data) => ({
      ...data,
      [e.target.id]:
        e.target.id === "checkTerms" ? !state.$data.checkTerms : e.target.value,
    }));
  }

  function validateForm() {
    if (
      state.$data.creation &&
      state.$data.sponsorCode.length > 0 &&
      !checkSponsorCodeValid(proContext, state.$data.sponsorCode, formatMessage)
        .valid
    ) {
      setAlert(
        <div className="alert alert-danger">
          <div className="text-center w-100">
            <FormattedMessage id="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.code_parrainage_usedWarning" />
          </div>
        </div>
      );
      return false;
    }
    if (
      state.$data.creation &&
      state.$data.password.length > 0 &&
      state.$data.password !== state.$data.checkPassword
    ) {
      setAlert(
        <div className="alert alert-danger">
          <div className="text-center w-100">
            <FormattedMessage id="src.components.reglog.LogForm.checkPasswordFail" />
          </div>
        </div>
      );
      return false;
    }
    if (state.$data.creation && state.$data.checkTerms === false) {
      setAlert(
        <div className="alert alert-danger">
          <div className="text-center w-100">
            <FormattedMessage id="src.components.reglog.LogForm.checkTermsFail" />
          </div>
        </div>
      );
      return false;
    }
    if (state.$all_source_errors.length > 0) return false;
    setAlert(null);
    return true;
  }
  async function handleSubmit() {
    //login case
    if (!cookies.user) {
      const res = !state.$data.creation
        ? await login(state.$data.userID, state.$data.password)
        : await register(
            state.$data.userID,
            state.$data.type,
            state.$data.password,
            state.$data.type === "particulier" ? state.$data.sponsorCode : "",
            langs.french.includes(window.navigator.language) ? "fr" : "en" //init preferred language at account creation
          );
      if (res.data.statusCode >= 400 && res.data.statusCode <= 500) {
        setAlert(
          <div className="alert alert-danger">
            <div className="text-center w-100">
              {/* <FormattedMessage id="src.components.reglog.LogForm.unknownUserID1" /> */}
              {locale === "en"
                ? res.data.description
                : await translate({
                    text: res.data.description,
                    from: "en",
                    to: locale,
                  })}
              {/*message sent back from API*/}
            </div>
          </div>
        );
        if (res.data.statusCode >= 500) return;
      } else {
        let bl = true;
        if (state.$data.creation && state.$data.type === "pro") {
          await postCompany(
            {
              id_user: res.data.data[0]._id,
              code_parrainage_used: state.$data.sponsorCode
                ? state.$data.sponsorCode
                : "",
            },
            res.headers["x-auth-token"],
            null
          );
          bl = !(await errorHandlingToast(res, locale, false));
        }
        const {exp, _id} = decodeJWT(res.headers["x-auth-token"]); //exp is expressed in seconds since EPOCH
        setCookie("user", res.headers["x-auth-token"], {
          path: "/",
          expires: new Date(exp * 1000),
        });
        window.localStorage.setItem(
          "spareToken",
          res.headers["x-auth-sparetoken"]
        );
        setLoginTimeOut(
          formatMessage,
          setCookie,
          removeCookie,
          navigate,
          exp,
          userContext,
          _id,
          res.headers["x-auth-token"],
          locale
        );
        bl &&
          setAlert(
            <div className="alert alert-success text-center w-100">
              {locale === "en"
                ? res.data.message
                : await translate({
                    text: res.data.message,
                    from: "en",
                    to: locale,
                  })}
              {/*message sent back from API*/}
            </div>
          );
        const preferred = decodeJWT(
          res.headers["x-auth-token"]
        ).preferredLanguage;
        if (bl && preferred && locale !== preferred)
          document.getElementById(`main-navbar-${preferred}-flag`).click();
        handleClose();
        if (bl && state.$data.creation && state.$data.type === "pro")
          setTimeout(() => {
            navigate(`/${locale}/member?proPENDING`);
          }, 500);
      }
    }
    //sign-out case
    else {
      setAlert(
        <div className="alert alert-success text-center w-100">
          <FormattedMessage id="src.components.reglog.LogForm.userDisConnected" />
        </div>
      );
      handleLogOut(
        locale,
        removeCookie,
        navigate,
        userContext,
        formatMessage,
        true //controlled logout
      );
      handleClose();
    }
  }
  function handleClose() {
    setClose(true);
    onClose();
  }
  async function handleForgotPassword() {
    setDisabled(null);
    setData((data) => ({
      ...data,
      forgotPwd: true,
    }));
    setAlert(
      await processPwdReset(
        `${window.location.origin}/${locale}`,
        state.$data.userID,
        locale
      )
    );
  }
  function handleCreateAccount() {
    setDisabled(null);
    setData((data) => ({
      ...data,
      creation: true,
    }));
    setAlert(null);
  }
  return (
    <div
      className="col-auto mt-5 mr-3 "
      style={{width: window.innerWidth < 450 ? 300 : 400}}
    >
      <div className="form-group">
        <label htmlFor="userID">
          <FormattedMessage id="src.components.reglog.LogForm.userID" />
          {" *"}
        </label>
        <input
          id="userID"
          type="text"
          className="form-control"
          onChange={handleChange}
          defaultValue={cookies.user ? currentUser.email : null}
        />
        {state.$errors.userID.length > 0 ? (
          <div className="alert alert-danger">
            {state.$errors.userID.map((data) => data.$message).join(",")}
          </div>
        ) : null}
      </div>
      {!close && state.$data.creation && (
        <div className="form-group">
          <label htmlFor="type">
            <FormattedMessage id="src.components.reglog.LogForm.type" />
            {" *"}
          </label>
          <select
            id="type"
            type="text"
            className="form-control"
            defaultValue="particulier"
            onChange={handleChange}
          >
            <option value="particulier">
              {intl.formatMessage({
                id: "src.components.reglog.LogForm.particulier",
              })}
            </option>
            <option value="pro">
              {intl.formatMessage({
                id: "src.components.reglog.LogForm.pro",
              })}
            </option>
          </select>
          {state.$errors.type.length > 0 ? (
            <div className="alert alert-danger">
              {state.$errors.type.map((data) => data.$message).join(",")}
            </div>
          ) : null}
        </div>
      )}
      {!close && state.$data.creation && (
        <div className="form-group">
          <label htmlFor="sponsorCode">
            <FormattedMessage id="src.components.reglog.LogForm.sponsorCode" />
          </label>
          <input
            id="sponsorCode"
            type="text"
            className="form-control"
            onChange={handleChange}
          />
          {state.$errors.sponsorCode.length > 0 ? (
            <div className="alert alert-danger">
              {state.$errors.sponsorCode.map((data) => data.$message).join(",")}
            </div>
          ) : null}
        </div>
      )}
      {!close && !cookies.user && (
        <div className="form-group">
          <label htmlFor="password">
            <FormattedMessage id="src.components.reglog.LogForm.password" />
            {" *"}
          </label>
          <input
            id="password"
            type="password"
            className="form-control"
            onChange={handleChange}
          />
          {state.$errors.password.length > 0 ? (
            <div className="alert alert-danger">
              {state.$errors.password.map((data) => data.$message).join(",")}
            </div>
          ) : null}
        </div>
      )}
      {!close && state.$data.creation && (
        <>
          <div className="form-group">
            <label htmlFor="checkPassword">
              <FormattedMessage id="src.components.reglog.LogForm.checkPassword" />
              {" *"}
            </label>
            <input
              id="checkPassword"
              type="password"
              className="form-control"
              onChange={handleChange}
            />
            {state.$errors.checkPassword.length > 0 ? (
              <div className="alert alert-danger">
                {state.$errors.checkPassword
                  .map((data) => data.$message)
                  .join(",")}
              </div>
            ) : null}
          </div>
          <div className="checkbox c-checkbox mt-0">
            <label>
              <input id="checkTerms" type="checkbox" onChange={handleChange} />
              <Link to={`/${locale}/CGU-CGV`} target="_blank">
                <FormattedMessage id="src.components.reglog.LogForm.termsLink" />
              </Link>
            </label>
          </div>
        </>
      )}
      {!close && (
        <div className="form-group">
          <button
            id="login_submit"
            disabled={disabled}
            className="btn btn-success btn-default w-100"
            onClick={() => {
              handleSubmit();
            }}
          >
            {!cookies.user ? (
              state.$data.creation ? (
                <FormattedMessage id="src.components.reglog.LogForm.register" />
              ) : (
                <FormattedMessage id="src.components.reglog.LogForm.signIn" />
              )
            ) : (
              <FormattedMessage id="src.components.reglog.LogForm.signOut" />
            )}
          </button>
        </div>
      )}
      {!close && !state.$data.creation && !cookies.user && (
        <div className="form-group">
          <p className="pt-4 text-center">
            <a onClick={handleForgotPassword}>
              <strong>
                <FormattedMessage id="src.components.reglog.LogForm.forgotPassword" />
              </strong>
            </a>
          </p>
          <p className="text-center">
            <a onClick={handleCreateAccount}>
              <strong>
                <FormattedMessage id="src.components.reglog.LogForm.noAccount" />
              </strong>
            </a>
          </p>
        </div>
      )}
      {alert ? alert : null}
    </div>
  );
}

export default FormLogin;
