import {useState, useEffect} from "react";
import {useIntl} from "react-intl";
import _ from "lodash";
import SimpleLabel from "../../common/SimpleLabel.jsx";
import Translate from "./Translate.jsx";
import {translate} from "../../../../../services/httpGoogleServices.js";
import {alert} from "../../validation.js";
import {isEven} from "../../../utils/utilityFunctions.js";

function LanguageBlock({
  type,
  lenMax = null,
  nested = false,
  reset,
  dataIn,
  required,
  valid,
  onHandleGlobals,
}) {
  const {locale, formatMessage} = useIntl();
  const langs = ["en", "fr"];
  const order = langs.indexOf(locale);
  const {name} = dataIn;
  const [start, setStart] = useState({en: 1, fr: 1});
  const [value, setValue] = useState(null);
  const [warning, setWarning] = useState({en: null, fr: null});
  const [len, setLen] = useState({});
  useEffect(() => {
    if (lenMax) {
      const obj = {};
      langs.map((lang) => {
        obj[lang] = (
          isEven(reset) ? dataIn.data.default[lang] : dataIn.data.saved[lang]
        ).length;
      });
      setLen(obj);
    }
  }, [dataIn, reset]);
  useEffect(() => {
    if (typeof reset !== "undefined") return; //used for days and options in AnnounceForm
    //setStart({en: 0, fr: 0});
    setValue(dataIn.data);
    //setWarning({en: false, fr: false});
  }, [dataIn]);
  useEffect(() => {
    if (typeof reset === "undefined") return;
    const data = isEven(reset) ? dataIn.data.default : dataIn.data.saved;
    //setValue(data);
    langs.map((lang) => {
      try {
        handleChange(lang, data[lang], 1); //set value state, update validation & warning upon reset change
        setWarning({en: false, fr: false});
      } catch (error) {} //missing .en or .fr property in data
    });
  }, [reset]);
  function handleChange(lang, val, cs = 0) {
    setStart((data) => ({
      ...data,
      [lang]: 0,
    }));
    setValue((data) => ({
      ...data,
      [lang]: val,
    }));
    const result = alert(name, lang, val, required);
    if (cs === 0)
      setWarning({
        ...warning,
        [lang]: result,
      });
    onHandleGlobals("valid", result.props.obj);
    if (cs === 0) onHandleGlobals("value", [name, lang, val]);
  }
  function handleClear(cs) {
    let lang = null;
    if (cs === "lh") lang = langs[order];
    else lang = langs[order === 0 ? 1 : 0];
    handleChange(lang, "");
  }
  async function handleTranslate(cs) {
    let lang = null,
      text = null;
    if (cs === "lh") {
      lang = langs[order];
      text = value[langs[order === 0 ? 1 : 0]];
    } else {
      lang = langs[order === 0 ? 1 : 0];
      text = value[langs[order]];
    }
    await translate({
      text,
      from: langs[langs.indexOf(lang) === 0 ? 1 : 0],
      to: lang,
    }).then((translated) => {
      handleChange(lang, translated);
    });
  }
  return (
    value && (
      <>
        <SimpleLabel
          name={name}
          color={
            (valid[langs[order]] && valid[langs[order === 0 ? 1 : 0]]) ||
            !required
              ? "green"
              : "red"
          }
          required={required}
        ></SimpleLabel>
        <div className="member-control-container lang">
          <div className="d-flex justify-content-center">
            <label htmlFor={langs[order]} className="member-label">
              {`${langs[order]}`}
              {required ? <span>&nbsp;{"*"}</span> : null}
            </label>
            {lenMax && (
              <span className="input-car-counter">{`${
                len[langs[order]]
              }/${lenMax}`}</span>
            )}
          </div>
          {type === "text" && (
            <input
              type="text"
              id={langs[order]}
              className="member-control"
              onChange={(e) => {
                handleChange(e.target.id, e.target.value);
                setLen({...len, [langs[order]]: e.target.value.length});
              }}
              value={value[langs[order]]}
              placeholder={
                !nested
                  ? formatMessage({
                      id: `src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.${name}PH`,
                    })
                  : null
              }
              style={{
                borderColor: valid[langs[order]] || !required ? "green" : "red",
              }}
            ></input>
          )}
          {type === "textarea" && (
            <textarea
              type="text"
              id={langs[order]}
              rows={!nested ? 8 : 3}
              className="member-control"
              onChange={(e) => {
                handleChange(e.target.id, e.target.value);
              }}
              value={value[langs[order]]}
              placeholder={
                !nested
                  ? formatMessage({
                      id: `src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.${name}PH`,
                    })
                  : null
              }
              style={{
                borderColor: valid[langs[order]] || !required ? "green" : "red",
              }}
            ></textarea>
          )}
          <i
            className="fa fa-trash fa-lg"
            onClick={() => {
              handleClear("lh");
            }}
          ></i>
          <div
            className="d-flex justify-content-left"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {start[langs[order]] === 0 && required && warning[langs[order]]}
          </div>
        </div>
        <Translate onHandleTranslate={handleTranslate}></Translate>
        <div className="member-control-container lang">
          <div className="d-flex justify-content-center">
            <label
              htmlFor={langs[order === 0 ? 1 : 0]}
              className="member-label"
            >
              {`${langs[order === 0 ? 1 : 0]}`}
              {required ? <span>&nbsp;{"*"}</span> : null}
            </label>{" "}
            {lenMax && (
              <span className="input-car-counter">{`${
                len[langs[order === 0 ? 1 : 0]]
              }/${lenMax}`}</span>
            )}
          </div>
          {type === "text" && (
            <input
              type="text"
              id={langs[order === 0 ? 1 : 0]}
              className="member-control"
              onChange={(e) => {
                handleChange(e.target.id, e.target.value);
                setLen({
                  ...len,
                  [langs[order === 0 ? 1 : 0]]: e.target.value.length,
                });
              }}
              value={value[langs[order === 0 ? 1 : 0]]}
              style={{
                borderColor:
                  valid[langs[order === 0 ? 1 : 0]] || !required
                    ? "green"
                    : "red",
              }}
            ></input>
          )}
          {type === "textarea" && (
            <textarea
              type="text"
              rows={!nested ? 8 : 3}
              id={langs[order === 0 ? 1 : 0]}
              className="member-control"
              onChange={(e) => {
                handleChange(e.target.id, e.target.value);
              }}
              value={value[langs[order === 0 ? 1 : 0]]}
              style={{
                borderColor:
                  valid[langs[order === 0 ? 1 : 0]] || !required
                    ? "green"
                    : "red",
              }}
            ></textarea>
          )}
          <i
            className="fa fa-trash fa-lg"
            onClick={() => {
              handleClear("Rh");
            }}
          ></i>
          <div
            className="d-flex justify-content-left"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {start[langs[order === 0 ? 1 : 0]] === 0 &&
              required &&
              warning[langs[order === 0 ? 1 : 0]]}
          </div>
        </div>
      </>
    )
  );
}
export default LanguageBlock;
