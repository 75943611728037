import {useState, useEffect} from "react";
import {useIntl} from "react-intl";
import DropDown from "./DropDown.jsx";
import {alert} from "../../validation.js";
import {isEven} from "../../../utils/utilityFunctions.js";
import PopperInfo from "../../common/PopperInfo.jsx";

function DropDownBlock({cs, reset, dataIn, valid, onHandleGlobals}) {
  const {formatMessage} = useIntl();
  const {name, data: dta} = dataIn;
  const color = valid ? "green" : "red";
  const [start, setStart] = useState(1);
  const [value, setValue] = useState("");
  const [warning, setWarning] = useState(false);
  useEffect(() => {
    const data = isEven(reset) ? dta.default : dta.saved;
    //setValue(data);
    handleChange(data, 1); //update validation, warning & globals value upon reset change
    setWarning(false);
  }, [reset]);
  function handleChange(val, cs = 0) {
    setStart(0);
    setValue(val);
    const result = alert(name, null, val, true);
    if (cs === 0) setWarning(result);
    onHandleGlobals("valid", result.props.obj);
    if (cs === 0) onHandleGlobals("value", [name, val]);
  }
  return (
    <>
      <DropDown
        id={cs === "destination" ? "destinations" : "activities"}
        intlId={
          cs === "destination"
            ? [
                "src.components.allPages.Menu.navbar.destinations",
                ".title",
                ".continents",
                ".continentName",
                ".countries",
              ]
            : [
                "src.components.allPages.Menu.navbar.activities",
                ".title",
                ".types",
                ".title",
                ".subactivities",
              ]
        } //ul slicing layout
        ul={
          cs === "destination"
            ? [
                [0, 1],
                [1, 3],
                [3, 6],
              ]
            : [
                [0, 2],
                [2, 4],
                [4, 6],
              ]
        }
        name={name}
        value={value}
        color={color}
        onHandleChange={handleChange}
      ></DropDown>
      <PopperInfo
        data={[
          [
            `${formatMessage({
              id: `src.components.memberPage.tabs.annonces.details.AddAnnounceForm.infos.${
                cs === "destination" ? "destinations" : "activities"
              }.info1`,
            })}`,
          ],
        ]}
        idTT={null}
        icon="fa fa-info-circle fa-2x ml-n3 pb-2"
      ></PopperInfo>
      {start === 0 && warning}
    </>
  );
}

export default DropDownBlock;
