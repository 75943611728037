import {useState, useEffect} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {isEven} from "../../../utils/utilityFunctions.js";

function GuideAdultChildRider({reset, dataIn, onHandleGlobals}) {
  const {formatMessage} = useIntl();
  const keys = Object.keys(dataIn);
  const [value, setValue] = useState(init());
  useEffect(() => {
    setValue(init());
  }, [reset]);
  function init() {
    const obj = {};
    keys.map((key) => {
      obj[key] = dataIn[key].data[isEven(reset) ? "default" : "saved"] ? 1 : 0;
    });
    return obj;
  }
  function handleChange(e) {
    setValue((data) => ({
      ...data,
      [e.target.id]: e.target.value,
    }));
    onHandleGlobals("value", [e.target.id, e.target.value == 1 ? true : false]); //no object equality
    if (e.target.id !== "openToNonRiders") return;
    document.getElementById("companionIncluded-container").style.display =
      e.target.value == 0 ? "none" : "flex";
    onHandleGlobals("value", [
      "companionIncluded",
      e.target.value == 0 ? false : {init: null},
    ]);
  }
  return (
    <>
      {keys.map((key, idx) => {
        return (
          <div key={idx} className="simple-text-block">
            <label key={idx} className="member-label">
              <FormattedMessage
                id={`src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.${key}`}
              />
            </label>
            <div className="member-control-container">
              <select
                id={key}
                className="member-control"
                onChange={handleChange}
                value={value[key]}
              >
                <option value={1}>{formatMessage({id: "global.yes"})}</option>
                <option value={0}>{formatMessage({id: "global.no"})}</option>
              </select>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default GuideAdultChildRider;
