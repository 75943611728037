import {FormattedMessage} from "react-intl";

function Translate({onHandleTranslate}) {
  return (
    <div className="translate">
      <span
        className="glyphicon translate-icon"
        onClick={() => {
          onHandleTranslate("lh");
        }}
      >
        &#xe132;
      </span>
      <span className="translate-text">
        <FormattedMessage id="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.translate" />
      </span>
      <span
        className="glyphicon translate-icon"
        onClick={() => {
          onHandleTranslate("rh");
        }}
      >
        &#xe131;
      </span>
    </div>
  );
}

export default Translate;
