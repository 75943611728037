import {useIntl} from "react-intl";
import {BedRating} from "../../utils/Ratings";
import LevelInfo from "./common/LevelInfo";
import {range} from "../../utils/utilityFunctions";
import StandardInfo from "./common/StandardInfo";

function AccomodationInfo({announce}) {
  const {locale} = useIntl();
  return (
    <div className="ann-section-content">
      {announce.comfortLevel > 1 && (
        <div className="ann-level-info">
          <LevelInfo
            label="src.components.announcePage.announceDetailTab.labels.accommodation"
            l0="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.header1"
            l1_title="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.span"
            l1_desc="src.components.memberPage.tabs.annonces.details.AddAnnounceForm.description"
            range={[...range(1, 5)]}
            rating={<BedRating level={announce.comfortLevel - 1} />} //comfortLevel=1 >>> not applicable, 2=bivouac ...,6=luxurious
          ></LevelInfo>
        </div>
      )}
      {announce.accommodationDescription &&
        announce.accommodationDescription[locale] && (
          <div>
            <StandardInfo
              field={announce.accommodationDescription}
              locale={locale}
              id="src.components.announcePage.announceDetailTab.labels.house"
            ></StandardInfo>
          </div>
        )}
      {announce.mealDescription && announce.mealDescription[locale] && (
        <div>
          <StandardInfo
            field={announce.mealDescription}
            locale={locale}
            id="src.components.announcePage.announceDetailTab.labels.meal"
          ></StandardInfo>
        </div>
      )}
    </div>
  );
}

export default AccomodationInfo;
