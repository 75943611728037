import {useEffect, useRef, useState, forwardRef} from "react";
import {createPortal} from "react-dom";
import ReactToPrint from "react-to-print";
import {useReactToPrint} from "react-to-print";

export function RenderInWindow({
  comp,
  size,
  onClose,
  resizeControl = false,
  onHandleWindowResize,
}) {
  const [container, setContainer] = useState(null);
  const newWindow = useRef(window);
  useEffect(() => {
    const div = document.createElement("div");
    setContainer(div);
  }, []);
  useEffect(() => {
    if (container && newWindow.current) {
      newWindow.current = window.open(
        "",
        "",
        `resizable,scrollbars,width=${size.width},height=${size.height},left=${size.x},top=${size.y}`
      );
      newWindow.current.document.write(
        "<html><head></head><body></body></html>"
      );
      newWindow.current.document.head.innerHTML =
        window.document.head.innerHTML; //required to ensure styles are transfered to the new window
      newWindow.current.addEventListener("beforeunload", onClose);
      newWindow.current.document.body.appendChild(container);
      // newWindow.current.document.title = "A React portal window";
      function handleResize() {
        onHandleWindowResize(newWindow.current.innerWidth);
      }
      if (resizeControl)
        newWindow.current.addEventListener("resize", handleResize);
      return () => {
        newWindow.current.close();
        newWindow.current.removeEventListener("beforeunload", onClose);
        if (resizeControl)
          newWindow.current.removeEventListener("resize", handleResize);
      };
    }
  }, [container, newWindow]);
  let ref = useRef();
  const ElementToDisplay = forwardRef((props, ref) => {
    return (
      <div className="d-flex justify-content-center w-100" ref={ref}>
        {comp}
      </div>
    );
  });
  return (
    container &&
    createPortal(
      <>
        <ReactToPrint
          trigger={() => (
            <div
              id="print"
              style={{
                position: "fixed",
                top: "10px",
                fontSize: "2.5rem",
                fontWeight: "bolder",
                marginLeft: "93%",
                backgroundColor: "transparent",
                color: "#7aa095",
                cursor: "pointer",
              }}
            >
              <span
                onClick={() => {
                  setTimeout(() => {
                    onClose();
                  }, 500);
                }}
              >
                &#128438;
              </span>
            </div>
          )}
          content={() => ref.current}
        />
        <ElementToDisplay ref={ref}></ElementToDisplay>
      </>,
      container
    )
  ); //react portal allow you to render content outside the DOM hierarchy of a parent component, such as modal window.
}
