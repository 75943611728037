import {useState, useEffect} from "react";
import {useIntl} from "react-intl";
import SimpleLabel from "../../common/SimpleLabel.jsx";
import SimpleText from "../../common/SimpleText.jsx";
import PopperInfo from "../../common/PopperInfo.jsx";

function Participants({prefix, reset, dataIn, valid, onHandleGlobals}) {
  const {formatMessage} = useIntl();
  const keys = Object.keys(dataIn);
  const [color, setColor] = useState([]);
  function init() {
    const obj = [];
    keys.map((key) => {
      obj.push(valid[key]);
    });
    return obj.indexOf(false) === -1 ? "green" : "red";
  }
  useEffect(() => {
    setColor(init());
  }, [valid]);
  const [value, setValue] = useState({});
  useEffect(() => {
    const data = {};
    keys.map((key, idx) => {
      if (key === "companionIncluded")
        data[key] = dataIn[key].data.saved ? 1 : 0;
      else data[key] = dataIn[key];
    });
    setValue(data);
  }, [reset]);
  function handleChange(e) {
    setValue((data) => ({
      ...data,
      [e.target.id]: e.target.value,
    }));
    onHandleGlobals("value", [e.target.id, e.target.value == 1 ? true : false]); //no object equality
  }
  return (
    Object.keys(value).length > 0 && (
      <>
        <SimpleLabel name="participants" color={color} required></SimpleLabel>
        <div className="announce-items-group">
          {keys.map((key, idx) => {
            return key !== "companionIncluded" ? (
              <div key={idx} className="simple-text-block">
                <SimpleText
                  prefix={prefix}
                  reset={reset}
                  key={idx}
                  dataIn={value[key]}
                  required={true}
                  valid={valid[key]}
                  trash={false}
                  onHandleGlobals={onHandleGlobals}
                ></SimpleText>
              </div>
            ) : (
              <div
                id={`${key}-container`}
                key={idx}
                className="simple-text-block"
              >
                <label key={idx} className="member-label">
                  {formatMessage({
                    id: `src.components.memberPage.tabs.annonces.details.AddAnnounceForm.labels.${key}`,
                  })}
                </label>
                <div className="member-control-container">
                  <select
                    id="companionIncluded"
                    className="member-control"
                    onChange={handleChange}
                    value={value[key]}
                  >
                    <option value={1}>
                      {formatMessage({id: "global.yes"})}
                    </option>
                    <option value={0}>
                      {formatMessage({id: "global.no"})}
                    </option>
                  </select>
                </div>
                <PopperInfo
                  data={[
                    [
                      `${formatMessage({
                        id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.infos.companionIncluded.info1",
                      })} :`,
                    ],
                    [
                      `- ${formatMessage({
                        id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.infos.companionIncluded.info2",
                      })}`,
                    ],
                    [
                      `- ${formatMessage({
                        id: "src.components.memberPage.tabs.annonces.details.AddAnnounceForm.infos.companionIncluded.info3",
                      })}`,
                    ],
                  ]}
                  idTT={null}
                  icon="fa fa-info-circle fa-2x pl-3"
                ></PopperInfo>
              </div>
            );
          })}
        </div>
      </>
    )
  );
}

export default Participants;
