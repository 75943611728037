import {FormattedMessage} from "react-intl";

function StandardInfo({field, locale, id}) {
  let cond = true;
  if (locale !== null)
    cond = field ? field[locale] && field[locale] !== "" : false;
  else cond = field ? true : false;
  return (
    <div className="my-2" style={{whiteSpace: "pre-wrap"}}>
      <div>
        <h3>
          <FormattedMessage id={id} />
        </h3>
        <p className="pl-4 mb-0">
          {cond ? (locale !== null ? field[locale] : field) : null}
        </p>
      </div>
    </div>
  );
}

export default StandardInfo;
