import React from "react";
import {useIntl} from "react-intl";
import LanguageBlock from "./LanguageBlock.jsx";
import SimpleText from "../../common/SimpleText.jsx";

function DayUnit({dataIn, valid, onHandleDelete, onHandleGlobals}) {
  const {formatMessage} = useIntl();
  const keys = Object.keys(dataIn);
  return keys.map((key, idx) => {
    return (
      <React.Fragment key={idx}>
        {key === "day" && (
          <button
            key={`${key}-${idx}`}
            className="dropdown singleDrop btn btn-success"
          >
            {formatMessage({
              id: "src.components.announcePage.announceDetailTab.program.day",
            })}
            <span className="badge badge-light mx-2">{dataIn[key]}</span>

            <i
              className="fa fa-trash fa-lg ml-4"
              onClick={() => {
                onHandleDelete(idx + 1);
              }}
            ></i>
          </button>
        )}
        {key !== "day" && key !== "nbHoursEqui" && key !== "nbKmsEqui" && (
          <LanguageBlock
            key={`${key}-${idx}`}
            type={`${idx === 1 ? "text" : "textarea"}`}
            nested={true}
            dataIn={{name: key, data: dataIn[key]}}
            required={key === "title" ? true : false}
            valid={valid[key]}
            onHandleGlobals={(cs, val) => {
              onHandleGlobals(cs, val, dataIn.day);
            }}
          ></LanguageBlock>
        )}
        {key === "nbHoursEqui" && (
          <div key={`${key}-${idx}`} className="equi-hours-kms">
            {["nbHoursEqui", "nbKmsEqui"].map((ky) => {
              return (
                <SimpleText
                  key={ky}
                  dataIn={{name: ky, data: dataIn[ky] ? dataIn[ky] : ""}}
                  required={false}
                  valid={true}
                  onHandleGlobals={(cs, val) => {
                    onHandleGlobals(cs, val, dataIn.day);
                  }}
                ></SimpleText>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  });
}

export default DayUnit;
