import {useState, useEffect, useContext} from "react";
import {useIntl} from "react-intl";
import {Link, useNavigate} from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import TableToolbar from "./TableToolbar.jsx";
import Tablehead from "./Tablehead.jsx";
import {
  getFormattedDate,
  sumOfPropsValues,
} from "../../../utils/utilityFunctions.js";
import stepsNumbering from "../../../announce/details/priceDatesTable/booking/stepsNumbering.json";
import {RenderInWindow} from "../../../common/RenderInWindow.jsx";
import BookingSummary from "./summary/BookingSummary.jsx";
import ParticipantsList from "./participants/ParticipantsList.jsx";
import {toastInfo} from "../../../common/toastSwal/ToastMessages.js";
import {scrollToBottom} from "../../../utils/utilityFunctions.js";
import ImagesContext from "../../../common/context/ImagesContext.js";
import {getImages} from "../../../../../services/utilsFunctions.js";
import "./style.css";

export function getNextSteps(
  steps,
  id,
  stepsNumbering,
  formatMessage,
  formatRoot,
  onHandleChange,
  type
) {
  function getButton(step, by, txt, idx, id) {
    const key = Object.keys(step)[0];
    return (
      <ul key={idx + txt}>
        <button
          id={txt}
          className="btn btn-warning my-2 px-2 py-1"
          style={{
            fontSize: "1.2rem",
            fontWeight: "500",
            paddingRight: "5px",
            color: by === "pro" ? "#305496" : "#ffffff",
            backgroundColor:
              by === "pro"
                ? "yellow"
                : by === "admin" || txt === "seeYouSoon"
                ? "#7aa095"
                : "#4472C4",
          }}
          onClick={(e) => {
            if (
              [
                "waitRefundDecision",
                "seeYou",
                "seeYouSoon",
                "informRejected",
                "completed",
                "paid",
              ].indexOf(e.target.id) !== -1
            )
              return;
            if (txt === "comment" && type && type === "pro") return;
            if (onHandleChange) onHandleChange(id, e.target.id);
          }}
        >
          {stepsNumbering[txt] !== undefined &&
            ` ${
              txt === "akn" ? stepsNumbering["akn"][key] : stepsNumbering[txt]
            } - `}
          {`${formatMessage({
            id: `${formatRoot}.${txt}`,
          })}`}
          {(txt === "completed" ||
            txt === "paid" ||
            txt === "commented" ||
            txt === "seeYouSoon") && <span className="ml-2">&#x2714;</span>}
          {`${
            txt === "seeYou"
              ? getFormattedDate(step.next[by][txt], "dd.MM.yyyy")
              : ""
          }`}
        </button>
      </ul>
    );
  }
  let step = null,
    txt = null;
  return Object.keys(steps).map((key) => {
    step = steps[key];
    if (step && step.active) {
      if (step.next)
        return (
          <div
            className="mx-1 px-1"
            key={key}
            style={{color: "red", fontWeight: "bolder"}}
          >
            {Object.keys(step.next).map((by, idx) => {
              txt = Object.keys(step.next[by])[0];
              if (txt !== "multiple") {
                return getButton(step, by, txt, idx, id);
              } else {
                return (
                  <div className="my-0 py-0" key={idx}>
                    {Object.keys(step.next[by].multiple).map((tx, i) => {
                      return getButton(step, by, tx, i + 10, id);
                    })}
                  </div>
                );
              }
            })}
          </div>
        );
      else
        return (
          <div className="my-0 py-0" key={id}>
            {getButton(step, "admin", "completed", 22, id)}
            {getButton(
              step,
              !step.commented ? "particulier" : "admin",
              !step.commented ? "comment" : "commented",
              22,
              id
            )}
          </div>
        ); //'completed', next=undefined
    }
  });
}
export function getCompletedSteps(
  steps,
  txt,
  taskNbr,
  formatMessage,
  formatRoot
) {
  let step = null;
  return Object.keys(steps).map((key) => {
    step = steps[key];
    if (step && step[txt])
      return (
        <div
          className="d-flex justify-content-left ml-1 my-0 py-1"
          key={key}
          style={{
            color: "dark green",
            fontWeight: "600",
          }}
        >
          <div
            className="badge badge-light my-0 mr-2"
            style={{
              backgroundColor:
                step.by === "pro"
                  ? "yellow"
                  : step.by === "particulier"
                  ? "#4472C4"
                  : "#7aa095",
              color: step.by === "pro" ? "#305496" : "#ffffff",
              fontWeight: "normal",
              width: "30px",
              height: "19px",
              border: "solid 1px",
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            {taskNbr}
          </div>
          <div>
            {`${formatMessage({
              id: `${formatRoot}.${txt}`,
            })} ${getFormattedDate(step[txt], "dd.MM.yyyy")} `}
            &#x2714;
          </div>
        </div>
      );
  });
}

let original = [],
  numFiltered = 0,
  odr = "desc",
  odrby = "reference-booked by";
function DataTable({
  tab,
  user,
  headCells,
  bookings,
  selected,
  themes,
  spinner,
  onHandleSelected,
  onHandleBookingChange,
  onHandleParticipantsChange,
  onHandleToggle,
}) {
  const navigate = useNavigate();
  const {locale, formatMessage} = useIntl(); //locale for page refresh when switching language
  const {type, role} = user;
  const contextImages = useContext(ImagesContext);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    original = prepareData(headCells, bookings); //default past parameter=true (i.e. includes dates in the past)
    setRows(original);
  }, [bookings, locale]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(headCells[0].name);
  const [dense, setDense] = useState(true);
  const [open, setOpen] = useState({summary: null, infos: null});
  function getURL(ids) {
    let url = `/${locale}/member?subtab=${tab}&MyBookings_ids=${ids}`;
    const elt = document.getElementById(`bkgActionSlider${tab}`);
    if (elt && elt.checked) url = `${url}&action`;
    if (numFiltered > 0) url = `${url}&filtered`;
    url = `${url}&orderBy=${odrby}&order=${odr}`;
    return url;
  }
  function getActionCondition(bkg) {
    let bl = false,
      next = null;
    Object.keys(bkg.steps).map((key) => {
      if (
        !bl &&
        bkg.steps[key].active &&
        [18, 20, 26].indexOf(Number(key)) === -1
      ) {
        next = bkg.steps[key].next ? Object.keys(bkg.steps[key].next)[0] : null;
        switch (role) {
          case "USER":
            if (next === type) bl = true; //if key=22, false is returned (step 22 has no next property)
            break;
          case "ADMIN":
            if (key !== "22") bl = true;
        }
        if (!bl && key === "22" && type === "particulier")
          bl = !bkg.steps["22"].commented;
      }
    });
    return bl;
  }
  function prepareData(headCells, bkgs, actionOnly = false) {
    let data = [];
    Object.keys(bkgs).map((key) => {
      if (bkgs[key].nbBookings > 0) data = data.concat(bkgs[key].data);
    });
    const rows = [],
      n = headCells.length;
    let obj = {};
    data.map((rec) => {
      if (!actionOnly || (actionOnly && getActionCondition(rec))) {
        obj = {};
        for (let i = 0; i < n; i++) {
          {
            switch (i) {
              case 0: //reference-booked by
                obj[headCells[i].name] = (
                  <>
                    {`${rec.ref}`}
                    <br />
                    {`${rec.user.email}`}
                  </>
                );
                break;
              case 1: //activity - organizer
                obj[headCells[i].name] = (
                  <>
                    <Link
                      className="alink"
                      to={`/${locale}/announce/details?id=${rec.announce._id}`} //navigate to Announce details page
                      state={{
                        images:
                          contextImages.state.status &&
                          contextImages.state.status[rec.announce._id].main ===
                            1
                            ? [contextImages.state[rec.announce._id][0]]
                            : [], //main image
                      }}
                      onClick={() => {
                        /* const ids = [];
                        console.log(selected);
                        Object.keys(selected).map((key) => {
                          if (selected[key] === 1) ids.push(key);
                        }); */
                        navigate(
                          getURL([rec._id]), //add 'MyBookings' parameter in url to be able to come back on bookings tab when using browser back button
                          {
                            replace: true,
                          }
                        );
                      }}
                    >
                      {`${rec.announce.title[locale]}`}
                    </Link>
                    <br />
                    {`${rec.company.corpName}`}
                  </>
                );
                break;
              case 2: //date
                obj[headCells[i].name] = (
                  <>
                    {`${getFormattedDate(rec.date.dateStart, "dd.MM.yyyy")}`}
                    <br />
                    {`${getFormattedDate(rec.date.dateEnd, "dd.MM.yyyy")}`}
                  </>
                );
                break;
              case 3: //days/nights
                obj[headCells[i].name] = rec.daysNights;
                break;
              case 4: //participants
                obj[headCells[i].name] = (
                  <div style={{textAlign: "left"}}>
                    {rec.adults && rec.adults.nb > 0
                      ? `${formatMessage({
                          id: "src.components.announcePage.booking.adults",
                        })} : ${rec.adults.nb}`
                      : ""}
                    {rec.adults && rec.adults.nb > 0 ? <br /> : ""}
                    {rec.children && rec.children.nb > 0
                      ? `${formatMessage({
                          id: "src.components.announcePage.booking.children",
                        })} : ${rec.children.nb}`
                      : ""}
                    {rec.children && rec.children.nb > 0 ? <br /> : ""}
                    {rec.companions && rec.companions.nb > 0
                      ? `${formatMessage({
                          id: "src.components.announcePage.booking.accompanying",
                        })} : ${rec.companions.nb}`
                      : ""}
                  </div>
                );
                break;
              case 5: //options
                obj[headCells[i].name] = rec.options && (
                  <div>
                    {Object.keys(rec.options).map((option) => {
                      return (
                        rec.options[option] && (
                          <ul key={option}>{`Option ${option} `}&#x2714;</ul>
                        )
                      );
                    })}
                  </div>
                );

                break;
              case 6: //price
                if (!rec.paymentRecap)
                  obj[headCells[i].name] = `${
                    rec.adults
                      ? rec.adults.price
                      : 0 + rec.children
                      ? rec.children.price
                      : 0 + rec.companions
                      ? rec.companions.price
                      : 0
                  } ${rec.announce.devise}`;
                else {
                  if (rec.paymentRecap.deposit)
                    obj[headCells[i].name] = (
                      <div className="d-flex flex-column mx-3">
                        <div className="d-flex justify-content-left ml-3">
                          {`${formatMessage({
                            id: "src.components.bookingPage.BookDetailInfo.deposit",
                          })} : ${Math.round(
                            rec.paymentRecap.deposit.amount
                          )} ${rec.announce.devise} - ${formatMessage({
                            id: "src.components.bookingPage.BookDetailInfo.asap",
                          })}
                        `}
                        </div>
                        <div className="d-flex justify-content-left ml-3">
                          {`${formatMessage({
                            id: "src.components.bookingPage.BookDetailInfo.balance",
                          })} : ${Math.round(
                            rec.paymentRecap.balance.amount
                          )} ${rec.announce.devise} - ${getFormattedDate(
                            rec.paymentRecap.balance.due,
                            "dd.MM.yyyy"
                          )}`}
                        </div>
                      </div>
                    );
                  else
                    obj[headCells[i].name] = (
                      <div className="d-flex flex-column mx-3">
                        <div className="d-flex justify-content-left ml-3">
                          {`${formatMessage({
                            id: "src.components.bookingPage.BookDetailInfo.totalAccount",
                          })}: ${Math.round(rec.paymentRecap.total.amount)} ${
                            rec.announce.devise
                          } - ${formatMessage({
                            id: "src.components.bookingPage.BookDetailInfo.asap",
                          })}
                    `}
                        </div>
                      </div>
                    );
                }
                break;
              case 7: //steps completed
                obj[headCells[i].name] = (
                  <div
                    id={rec._id}
                    className="d-flex flex-column mx-0 px-0 my-2"
                    style={{
                      maxHeight: "70px",
                      overflowY: "scroll",
                    }}
                  >
                    {Object.keys(stepsNumbering).map((txt) => {
                      return getCompletedSteps(
                        rec.steps,
                        txt,
                        stepsNumbering[txt],
                        formatMessage,
                        "src.components.memberPage.tabs.MyReservation"
                      );
                    })}
                  </div>
                );
                setTimeout(() => {
                  scrollToBottom(rec._id);
                }, 500);
                break;
              case 8: //steps to be done
                obj[headCells[i].name] = (
                  <div className="justify-content-left mx-1 px-1">
                    {getNextSteps(
                      rec.steps,
                      rec._id,
                      stepsNumbering,
                      formatMessage,
                      "src.components.memberPage.tabs.MyReservation",
                      onHandleBookingChange,
                      type
                    )}
                  </div>
                );
                break;
              case 9: //cancel
                obj[headCells[i].name] = (
                  <>
                    <button
                      className="fa fa-trash mx-4"
                      style={{
                        color: "#7aa095",
                        border: "0",
                        fontSize: "20px",
                        cursor: rec.steps["22"].completed
                          ? "not-allowed"
                          : "pointer",
                      }}
                      disabled={rec.steps["22"].completed}
                      onClick={(e) => {
                        onHandleBookingChange(rec._id, e.target.id, "cancel");
                      }}
                    ></button>
                  </>
                );
                break;
              case 10: //booking _id
                obj[headCells[i].name] = rec._id;
                break;
              case 11: //id_user
                obj[headCells[i].name] = rec.id_user;
                break;
              case 12: //announce_id
                obj[headCells[i].name] = rec.announce._id;
                break;
            }
          }
        }
        rows.push(obj);
      }
    });
    return rows;
  }
  function getParticipantsButton(id) {
    // console.log(id);
    let allSet = false,
      na = 0,
      nc = 0,
      ncp = 0;
    Object.keys(bookings).map((usr) => {
      bookings[usr].data.map((bkg) => {
        if (bkg._id === id) {
          try {
            bkg.participantsInfo.map((part) => {
              if (part) {
                na += part.as === "adult" ? 1 : 0;
                nc += part.as === "child" ? 1 : 0;
                ncp += part.as === "companion" ? 1 : 0;
              }
            });
          } catch (error) {}
          if (
            na === bkg.adults.nb &&
            nc === bkg.children.nb &&
            ncp === bkg.companions.nb
          )
            allSet = true;
        }
      });
    });
    return (
      <ThemeProvider theme={themes.infos}>
        <Tooltip
          title={formatMessage({
            id: "src.components.memberPage.tabs.MyReservation.infos",
          })}
          arrow
        >
          <button
            className="btn btn-warning ml-2 px-2 py-0"
            style={{
              fontSize: "1.2rem",
              fontWeight: "500",
              color: "#ffffff",
              backgroundColor: selected[id] === 1 ? "#4472C4" : "#A5BBE3",
              marginTop: 0,
              height: 25,
              width: 50,
            }}
            onClick={() => {
              if (selected[id] === 0) return;
              handleSummaryParticipants("infos", id);
            }}
          >
            <span>infos</span> {allSet ? <span> &#x2714;</span> : null}
          </button>
        </Tooltip>
      </ThemeProvider>
    );
  }
  function handleSummaryParticipants(cs, id) {
    if (open[cs]) {
      toastInfo(formatMessage({id: "user_msg.standard.errors.windowOpen"}));
      return;
    }
    setOpen({...open, [cs]: id});
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    odr = isAsc ? "desc" : "asc";
    odrby = property;
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    const newSelecteds = {};
    rows.map((row) => {
      newSelecteds[row.id] = event.target.checked ? 1 : 0;
    });
    onHandleSelected(newSelecteds);
  };
  const handleClick = (id) => {
    if (selected[id] === 1) onHandleSelected({...selected, [id]: 0});
    else onHandleSelected({...selected, [id]: 1});
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const handleAction = (actionOnly) => {
    original = prepareData(headCells, bookings, actionOnly);
    setRows(original);
  };
  const isSelected = (id) => {
    return selected[id] === 1;
  };
  const filterData = (filter) => {
    if (filter) {
      const filtered = _.filter(rows, (row, idx) => {
        return selected[row.id] === 1;
      });
      setRows(filtered);
      numFiltered = filtered.length;
    } else {
      numFiltered = 0;
      setRows(original);
    }
  };
  function sortRows(data, order, orderBy) {
    return data.sort((a, b) => {
      switch (orderBy) {
        case "reference-booked by":
          a = a["reference-booked by"].props["children"][0].replace("-", "_");
          a = a.split("_");
          a = Number(a[1] + a[2]);
          b = b["reference-booked by"].props["children"][0].replace("-", "_");
          b = b.split("_");
          b = Number(b[1] + b[2]);
          break;
        case "activity-organizer":
          a =
            a["activity-organizer"].props["children"][0].props[
              "children"
            ].toLowerCase();
          b =
            b["activity-organizer"].props["children"][0].props[
              "children"
            ].toLowerCase();
          break;
        case "date":
          a = a["date"].props["children"][0].split(".");
          a = new Date(a[2] + "," + a[1] + "," + a[0]);
          b = b["date"].props["children"][0].split(".");
          b = new Date(b[2] + "," + b[1] + "," + b[0]);
          break;
        case "days-nights":
          a = Number(a["days-nights"].split("/")[0]);
          b = Number(b["days-nights"].split("/")[0]);
          break;
        case "participants":
          a = a["participants"].props["children"];
          a =
            Number(a[0].split(":")[0].length !== 0 ? a[0].split(":")[1] : 0) +
            Number(a[2].split(":")[0].length !== 0 ? a[2].split(":")[1] : 0) +
            Number(a[4].split(":")[0].length !== 0 ? a[4].split(":")[1] : 0);
          b = b["participants"].props["children"];
          b =
            Number(b[0].split(":")[0].length !== 0 ? b[0].split(":")[1] : 0) +
            Number(b[2].split(":")[0].length !== 0 ? b[2].split(":")[1] : 0) +
            Number(b[4].split(":")[0].length !== 0 ? b[4].split(":")[1] : 0);
          break;
        case "options":
          a = !a["options"] ? 0 : 1;
          b = !b["options"] ? 0 : 1;
          break;
        case "price":
          if (!a["price"].props["children"][0])
            a = Number(
              a["price"].props["children"].props["children"]
                .split("€")[0]
                .split(":")[1]
            );
          else
            a =
              Number(
                a["price"].props["children"][0].props["children"]
                  .split("€")[0]
                  .split(":")[1]
              ) +
              Number(
                a["price"].props["children"][1].props["children"]
                  .split("€")[0]
                  .split(":")[1]
              );
          if (!b["price"].props["children"][0])
            b = Number(
              b["price"].props["children"].props["children"]
                .split("€")[0]
                .split(":")[1]
            );
          else
            b =
              Number(
                b["price"].props["children"][0].props["children"]
                  .split("€")[0]
                  .split(":")[1]
              ) +
              Number(
                b["price"].props["children"][1].props["children"]
                  .split("€")[0]
                  .split(":")[1]
              );
          break;
      }
      if (a > b) return Number(order === "asc" ? 1 : -1);
      if (a < b) return Number(order === "asc" ? -1 : 1);
      return 0;
    });
  }
  function getNumSelected() {
    const keys = [];
    Object.keys(selected).map((key) => {
      if (selected[key] === 1) keys.push(key);
    });
    let result = 0;
    Object.keys(bookings).map((userId) => {
      result += _.filter(bookings[userId].data, (bkg) => {
        return keys.indexOf(bkg._id) !== -1;
      }).length;
    });
    return [result, keys[0]]; //keys[0] = first selected row
  }
  function getPopUpData(id) {
    let result = null;
    Object.keys(bookings).map((userId) => {
      bookings[userId].data.map((bkg) => {
        if (bkg._id === id) result = bkg;
      });
    });
    return [result];
  }
  try {
    return (
      <>
        {open.summary && (
          <RenderInWindow
            comp={
              <BookingSummary
                data={getPopUpData(open.summary)}
              ></BookingSummary>
            }
            size={{width: 900, height: 500, x: 400, y: 200}}
            onClose={() => {
              setOpen({...open, summary: null});
            }}
          ></RenderInWindow>
        )}
        {open.infos && (
          <RenderInWindow
            comp={
              <ParticipantsList
                data={getPopUpData(open.infos)}
                onHandleToggle={onHandleToggle}
                onHandleSave={(data) => {
                  bookings[data.userId].data.map((bkg, idx) => {
                    if (bkg._id === data._id) {
                      bookings[data.userId].data[idx].participantsInfo =
                        data.ticked;
                      onHandleParticipantsChange(
                        data.userId,
                        data._id,
                        data.ticked
                      );
                    }
                  });
                }}
              ></ParticipantsList>
            }
            size={{width: 900, height: 500, x: 400, y: 200}}
            onClose={() => {
              setOpen({...open, infos: null});
            }}
          ></RenderInWindow>
        )}
        <Paper sx={{width: "100%"}}>
          <TableToolbar
            tab={tab}
            user={user}
            numSelected={getNumSelected()[0]}
            numFiltered={numFiltered}
            theme={themes.toolbar}
            spinner={spinner}
            onHandleFilter={filterData}
            onHandleAction={handleAction}
            onHandleSummary={() => {
              const result = getNumSelected();
              if (result[0] === 0) return;
              handleSummaryParticipants("summary", result[1]);
            }}
          />
          <TableContainer sx={{maxHeight: "60vh"}}>
            <Table stickyHeader size={dense ? "small" : "medium"}>
              <Tablehead
                headCells={headCells}
                numSelected={sumOfPropsValues(selected)}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                theme={themes.header}
                sx={themes.header_sx}
              />
              <ThemeProvider theme={themes.body}>
                <TableBody>
                  {sortRows(rows, order, orderBy).map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        onClick={() => handleClick(row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        {Object.keys(row).map((item, idx) => {
                          return (
                            <TableCell
                              onClick={(e) => {
                                if (
                                  e.target.cellIndex === 2 ||
                                  e.target.cellIndex === undefined
                                ) {
                                  //prevents row selection when clicking buttons inside cell >>> cell click behaviour unchanged (i.e row selection)
                                  e.stopPropagation();
                                  e.preventDefault();
                                }
                              }}
                              key={idx}
                              align={headCells[idx].align}
                              width={headCells[idx].width}
                              hidden={headCells[idx].hidden ? true : false}
                              style={{
                                color:
                                  idx === 8
                                    ? row[headCells[idx].name] === "false"
                                      ? "black"
                                      : "red"
                                    : "black",
                              }}
                            >
                              {idx === 4 ? (
                                <div className="d-flex justify-content-between">
                                  {row[headCells[idx].name]}
                                  {getParticipantsButton(row.id)}
                                </div>
                              ) : (
                                row[headCells[idx].name]
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </ThemeProvider>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  } catch (error) {
    console.log("error in MemberPage bookings tab >>> DataTable.jsx ", error);
    return null;
  }
}
export default DataTable;
