import {parseISO, format, differenceInDays} from "date-fns";
import StandardInfo from "../common/StandardInfo";
import AnnounceRatings from "./AnnounceRatings";
import AnnounceComments from "./comments/AnnounceComments";

function ProInfo({pro, bookings, announce}) {
  function getBookingDays() {
    let days = 0;
    bookings.map((booking) => {
      days +=
        (differenceInDays(
          parseISO(booking.date.dateEnd),
          parseISO(booking.date.dateStart)
        ) +
          1) *
        (booking.children.nb + booking.adults.nb + booking.companions.nb);
    });
    return days;
  }
  const levels = {
    0: announce.numberRatings,
    1: announce.environmentLandscapeNote,
    2: announce.cavalryNote,
    3: announce.qualityPriceNote,
    4: announce.receptionNote,
    5: pro.horseAroundNote.total,
  };
  return (
    <div className="ann-section-content">
      <div className="d-flex flex-wrap justify-content-left mb-4">
        <StandardInfo
          field={format(
            parseISO(announce.id_user.registration_date),
            "dd.MM.yyyy"
          )}
          locale={null}
          id="src.components.announcePage.moreInfos.registeredSince"
        ></StandardInfo>
        <hr style={{width: 60, minWidth: 20}} />
        <StandardInfo
          field={getBookingDays().toString()}
          locale={null}
          id="src.components.announcePage.moreInfos.nbSoldDays"
        ></StandardInfo>
        <hr style={{width: 60, minWidth: 20}} />
        <StandardInfo
          field={bookings.length.toString()}
          locale={null}
          id="src.components.announcePage.moreInfos.nbBookings"
        ></StandardInfo>
      </div>
      {announce.numberComments && announce.numberComments > 0 ? (
        <AnnounceRatings
          label="src.components.rating.DetailledRating.title"
          levels={levels}
        ></AnnounceRatings>
      ) : null}
      <AnnounceComments bookings={bookings}></AnnounceComments>
    </div>
  );
}

export default ProInfo;
