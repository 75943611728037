import http from "./httpService";
import {getApiUrl} from "./utilsFunctions.js";

const api = getApiUrl();

export function getProfileAction(userId, type, role, token, signal) {
  return http.get(`${api}/master/profile/${userId}/${type}/${role}`, {
    headers: {"x-auth-token": token},
    signal,
  });
}
export function getCorporateAction(userId, token, signal) {
  return http.get(`${api}/master/corporate/${userId}`, {
    headers: {"x-auth-token": token},
    signal,
  });
}
export function getUnreadAction(userId, token, signal) {
  return http.get(`${api}/master/messages/user/${userId}`, {
    headers: {"x-auth-token": token},
    signal,
  });
}
export function getAdminPendingAction(token, signal) {
  //look for a user with a 'PENDING' status
  return http.get(`${api}/master/admin/pending`, {
    headers: {"x-auth-token": token},
    signal,
  });
}

export function getAdminUrlsAction(token, signal) {
  //look for a company waiting for urls validation (MyConcitions)
  return http.get(`${api}/master/admin/urls`, {
    headers: {"x-auth-token": token},
    signal,
  });
}
export function getBookingAction(userId, type, token, signal) {
  return http.get(`${api}/master/bookings/user/${userId}/${type}`, {
    headers: {"x-auth-token": token},
    signal,
  });
}
export function getInvoiceAction(userId, type, token, signal) {
  return http.get(`${api}/master/invoices/user/${userId}/${type}`, {
    headers: {"x-auth-token": token},
    signal,
  });
}
